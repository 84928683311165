import React from 'react';
import PropTypes from 'prop-types';

import images from '../../../../utils/ImageStore';
import withTranslate from '../../../../shared/withTranslate/withTranslate';

const CompatibilityHeader = ({ t, showMobile, showUploader }) => (
  <div className="CompatibilityHeader">
    <div className="CompatibilityHeader-cell">
      <img alt={t('selectedDevicesHeader')} src={images.meter} />
      { t('yourDevices') }
    </div>
    {showMobile &&
      <div className="CompatibilityHeader-cell">
        <img alt={t('glookoMobileApp')} src={images.mobile} />
        { t('glookoMobileApp') }
      </div>
    }
    {showUploader &&
    <div className="CompatibilityHeader-cell">
      <img alt={t('pcMacComputer')} src={images.uploader} />
      { t('pcMacComputer') }
    </div>
    }
  </div>
);

CompatibilityHeader.propTypes = {
  t: PropTypes.func.isRequired,
  showMobile: PropTypes.bool.isRequired,
  showUploader: PropTypes.bool.isRequired,
};

export default withTranslate(CompatibilityHeader);
