import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUpdateAttribute, onCodeActivate } from '~/store/modules/accountInformation';
import { DEFAULT_MIN_AGE } from '../../utils/dateUtils';
import CodeActivation from './CodeActivation';

import { codeActivationValid } from '../AccountInformation/validations/accountInformation';

const mapStateToProps = state => ({
  email: state.accountInformation.email,
  password: state.accountInformation.password,
  dateOfBirth: state.accountInformation.dateOfBirth,
  majorityAge: DEFAULT_MIN_AGE,
  code: state.accountInformation.code,
  consents: state.accountInformation.consents,
  language: state.language.language,
  registerStatus: state.accountInformation.registerStatus,
  registerError: state.accountInformation.registerError,
  isValid: codeActivationValid(state.accountInformation),
  guardianInfo: state.accountInformation.guardianInfo,
});

const mapDispatchToProps = dispatch => ({
  onCodeActivate: () => dispatch(onCodeActivate()),
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(CodeActivation));
