import { fetchAllDiabetesDevices } from '~/services/deviceService';
import { detectedPlatform } from '../../utils/deviceDetection';

// ACTIONS
export const SELECT_ANDROID_CHARGING_CORD =
  'deviceSelection/SELECT_ANDROID_CHARGING_CORD';
export const SELECT_PLATFORM = 'deviceSelection/SELECT_PLATFORM';
export const SELECT_DIABETES_DEVICES =
  'deviceSelection/SELECT_DIABETES_DEVICES';
export const TOGGLE_SKIP_DIABETES_DEVICES = 'deviceSelection/TOGGLE_SKIP_DIABETES_DEVICES';
export const FILTER_DIABETES_DEVICES =
  'deviceSelection/FILTER_DIABETES_DEVICES';
export const DIABETES_DEVICES_REQUESTED =
  'deviceSelection/DIABETES_DEVICES_REQUESTED';
export const DIABETES_DEVICES_RECEIVED =
  'deviceSelection/DIABETES_DEVICES_RECEIVED';

const initialState = {
  androidChargingCord: undefined,
  deviceFetchStatus: undefined,
  skipDiabetesDevices: false,
  allDiabetesDevices: [],
  diabetesDeviceFilter: '',
  selectedDiabetesDevices: [],
  platform: detectedPlatform(),
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ANDROID_CHARGING_CORD:
      return {
        ...state,
        androidChargingCord: action.value,
      };
    case SELECT_PLATFORM:
      return {
        ...state,
        platform: action.value,
        androidChargingCord: undefined,
      };
    case SELECT_DIABETES_DEVICES:
      return {
        ...state,
        skipDiabetesDevices: false,
        selectedDiabetesDevices: [].concat(action.value),
      };
    case FILTER_DIABETES_DEVICES:
      return {
        ...state,
        diabetesDeviceFilter: action.value,
      };
    case DIABETES_DEVICES_REQUESTED:
      return {
        ...state,
        deviceFetchStatus: 'loading',
        allDiabetesDevices: [],
      };
    case DIABETES_DEVICES_RECEIVED:
      return {
        ...state,
        deviceFetchStatus: action.status,
        allDiabetesDevices: [].concat(action.allDiabetesDevices),
      };
    case TOGGLE_SKIP_DIABETES_DEVICES:
      return {
        ...state,
        skipDiabetesDevices: !state.skipDiabetesDevices,
        selectedDiabetesDevices: [],
      };
    default:
      return state;
  }
};

// ACTION CREATORS
export const onSelectPlatform = selection => (
  { type: SELECT_PLATFORM, value: selection }
);

export const onSelectDiabetesDevices = selection => (
  { type: SELECT_DIABETES_DEVICES, value: selection }
);

export const onToggleSkipDiabetesDevices = () => (
  { type: TOGGLE_SKIP_DIABETES_DEVICES }
);

export const onFilterDiabetesDevices = filter => (
  { type: FILTER_DIABETES_DEVICES, value: filter }
);

export const onSelectAndroidChargingCord = cord => (
  { type: SELECT_ANDROID_CHARGING_CORD, value: cord }
);

// thunk action creator
export const fetchDiabetesDevices = () =>
  (dispatch) => {
    dispatch({ type: DIABETES_DEVICES_REQUESTED });
    const queryString = '?exclude[]=hidden_add_device';

    fetchAllDiabetesDevices(queryString)
      .then((res) => {
        dispatch({
          type: DIABETES_DEVICES_RECEIVED,
          deviceFetchStatus: 'success',
          allDiabetesDevices: res.data.devices,
        });
      })
      .catch(() => {
        dispatch({
          type: DIABETES_DEVICES_RECEIVED,
          deviceFetchStatus: 'failure',
          allDiabetesDevices: [],
        });
      });
  };
