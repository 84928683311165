import { connect } from 'react-redux';
import { logEvent } from '~/store/modules/logger';
import { checkResearchUser } from '../utils/navigation';
import Footer from './Footer';

const checkGlookoResearchSite = state => state.session && state.session.currentUser
  && state.session.currentUser.glookoResearchSite;

const checkQRCodeURLCountryOfResidenceValueIsUSA = state =>
  state.enrollmentWizard &&
  state.enrollmentWizard.userDecryptedDetails &&
  state.enrollmentWizard.userDecryptedDetails.country === 'usa';

const mapStateToProps = state => ({
  language: state.language.language,
  currentStep: state.enrollmentWizard.currentStep,
  isMinorPage: state.enrollmentWizard.isMinorPage,
  isCountryOfResidenceUSAInQRCodeURL: checkQRCodeURLCountryOfResidenceValueIsUSA(state),
  isGlookoResearchSite: checkGlookoResearchSite(state) || checkResearchUser(),
});

const mapDispatchToProps = dispatch => ({
  logEvent: (name, data) => dispatch(logEvent(name, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
