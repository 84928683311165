import images from '~/utils/ImageStore';
import { getRedirectUrl, getMyGlookoUrl } from './navigation';

const redirectUrl = getRedirectUrl() ? encodeURIComponent(getRedirectUrl()) : '';

const partners = {
  op5: {
    name: 'insulet',
    image: images.insuletLogo,
    loginUrl: `${getMyGlookoUrl()}/op5-confirm-clinic?redirectUrl=${redirectUrl}`,
  },
};

export function getPartnerInfo(partner) {
  return partners[partner];
}
