// NOTE: This is to handle tracking events that are not updating store
//       Events will be seen by the storeTracker middleware

// ACTIONS
export const LOG_EVENT = 'session/LOG_EVENT';

// ACTION CREATORS
export const logEvent = (name, data = {}, callback) => (
  {
    type: LOG_EVENT, name, data, callback,
  }
);
