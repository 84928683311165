export const AE_COUNTRY_CODE = '+971';
export const AT_COUNTRY_CODE = '+43';
export const AU_COUNTRY_CODE = '+61';
export const BE_COUNTRY_CODE = '+32';
export const BG_COUNTRY_CODE = '+359';
export const CA_COUNTRY_CODE = '+1';
export const CH_COUNTRY_CODE = '+41';
export const CY_COUNTRY_CODE = '+357';
export const CZ_COUNTRY_CODE = '+420';
export const DE_COUNTRY_CODE = '+49';
export const DK_COUNTRY_CODE = '+45';
export const EE_COUNTRY_CODE = '+372';
export const ES_COUNTRY_CODE = '+34';
export const FI_COUNTRY_CODE = '+358';
export const FR_COUNTRY_CODE = '+33';
export const GB_COUNTRY_CODE = '+44';
export const GR_COUNTRY_CODE = '+30';
export const HR_COUNTRY_CODE = '+385';
export const HU_COUNTRY_CODE = '+36';
export const IE_COUNTRY_CODE = '+353';
export const IL_COUNTRY_CODE = '+972';
export const IS_COUNTRY_CODE = '+354';
export const IT_COUNTRY_CODE = '+39';
export const KW_COUNTRY_CODE = '+965';
export const LU_COUNTRY_CODE = '+352';
export const MT_COUNTRY_CODE = '+356';
export const NL_COUNTRY_CODE = '+31';
export const NO_COUNTRY_CODE = '+47';
export const NZ_COUNTRY_CODE = '+64';
export const PL_COUNTRY_CODE = '+48';
export const PT_COUNTRY_CODE = '+351';
export const QA_COUNTRY_CODE = '+974';
export const SA_COUNTRY_CODE = '+966';
export const SE_COUNTRY_CODE = '+46';
export const SK_COUNTRY_CODE = '+421';
export const TR_COUNTRY_CODE = '+90';
export const US_COUNTRY_CODE = '+1';
export const ZA_COUNTRY_CODE = '+27';


export const DEFAULT_COUNTRY_CODE = '+1';
