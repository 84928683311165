import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
import { onSelectDiabetesDevices, onFilterDiabetesDevices, onToggleSkipDiabetesDevices } from '~/store/modules/deviceSelection';
import DiabetesDeviceSelect from './DiabetesDeviceSelect';

const diabetesDeviceTypes = ['cgm', 'pump', 'meter', 'insulin_pen'];
const syncTypes = [
  'ios', 'android', 'uploader', 'researchUploader', 'partnerIntegration',
];
const blacklistedDevices = ['MINIMED_CONNECT'];

// TODO: Rework logic to include other "account" devices to be shown.
// This requires us to possibly have different logic around which "account"
// devices should be shown.
const isDiabetesDevice = device => (
  (device.types.some(type => diabetesDeviceTypes.includes(type)) &&
    syncTypes.some(syncType => device[syncType] === 'supported')) &&
    !blacklistedDevices.includes(device.name)
);

const deviceMatchesFilter = (device, filter) => (
  (device.brand.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
    (device.model.toLowerCase().indexOf(filter.toLowerCase()) > -1)
);

const getVisibleDiabetesDevices = (devices, filter, country) => (
  devices.filter(device => isDiabetesDevice(device) && deviceMatchesFilter(device, filter) &&
    device.supportedCountries.includes(country))
);

const mapStateToProps = state => ({
  devices: getVisibleDiabetesDevices(
    state.deviceSelection.allDiabetesDevices,
    state.deviceSelection.diabetesDeviceFilter,
    state.accountInformation.countryOfResidence ||
      (state.session && state.session.currentUser && state.session.currentUser.countryOfResidence),
  ),
  skipDiabetesDevices: state.deviceSelection.skipDiabetesDevices,
  selected: state.deviceSelection.selectedDiabetesDevices,
  filter: state.deviceSelection.diabetesDeviceFilter,
  isLoading: state.deviceSelection.deviceFetchStatus === 'loading',
  language: state.language.language,
});

const mapDispatchToProps = dispatch => ({
  /* istanbul ignore next */
  onSelectionChange: selection => dispatch(onSelectDiabetesDevices(selection)),
  /* istanbul ignore next */
  onFilterChange: filter => dispatch(onFilterDiabetesDevices(filter)),
  /* istanbul ignore next */
  onToggleSkipDiabetesDevices: () => dispatch(onToggleSkipDiabetesDevices()),
});

export default
connect(
  mapStateToProps, mapDispatchToProps,
  null, { withRefs: true },
)(DiabetesDeviceSelect);
