import { connect } from 'react-redux';
import { onMinorParentalConsent } from '~/store/modules/accountInformation';
import WithdrawParentalConsent from './WithdrawParentalConsent';

const mapStateToProps = state => ({
  userDecryptedDetails: state.enrollmentWizard.userDecryptedDetails,
  language: state.language.language,
  registerStatus: state.accountInformation.registerStatus,
  registerError: state.accountInformation.registerError,
});

const mapDispatchToProps = dispatch => ({
  captureParentalConsent: payload => dispatch(onMinorParentalConsent(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawParentalConsent);
