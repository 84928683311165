import AccountInformationContainer from
  '../EnrollmentWizard/AccountInformation/AccountInformationContainer';
import CodeActivationContainer from
  '../EnrollmentWizard/CodeActivation/CodeActivationContainer';
import TherapyContainer from
  '../EnrollmentWizard/Therapy/TherapyContainer.jsx';
import DeviceSelectionContainer from
  '../EnrollmentWizard/DeviceSelection/DeviceSelectionContainer';
import WaysToSyncContainer from
  '../EnrollmentWizard/WaysToSync/WaysToSyncContainer';
import ShippingInfoContainer from
  '../EnrollmentWizard/ShippingInfo/ShippingInfoContainer';
import DownloadUploaderContainer from
  '../EnrollmentWizard/DownloadUploader/DownloadUploaderContainer';
import EmailActivationContainer from
  '../EnrollmentWizard/EmailActivation/EmailActivationContainer';
import AutoInsuletWebLink from
  '../EnrollmentWizard/AutoWebLink/AutoInsuletWebLink';
import QRCodeActivation from '../EnrollmentWizard/QRCodeActivation/QRCodeActivationContainer';

import MinorEmailActivation from
  '../EnrollmentWizard/MinorActivation/MinorActivationContainer';
import MinorParentalConsentActivation from
  '../EnrollmentWizard/MinorParentalConsent/MinorParentalConsentContainer';
import WithdrawParentalConsent from
  '../EnrollmentWizard/WithdrawConsent/WithdrawParentalConsentContainer';

export const wizardSteps =
  {
    accountInformation: {
      container: AccountInformationContainer,
      name: 'AccountInformation',
    },
    codeActivation: {
      container: CodeActivationContainer,
      name: 'CodeActivation',
    },
    therapy: {
      container: TherapyContainer,
      name: 'Therapy',
    },
    deviceSelection: {
      container: DeviceSelectionContainer,
      name: 'DeviceSelection',
    },
    waysToSync: {
      container: WaysToSyncContainer,
      name: 'WaysToSync',
    },
    shippingInformation: {
      container: ShippingInfoContainer,
      name: 'ShippingInfo',
    },
    downloadUploader: {
      container: DownloadUploaderContainer,
      name: 'DownloadUploader',
    },
    emailActivation: {
      container: EmailActivationContainer,
      name: 'EmailActivation',
    },
    autoInsuletWebLink: {
      container: AutoInsuletWebLink,
      name: 'AutoWebLink',
    },
    qrCodeActivation: {
      container: QRCodeActivation,
      name: 'qrCodeActivation',
    },
    minorEmailActivation: {
      container: MinorEmailActivation,
      name: 'MinorEmailActivation',
    },
    minorParentalConsentActivation: {
      container: MinorParentalConsentActivation,
      name: 'MinorParentalConsentActivation',
    },
    withdrawParentalConsent: {
      container: WithdrawParentalConsent,
      name: 'WithdrawParentalConsent',
    },
  };

export const wizardEvents = {
  startedEnrollment: {
    name: 'StartedEnrollment',
  },
  completedEnrollment: {
    name: 'CompletedEnrollment',
  },
  webTextedMobileLink: {
    name: 'WebTextedMobileLink',
  },
  webClickedTab: {
    name: 'WebClickedTab',
  },
  webStartedActivation: {
    name: 'WebStartedActivation',
  },
  mobileStartedActivation: {
    name: 'MobileStartedActivation',
  },
  mobileClickedActivateOnApp: {
    name: 'MobileClickedActivateOnApp',
  },
  qrCodeStartedActivation: {
    name: 'QRCodeStartedActivation',
  },
  qrCodeClickGetStarted: {
    name: 'QRCodeClickGetStarted',
  },
  qrCodeSubmitDob: {
    name: 'QRCodeSubmitDob',
  },
};
