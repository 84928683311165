import { fetchEligibilityAddress, saveUserShippingInfo, sendWelcomeKit } from '~/services/userService';
import { logError } from '~/services/loggingService';
import { onProceedToUploader } from './enrollmentWizard';

// ACTIONS
export const UPDATE_ATTRIBUTE = 'shippingInfo/UPDATE_ATTRIBUTE';
export const SAVE_SHIPPING_REQUESTED = 'shippingInfo/SAVE_SHIPPING_REQUESTED';
export const SAVE_SHIPPING_RECEIVED = 'shippingInfo/SAVE_SHIPPING_RECEIVED';
export const ELIGIBILTY_REQUESTED = 'shippingInfo/ELIGIBILTY_REQUESTED';
export const ELIGIBILTY_RECEIVED = 'shippingInfo/ELIGIBILTY_RECEIVED';

const initialState = {
  isSaving: false,
  savingError: undefined,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ATTRIBUTE:
      return {
        ...state,
        [action.attribute]: action.value,
      };

    case SAVE_SHIPPING_REQUESTED:
      return {
        ...state,
        isSaving: true,
      };

    case SAVE_SHIPPING_RECEIVED:
      return {
        ...state,
        isSaving: false,
        savingError: action.savingError,
      };

    case ELIGIBILTY_REQUESTED:
      return {
        ...state,
        eligibityStatus: 'pending',
      };

    case ELIGIBILTY_RECEIVED:
      return {
        ...state,
        eligibityStatus: action.eligibityStatus,
        ...action.eligibilityAddress,
      };

    default:
      return state;
  }
};

// ACTION CREATORS
export const onUpdateAttribute = update => (
  { type: UPDATE_ATTRIBUTE, attribute: update.attribute, value: update.value }
);

export const onSaveShipping = () =>
  (dispatch, getState) => {
    const {
      line1, line2, city, state, country, zipcode,
    } = getState().shippingInfo;
    const shippingInfo = {
      line1, line2, city, state, country, zipcode,
    };
    const { selectedDiabetesDevices, platform } = getState().deviceSelection;
    const { selectedVariants } = getState().shopify;
    dispatch({ type: SAVE_SHIPPING_REQUESTED });
    saveUserShippingInfo(shippingInfo)
      .then(() => {
        dispatch({
          type: SAVE_SHIPPING_RECEIVED,
          savingError: undefined,
        });
        sendWelcomeKit(selectedVariants, selectedDiabetesDevices, platform);
        dispatch(onProceedToUploader()); // TODO: Go to correct placewhen there
      })
      .catch((error) => {
        dispatch({
          type: SAVE_SHIPPING_RECEIVED,
          savingError: error,
        });
        logError(error);
      });
  };

export const fetchUserEligibility = overrides =>
  (dispatch, getState) => {
    const { eligibityStatus } = getState().shippingInfo;

    // we will return if fetched already
    if (eligibityStatus) {
      return;
    }

    dispatch({ type: ELIGIBILTY_REQUESTED });

    fetchEligibilityAddress()
      .then((res) => {
        // override as requested (for example country)
        const address = Object.assign({}, res.data.address, overrides);
        // normalize case for state
        address.state = address.state ? address.state.toLowerCase() : address.state;
        dispatch({
          type: ELIGIBILTY_RECEIVED,
          eligibityStatus: 'fetched',
          eligibilityAddress: address,
        });
      })
      .catch((error) => {
        dispatch({
          type: ELIGIBILTY_RECEIVED,
          eligibityStatus: 'notFound',
          eligibilityAddress: overrides,
        });
        logError(error);
      });
  };

