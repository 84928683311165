import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.sass';

class TextField extends Component {
  constructor() {
    super();
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.state = { touched: false, focused: false };
    this.el = React.createRef();
    this.cursor = 0;
  }

  componentDidUpdate() {
    if (this.state.focused && this.props.getCursor) {
      this.setCursor();
    }
  }

  onFocus(e) {
    this.setState({ focused: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur() {
    this.setState({ focused: false });
    this.props.onBlur();
  }

  setCursor() {
    if (!this.el || !this.el.current) {
      return;
    }
    const {
      current: { selectionStart },
    } = this.el;
    if (selectionStart < this.cursor) {
      this.cursor -= 1;
    }

    this.el.current.selectionEnd = this.cursor;
    this.el.current.selectionStart = this.cursor;
  }

  type() {
    if (this.props.isPassword) { return 'password'; }
    return 'text';
  }

  classes() {
    return classnames({
      TextField: true, error: this.showError(),
    });
  }

  valueChange(event) {
    // for formatted text like phone numbers, handle managed cursor
    if (this.props.getCursor) {
      const {
        current: { selectionStart },
      } = this.el;
      this.cursor = this.props.getCursor(event.target.value, selectionStart);
    }

    event.preventDefault();
    this.props.onChange(event.target.value);
    this.touch();
  }

  showError() {
    return this.props.showError || (this.state.touched && !this.valid());
  }

  valid() {
    return !this.props.required || this.props.isValid(this.props.value);
  }

  focus() {
    if (this.el && this.el.focus) {
      this.el.focus();
    }
  }

  touch() {
    this.setState({ touched: true });
  }

  renderInput() {
    return (
      <input
        className="TextField-input-field"
        onBlur={this.onBlur}
        onChange={this.valueChange}
        onFocus={this.onFocus}
        placeholder={this.props.placeholder}
        type={this.type()}
        ref={this.el}
        value={this.props.value}
        onKeyPress={this.props.onKeyPress}
        onCopy={this.props.onCopy}
        onCut={this.props.onCut}
        disabled={this.props.disabled}
      />
    );
  }

  renderCaption() {
    if (this.props.caption) {
      return (
        <div className="TextField-caption">{ this.props.caption }</div>
      );
    }
    return null;
  }

  render() {
    return (
      <div className={this.classes()}>
        {this.props.label &&
          <div className="TextField-label">{ this.props.label }</div>
        }
        <div className="TextField-input">
          { this.renderInput() }
          { this.props.children }
        </div>
        { this.renderCaption() }
      </div>
    );
  }
}

TextField.propTypes = {
  caption: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  isPassword: PropTypes.bool,
  isValid: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  onFocus: PropTypes.func,
  onCopy: PropTypes.func,
  onCut: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showError: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  getCursor: PropTypes.func,
};

TextField.defaultProps = {
  caption: undefined,
  children: undefined,
  isPassword: false,
  onBlur: () => {},
  onFocus: undefined,
  onKeyPress: () => {},
  onCopy: () => {},
  onCut: () => {},
  placeholder: undefined,
  required: false,
  showError: false,
  value: '',
  label: '',
  disabled: false,
  isValid: value => value && value !== '',
  getCursor: undefined,
};

export default TextField;
