import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUpdateAttribute } from '~/store/modules/accountInformation';
import { AC_WEB_TEXTED_MOBILE_LINK } from '~/store/modules/enrollmentWizard';
import MobileEmailActivation from './MobileEmailActivation';

const mapStateToProps = state => ({
  countryCode: state.accountInformation.countryCode,
  language: state.language.language,
  mobilePhone: state.accountInformation.mobilePhone,
  phoneCountry: state.accountInformation.phoneCountry,
  receiveSms: state.accountInformation.receiveSms,
});

const mapDispatchToProps = dispatch => ({
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  onWebTextedMobileLink: () => dispatch({ type: AC_WEB_TEXTED_MOBILE_LINK }),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileEmailActivation));
