import moment from 'moment';

export const DEFAULT_MIN_AGE = 16;

export function isOfAge(dob, majorityAge) {
  const minAge = majorityAge || DEFAULT_MIN_AGE;
  const dobDate = dob.format().split('T')[0];
  const nowDate = moment().format().split('T')[0];
  return moment(nowDate).diff(dobDate, 'years', false) >= minAge;
}

export function daysInMonth(month, year) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  if (currentYear === year && currentMonth === month) {
    return currentDate.getDate();
  }

  // if no year, assume a leap year to handle 29 Feb
  return new Date(!year ? 2016 : year, month, 0).getDate();
}

export function monthsInYear(year) {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1;

  if (currentYear === year) {
    return currentMonth;
  }

  return 12;
}

const enMonths = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
];

export function getMonthsForLocale(locale) {
  try {
    const format = new Intl.DateTimeFormat(locale, { month: 'long' });
    const months = [];
    for (let month = 0; month < 12; month += 1) {
      const testDate = new Date(Date.UTC(2000, month, 2, 0, 0, 0));
      months.push(format.format(testDate));
    }
    return months;
  } catch (error) {
    return enMonths; // IE 10 - can check if we can create polyfill later
  }
}
