import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio } from '@glooko/common-ui';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import './style.sass';

class UnitOfMeasure extends React.Component {
  onChangeValue(val) {
    this.props.onChange(val);
  }

  render() {
    const {
      defaultUOM, visible, t,
    } = this.props;

    if (!visible) {
      return null;
    }

    const uomOptions = [
      {
        id: 'mmoll',
        label: t('mmoll'),
        disabled: null,
        className: '',
      },
      {
        id: 'mgdl',
        label: t('mgdl'),
        disabled: null,
        className: '',
      },
    ];

    return (
      <div className="UnitOfMeasure" >
        <div className="UnitOfMeasure-label">{t('unitOfMeasure')} </div>
        <div className="UnitOfMeasure-info">{t('unitOfMeasureInfo')} </div>
        <RadioGroup
          className="UnitOfMeasure-radio"
          value={!defaultUOM ? 'none' : defaultUOM}
          onChange={(event) => { this.onChangeValue(event.currentTarget.value, 'meterUnits'); }}
          dataAttributes={{ testid: 'unit-of-measure' }}
        >
          {
            uomOptions.map((option, index) => {
              const { id, label } = option;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Radio label={label} value={id} name="radio-group-uom" key={index} /> // NOSONAR
              );
            })
          }
        </RadioGroup>
      </div>
    );
  }
}

UnitOfMeasure.propTypes = {
  defaultUOM: PropTypes.string,
  visible: PropTypes.bool,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

UnitOfMeasure.defaultProps = {
  defaultUOM: undefined,
  visible: true,
  onChange: () => {},
};

export default withTranslate(UnitOfMeasure);
