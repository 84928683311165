import { combineReducers } from 'redux';
import languageReducer from './modules/language';
import accountInformationReducer from './modules/accountInformation';
import deviceSelectionReducer from './modules/deviceSelection';
import enrollmentWizardReducer from './modules/enrollmentWizard';
import shippingInfoReducer from './modules/shippingInfo';
import countriesReducer from './modules/countries';
import sessionReducer from './modules/session';
import shopifyReducer from './modules/shopify';
import therapyReducer from './modules/therapy';
import partnerConsentsReducer from './modules/partnerConsents';

export default combineReducers({
  language: languageReducer,
  accountInformation: accountInformationReducer,
  deviceSelection: deviceSelectionReducer,
  shippingInfo: shippingInfoReducer,
  countries: countriesReducer,
  session: sessionReducer,
  enrollmentWizard: enrollmentWizardReducer,
  shopify: shopifyReducer,
  therapy: therapyReducer,
  partnerConsents: partnerConsentsReducer,
});
