import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onMinorActivate } from '~/store/modules/accountInformation';
import MinorActivation from './MinorActivation';

const mapStateToProps = state => ({
  userDecryptedDetails: state.enrollmentWizard.userDecryptedDetails,
  language: state.language.language,
  registerStatus: state.accountInformation.registerStatus,
  registerError: state.accountInformation.registerError,
});

const mapDispatchToProps = dispatch => ({
  onMinorActivate: payload => dispatch(onMinorActivate(payload)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MinorActivation));
