/* global MY_GLOOKO_LOG_IN_URL */
import queryString from 'query-string';
import { detectBrowserLanguage } from '../utils/defaultLanguageSelection';

export const PRIVACY_POLICY_URL = 'http://www.glooko.com/privacy';
export const TERMS_OF_USE_URL = 'http://www.glooko.com/terms-of-use';
export const APPLE_STORE_GLOOKO_APP = 'https://itunes.apple.com/us/app/glooko-track-diabetes-data/id471942748?mt=8';
export const GOOGLE_PLAY_GLOOKO_APP = 'https://play.google.com/store/apps/details?id=com.glooko.logbook&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
const DOWNLOAD_UPLOADER_URL = 'https://s3.amazonaws.com/glooko-uploaders';
const UPLOADER_INSTALLER_MAC = 'glooko_uploader_3.4.0_macOS.pkg';
const UPLOADER_INSTALLER_WIN = 'glooko_uploader_3.4.0_windows.exe';

export function getDownloadUploaderUrl(os) {
  let downloaderName = UPLOADER_INSTALLER_WIN;
  if (os.toLowerCase().indexOf('mac') >= 0) {
    downloaderName = UPLOADER_INSTALLER_MAC;
  }
  return `${DOWNLOAD_UPLOADER_URL}/${downloaderName}`;
}

function appendLocaleToLogInUrl(locale) {
  return `${MY_GLOOKO_LOG_IN_URL}?locale=${locale}`;
}

export function getLogInURL(currentLanguage) {
  const queryParams = queryString.parse(window.location.search);
  const { locale } = queryParams;
  const browserLanguage = detectBrowserLanguage();
  const hasLocaleParamAndLanguageIsChanged = locale ? locale !== currentLanguage : false;
  const doNotHaveLocaleParamAndLanguageIsChanged =
    locale === undefined && currentLanguage !== browserLanguage;
  if (
    hasLocaleParamAndLanguageIsChanged ||
    doNotHaveLocaleParamAndLanguageIsChanged) {
    return appendLocaleToLogInUrl(currentLanguage);
  }
  if (locale && locale === currentLanguage) {
    return appendLocaleToLogInUrl(locale);
  }
  return `${MY_GLOOKO_LOG_IN_URL}`;
}
