import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUpdateAttribute, onEmailActivate } from '~/store/modules/accountInformation';
import {
  AC_WEB_STARTED_ACTIVATION, AC_WEB_CLICKED_TAB,
  AC_MOBILE_STARTED_ACTIVATION, AC_MOBILE_CLICKED_ACTIVATE_ON_APP,
} from '~/store/modules/enrollmentWizard';
import EmailActivation from './EmailActivation';
import NovoEmailActivation from './NovoEmailActivation';
import { disableMobileActivation, checkProfessional, getMajorityAge } from '../../utils/navigation';
import { DEFAULT_MIN_AGE } from '../../utils/dateUtils';
import { emailActivationValid } from '../AccountInformation/validations/accountInformation';

const mapStateToProps = (state) => {
  const majorityAge = getMajorityAge() || DEFAULT_MIN_AGE;
  const immediateGuardianConsent = false;
  return {
    password: state.accountInformation.password,
    dateOfBirth: state.accountInformation.dateOfBirth,
    majorityAge,
    consents: state.accountInformation.consents,
    tempToken: state.accountInformation.tempToken,
    professional: state.accountInformation.professional,
    language: state.language.language,
    registerStatus: state.accountInformation.registerStatus,
    registerError: state.accountInformation.registerError,
    isValid: emailActivationValid(state.accountInformation, majorityAge, immediateGuardianConsent),
    guardianInfo: state.accountInformation.guardianInfo,
  };
};

const isDisableMobileActivation = disableMobileActivation();

const isProfessional = checkProfessional();

const mapDispatchToProps = dispatch => ({
  onEmailActivate: () => dispatch(onEmailActivate()),
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  onWebStartedActivation: () => dispatch({ type: AC_WEB_STARTED_ACTIVATION }),
  onWebClickedTab: tab => dispatch({ type: AC_WEB_CLICKED_TAB, tab }),
  onMobileStartedActivation: () => dispatch({ type: AC_MOBILE_STARTED_ACTIVATION }),
  onMobileClickedActivateOnApp: () => dispatch({ type: AC_MOBILE_CLICKED_ACTIVATE_ON_APP }),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(isDisableMobileActivation || isProfessional
  ? NovoEmailActivation : EmailActivation));
