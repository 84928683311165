import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUpdateAttribute } from '~/store/modules/accountInformation';
import {
  AC_QR_CODE_STARTED_ACTIVATION,
  AC_QR_CODE_CLICK_GET_STARTED,
  AC_QR_CODE_SUBMIT_DOB,
} from '~/store/modules/enrollmentWizard';
import { onChangeLanguage } from '../../store/modules/language';
import QRCodeActivation from './QRCodeActivation';

const mapStateToProps = state => ({
  userDecryptedDetails: state.enrollmentWizard.userDecryptedDetails,
  language: state.language.language,
  dateOfBirth: state.accountInformation.dateOfBirth,
});

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: language => dispatch(onChangeLanguage(language)),
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  onQRCodeStartedActivation: () => dispatch({ type: AC_QR_CODE_STARTED_ACTIVATION }),
  onQRCodeClickGetStarted: () => dispatch({ type: AC_QR_CODE_CLICK_GET_STARTED }),
  onQRCodeSubmitDob: () => dispatch({ type: AC_QR_CODE_SUBMIT_DOB }),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(QRCodeActivation));
