import i18next from '~/utils/i18n/i18n';
import { getLanguage } from '../../utils/defaultLanguageSelection';
import { fetchCountries } from './countries';

// ACTIONS
export const CHANGE_LANGUAGE = 'language/CHANGE_LANGUAGE';

const initialState = {
  language: getLanguage(),
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };

    default:
      return state;
  }
};

// ACTION CREATORS

export const onChangeLanguage = language =>
  (dispatch) => {
    i18next.changeLanguage(language, (err) => {
      if (!err) {
        dispatch({
          type: CHANGE_LANGUAGE,
          language,
        });
        dispatch(fetchCountries(language));
      }
    });
  };
