import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '../../../../shared/withTranslate/withTranslate';

const HardwareDescription = ({
  device, title, t, price,
}) => (
  <div className="HardwareDescription">
    <div className="HardwareDescription-title">
      { title } ({ price })
    </div>
    <div className="HardwareDescription-for-device">
      { t('for') } { `${device.brand} ${device.model}` }
    </div>
  </div>
);

HardwareDescription.propTypes = {
  device: PropTypes.object.isRequired,
  price: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(HardwareDescription);
