import React from 'react';
import PropTypes from 'prop-types';

import images from '../../../../utils/ImageStore';
import { getDeviceImageUrl } from '../../../../utils/DeviceImages';

const isCompatible = (device, platform) => device[platform] === 'supported';
const compatibilityImage = (device, platform) => {
  if (isCompatible(device, platform)) { return images.blueCheck; }
  return images.darkX;
};
const iconStyle = url => ({ backgroundImage: `url(${url})` });

const CompatibilityRow = ({
  device, mobilePlatform, showMobile, showUploader,
}) => {
  // handle cases where brand is included in model name
  const { brand, id, model } = device;
  const displayName = model.toLowerCase().startsWith(brand.toLowerCase()) ? model : `${brand} ${model}`;

  return (
    <div className="CompatibilityRow">
      <div className="CompatibilityRow-device">
        <div
          className="CompatibilityRow-device-image"
          style={iconStyle(getDeviceImageUrl(id))}
        />
        <div className="CompatibilityRow-device-name">
          {displayName}
        </div>
      </div>
      {showMobile &&
        <div className="CompatibilityRow-cell">
          <img
            alt="mobile"
            className="CompatibilityRow-icon"
            src={compatibilityImage(device, mobilePlatform)}
          />
        </div>
      }
      {showUploader &&
        <div className="CompatibilityRow-cell">
          <img
            alt="uploader"
            className="CompatibilityRow-icon"
            src={compatibilityImage(device, 'uploader')}
          />
        </div>
      }
    </div>
  );
};

CompatibilityRow.propTypes = {
  mobilePlatform: PropTypes.string,
  showUploader: PropTypes.bool,
  showMobile: PropTypes.bool,
  device: PropTypes.object.isRequired,
};

CompatibilityRow.defaultProps = {
  mobilePlatform: 'none',
  showMobile: true,
  showUploader: true,
};

export default CompatibilityRow;
