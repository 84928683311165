import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@glooko/common-ui';
import images from '~/utils/ImageStore';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import './style.sass';

const WebAppLink = (props) => {
  const { getWebAppUrl, t } = props;

  const handleLinkClick = (e) => {
    e.preventDefault();
    const goToWebApp = () => {
      window.location.assign(getWebAppUrl());
    };
    props.logEvent('en_join_app_link_clicked', {}, goToWebApp);
  };

  return (
    <div className="WebAppLink">
      <div className="WebAppLink-Image" >
        <img src={images.downloadPC} alt="" />
      </div>
      <div className="WebAppLink-Main" >
        <div className="WebAppLink-header" >
          {t('openWebAppTitle')}
        </div>
        <p>
          {t('webAppDescription')}
        </p>
        <Button
          onClick={handleLinkClick}
          variation="secondary"
          dataAttributes={{ testid: 'web-app-link-open-web-app' }}
        >
          {t('openWebApp')}
        </Button>
      </div>
    </div>
  );
};

WebAppLink.propTypes = {
  getWebAppUrl: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  logEvent: PropTypes.func,
};

WebAppLink.defaultProps = {
  logEvent: () => {},
};

export default withTranslate(WebAppLink);
