import { isIOS, isAndroid, osName, osVersion, browserName, fullBrowserVersion } from 'react-device-detect';

export const detectedPlatform = () => {
  if (isIOS) {
    return 'ios';
  } else if (isAndroid) {
    return 'android';
  }
  return undefined;
};

export const detectedOS = () => ({ name: osName, version: osVersion });

export const detectedBrowser = () => ({ name: browserName, version: fullBrowserVersion });
