import React from 'react';
import { Button } from '@glooko/common-ui';
import PropTypes from 'prop-types';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { sendSMSLink } from '~/services/smsService.js';
import { validPhone } from '~/utils/phoneNumberUtil';
import PhoneNumberField from '../../shared/PhoneNumberField/PhoneNumberField';
import './style.sass';

export class AppLinkSender extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleSendSMSLink = this.handleSendSMSLink.bind(this);
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });
  }

  disableSendLink() {
    const {
      countryCode, mobilePhone, receiveSms, phoneCountry,
    } = this.props;
    return !receiveSms || !mobilePhone || !validPhone(countryCode, mobilePhone, phoneCountry);
  }

  handleSendSMSLink() {
    const {
      countryCode, mobilePhone, language, receiveSms,
    } = this.props;

    if (this.disableSendLink()) {
      return;
    }

    sendSMSLink(countryCode, mobilePhone, language, receiveSms);
  }

  render() {
    const {
      countryCode, mobilePhone, phoneCountry, receiveSms, t,
    } = this.props;
    return (
      <div className="AppLinkSender">
        <div className="AppLinkSender-title">{t('sendLinkTitle')}</div>
        <PhoneNumberField
          onChangeCountryCode={(v) => { this.onChangeValue(v, 'countryCode'); }}
          onChangePhone={(v) => { this.onChangeValue(v, 'mobilePhone'); }}
          onChangePhoneCountry={
            (v) => { this.onChangeValue(v, 'phoneCountry'); }
          }
          onChangeConsent={
            (v) => { this.onChangeValue(v, 'receiveSms'); }
          }
          phoneCountry={phoneCountry}
          phoneNumber={mobilePhone}
          receiveSms={receiveSms}
          countryCode={countryCode}
          ref={(el) => { this.mobilePhoneRef = el; }}
        />
        <Button
          onClick={this.handleSendSMSLink}
          disabled={this.disableSendLink()}
          variation="secondary"
          dataAttributes={{ testid: 'app-link-sender-send-link' }}
        >
          {t('sendLink')}
        </Button>
      </div>
    );
  }
}

AppLinkSender.propTypes = {
  countryCode: PropTypes.string,
  mobilePhone: PropTypes.string,
  onUpdateAttribute: PropTypes.func.isRequired,
  phoneCountry: PropTypes.string,
  receiveSms: PropTypes.bool,
  language: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

AppLinkSender.defaultProps = {
  countryCode: undefined,
  mobilePhone: undefined,
  phoneCountry: 'us',
  receiveSms: false,
};

export default withTranslate(AppLinkSender);
