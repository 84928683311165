import React from 'react';

import FooterContainer from './Footer/FooterContainer';
import HeaderContainer from './Header/HeaderContainer';
import Main from './Main/Main.jsx';

import './utils/i18n/i18n';
import './style.sass';

const App = () => (
  <div className="App">
    <div className="App-content" >
      <HeaderContainer />
      <Main />
    </div>
    <div className="App-footer">
      <FooterContainer />
    </div>
  </div>
);

export default App;
