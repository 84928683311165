import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './style.sass';

const optionValues = (option) => {
  if (typeof option === 'object') {
    return option;
  }
  return {
    value: option,
    displayValue: option,
  };
};

class Select extends React.Component {
  constructor() {
    super();
    this.valueChange = this.valueChange.bind(this);
    this.state = {
      touched: false,
    };
  }

  valueChange(event) {
    this.props.onChange(event.target.value);
    this.touch();
  }

  showError() { return this.state.touched && !this.isValid(); }

  isValid() { return this.props.value !== ''; }

  touch() { this.setState({ touched: true }); }

  renderSelectField() {
    return (
      <select
        name={this.props.name}
        value={this.props.value}
        onChange={this.valueChange}
        disabled={this.props.disabled}
      >
        {
          this.props.options.map((option) => {
            const { value, displayValue } = optionValues(option);
            return (
              <option value={value} key={value}>
                { displayValue }
              </option>
            );
          })
        }
      </select>
    );
  }

  render() {
    const selectClasses = classNames({ Select: true, error: this.showError() });
    return (
      <div className={selectClasses}>
        {this.props.label &&
          <div className="Select-label">
            { this.props.label }
          </div>
        }
        { this.renderSelectField() }
      </div>
    );
  }
}

Select.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.any,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

Select.defaultProps = {
  label: undefined,
  value: '',
  name: '',
  disabled: false,
};

export default Select;
