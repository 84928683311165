import thunkMiddleware from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import { fetchDiabetesDevices } from '~/store/modules/deviceSelection';
import rootReducer from './combinedReducers.js';
import { logger } from './storeTracker';

const middleware = [
  logger,
  thunkMiddleware,
];

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware),
);

store.dispatch(fetchDiabetesDevices());

store.subscribe(() => {
  const { countries, accountInformation } = store.getState();
  window.countriesWithApiUrls = {
    countriesList: countries.allCountries,
    countrySelected: accountInformation.countryOfResidence,
  };
});

export default store;
