import { detect } from 'detect-browser';
import { getLocaleQueryParam } from '../utils/navigation';
import { availableLocales } from '../utils/i18n/I18nConfig';

export const defaultLanguage = 'en';
// Arabic not to be added to dropdown options until fully launched
export const languageOptions = () => [
  { displayValue: 'Čeština', value: 'cs' },
  { displayValue: 'Dansk', value: 'da' },
  { displayValue: 'Deutsch', value: 'de' },
  { displayValue: 'English (USA)', value: 'en' },
  { displayValue: 'English (UK)', value: 'en-GB' },
  { displayValue: 'Español (EE. UU.)', value: 'es-US' },
  { displayValue: 'Español (España)', value: 'es' },
  { displayValue: 'Français (Canada)', value: 'fr-CA' },
  { displayValue: 'Français (France)', value: 'fr' },
  { displayValue: 'Hrvatski', value: 'hr' },
  { displayValue: 'Italiano', value: 'it' },
  { displayValue: 'Nederlands', value: 'nl' },
  { displayValue: 'Norsk', value: 'nb-NO' },
  { displayValue: 'Polskie', value: 'pl' },
  { displayValue: 'Português', value: 'pt-PT' },
  { displayValue: 'Slovenčina', value: 'sk' },
  { displayValue: 'Svenska', value: 'sv' },
  { displayValue: 'suomi', value: 'fi' },
  { displayValue: 'Türkçe', value: 'tr' },
  { displayValue: 'Ελληνικά', value: 'el' },
  { displayValue: 'العربية', value: 'ar' },
];
export const SafariBrowserLanguageCodes = {
  'ar-ar': 'ar',
  'cs-cz': 'cs',
  'da-dk': 'da',
  'de-de': 'de',
  'en-us': 'en',
  'en-gb': 'en-GB',
  'es-es': 'es',
  'es-xl': 'es-US',
  'fr-ca': 'fr-CA',
  'fr-fr': 'fr',
  'hr-hr': 'hr',
  'it-it': 'it',
  'nb-no': 'nb-NO',
  'nl-nl': 'nl',
  'pl-pl': 'pl',
  'pt-pt': 'pt-PT',
  'sk-sk': 'sk',
  'sv-se': 'sv',
  'fi-fi': 'fi',
  'tr-tr': 'tr',
  'el-gr': 'el',
};
export const InternetExplorerBrowserLanguageCodes = {
  'ar-ar': 'ar',
  'cs-cz': 'cs',
  'da-dk': 'da',
  'de-de': 'de',
  'en-us': 'en',
  'en-gb': 'en-GB',
  'es-es': 'es',
  'es-xl': 'es-US',
  'fr-ca': 'fr-CA',
  'fr-fr': 'fr',
  'hr-hr': 'hr',
  'it-it': 'it',
  'nb-no': 'nb-NO',
  'nl-nl': 'nl',
  'pl-pl': 'pl',
  'pt-pt': 'pt',
  'sk-sl': 'sk',
  'sv-se': 'sv',
  'fi-fi': 'fi',
  'tr-tr': 'tr',
  'el-gr': 'el',
};

// Currently Norwegian-Bokmal (nb-NO) and Portuguese (pt-PT)
// are not always recognized, so we have an extra check for them while
// no other locales of these languages are supported
export function checkForExceptionLanguages(browserLanguage) {
  if (browserLanguage.indexOf('nb') !== -1) return 'nb-NO';
  if (browserLanguage.indexOf('pt') !== -1) return 'pt-PT';
  return browserLanguage;
}

function getLanguageCode(browserCodes) {
  return browserCodes[navigator.language.toLowerCase()];
}

function strippedLanguageCode(language) {
  return language.split('-')[0];
}

export function detectBrowserLanguage() {
  if (availableLocales.includes(navigator.language)) return navigator.language;

  if ((detect() && detect().name === 'safari') && getLanguageCode(SafariBrowserLanguageCodes)) {
    return getLanguageCode(SafariBrowserLanguageCodes);
  }
  if ((detect() && detect().name === 'ie') && getLanguageCode(InternetExplorerBrowserLanguageCodes)) {
    return getLanguageCode(InternetExplorerBrowserLanguageCodes);
  }
  if (availableLocales.includes(strippedLanguageCode(navigator.language))) {
    return strippedLanguageCode(navigator.language);
  }
  return checkForExceptionLanguages(navigator.language);
}

export function getLanguage() {
  const localeParam = getLocaleQueryParam();
  const userPreferredLng = localeParam || detectBrowserLanguage();
  return languageOptions().some(elem => elem.value === userPreferredLng) || userPreferredLng === 'ar'
    ? userPreferredLng
    : defaultLanguage;
}
