import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import HardwareSelection from './HardwareSelection';
import { onDeselectVariant, onSelectVariant, onVisitHardwareSelection } from
  '../../../store/modules/shopify';
import { availableProducts } from '../../../utils/hardwareSelectionUtil';

const fullDevices = state =>
  state.deviceSelection.selectedDiabetesDevices.map(deviceId =>
    state.deviceSelection.allDiabetesDevices.find(device => device.id === deviceId));

const mapStateToProps = state => ({
  availableHardware:
    availableProducts(
      state.session.currentUser,
      fullDevices(state),
      state.shopify.products,
      state.deviceSelection.androidChargingCord,
      state.deviceSelection.platform,
    ),
  hardwareVisited: state.shopify.hardwareVisited,
  language: state.language.language,
  selectedVariants: state.shopify.selectedVariants,
});

const mapDispatchToProps = dispatch => ({
  onDeselectVariant: variantId => dispatch(onDeselectVariant(variantId)),
  onSelectVariant: variantId => dispatch(onSelectVariant(variantId)),
  onVisitHardwareSelection: () => dispatch(onVisitHardwareSelection()),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(HardwareSelection));
