/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@glooko/common-ui';

import './style.sass';

const EnrollmentWizardNavigation = props => (
  <div className="EnrollmentWizardNavigation">
    {props.showBack && <Button
      disabled={!props.canGoBack}
      onClick={props.onGoBack}
      variation="secondary"
      dataAttributes={{ testid: 'enrollment-wizard-navigation-secondary' }}
    >
      {props.secondaryLabel}
    </Button>}
    {props.showContinue && <Button
      disabled={!props.canContinue}
      onClick={props.canContinue ? props.onContinue : () => {}}
      dataAttributes={{ testid: 'enrollment-wizard-navigation-primary' }}
    >
      {props.primaryLabel}
    </Button>}
  </div>
);

EnrollmentWizardNavigation.propTypes = {
  canGoBack: PropTypes.bool,
  canContinue: PropTypes.bool,
  onContinue: PropTypes.func,
  onGoBack: PropTypes.func,
  primaryLabel: PropTypes.string,
  secondaryLabel: PropTypes.string,
  showBack: PropTypes.bool,
  showContinue: PropTypes.bool,
};

EnrollmentWizardNavigation.defaultProps = {
  canGoBack: false,
  canContinue: false,
  onContinue: () => {},
  onGoBack: () => {},
  primaryLabel: '',
  secondaryLabel: '',
  showBack: false,
  showContinue: false,
};

export default EnrollmentWizardNavigation;
