import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tile } from '@glooko/common-ui';
import './style.sass';

const ChoiceCard = ({
  onClick, title, details, ...props
}) => (
  <Tile className="ChoiceCard" onClick={onClick} {...props}>
    <div className="ChoiceCard-content">
      <div className="ChoiceCard-content-choice">
        <div className="ChoiceCard-content-choice-title">{title}</div>
        <div className="ChoiceCard-content-choice-details">{details}</div>
      </div>
      <Icon name="arrow-right" className="ChoiceCard-content-icon" />
    </div>
  </Tile>
);

ChoiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  details: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ChoiceCard;
