
export function getAppleLang(language) {
  // want in lower case dialect format (fr-ca'), or lang-lang if no dialect ('fr')
  let appleLang = language.toLowerCase();
  if (appleLang.split('-').length === 1) {
    appleLang = appleLang === 'en' ? 'en-us' : `${language}-${language}`;
  } else if (appleLang === 'nb-no') {
    appleLang = 'no-nb';
  }

  return appleLang;
}

export function getGoogleLang(language) {
  // always want non-dialect (e.g. 'fr')
  if (language === 'nb-NO') {
    return 'no';
  }
  return language.split('-')[0];
}

export function getAppleBadgeImage(language) {
  const appleLang = getAppleLang(language);
  return `https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/${appleLang}?size=96x30&amp;releaseDate=1320796800&h=90cef38cf5637a6af1d8dcfd2c225f72`;
}

export function getGoogleBadgeImage(language) {
  const googleLang = getGoogleLang(language);

  return `https://play.google.com/intl/en_us/badges/images/generic/${googleLang}_badge_web_generic.png`;
}
