import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const CheckboxCategoryItem = ({ label }) => (
  <div className="CheckboxCategoryItem">
    {label}
  </div>
);

CheckboxCategoryItem.propTypes = {
  label: PropTypes.string,
};

CheckboxCategoryItem.defaultProps = {
  label: '',
};

export default CheckboxCategoryItem;
