import React from 'react';
import queryString from 'query-string';
import { Button } from '@glooko/common-ui';
import PropTypes from 'prop-types';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { sendDeepLink } from '~/services/smsService.js';
import { validPhone } from '~/utils/phoneNumberUtil';
import { logError } from '~/services/loggingService.js';
import PhoneNumberField from '../../../shared/PhoneNumberField/PhoneNumberField';
import './style.sass';

class MobileEmailActivation extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleSendSMSLink = this.handleSendSMSLink.bind(this);
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });
  }

  disableSendLink() {
    const {
      countryCode, mobilePhone, receiveSms, phoneCountry,
    } = this.props;
    return !receiveSms || !mobilePhone || !validPhone(countryCode, mobilePhone, phoneCountry);
  }

  handleSendSMSLink() {
    const {
      countryCode, mobilePhone, language, receiveSms, setTextMessageSentStatus,
      onWebTextedMobileLink,
    } = this.props;
    const { tempToken } = queryString.parse(this.props.location.search);

    if (this.disableSendLink()) {
      return;
    }
    onWebTextedMobileLink();
    sendDeepLink(countryCode, mobilePhone, language, receiveSms, tempToken)
      .then((res) => {
        if (res.status === 200) {
          setTextMessageSentStatus();
        }
      })
      .catch((err) => {
        logError(err);
      });
  }

  render() {
    const {
      countryCode, mobilePhone, phoneCountry, receiveSms, t,
    } = this.props;
    const sendLinkButtonDisabled = this.disableSendLink();

    return (
      <div className="EmailActivation-web MobileAppLinkSender">
        <div className="EmailActivation-form">
          <div className="MobileAppLinkSender-title">{t('sendLinkTitle')}</div>
          <PhoneNumberField
            onChangeCountryCode={(v) => { this.onChangeValue(v, 'countryCode'); }}
            onChangePhone={(v) => { this.onChangeValue(v, 'mobilePhone'); }}
            onChangePhoneCountry={
            (v) => { this.onChangeValue(v, 'phoneCountry'); }
          }
            onChangeConsent={
            (v) => { this.onChangeValue(v, 'receiveSms'); }
          }
            phoneCountry={phoneCountry}
            phoneNumber={mobilePhone}
            receiveSms={receiveSms}
            countryCode={countryCode}
            ref={(el) => { this.mobilePhoneRef = el; }}
            isMobileEmailActivation
          />
          <div className="MobileAppLinkSender-button">
            <Button
              onClick={!sendLinkButtonDisabled ? this.handleSendSMSLink : () => {}}
              disabled={sendLinkButtonDisabled}
              dataAttributes={{ testid: 'mobile-email-activation-send-link' }}
            >
              {t('sendLink')}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

MobileEmailActivation.propTypes = {
  countryCode: PropTypes.string,
  mobilePhone: PropTypes.string,
  onUpdateAttribute: PropTypes.func.isRequired,
  phoneCountry: PropTypes.string,
  receiveSms: PropTypes.bool,
  language: PropTypes.string.isRequired,
  location: PropTypes.object,
  t: PropTypes.func.isRequired,
  setTextMessageSentStatus: PropTypes.func.isRequired,
  onWebTextedMobileLink: PropTypes.func,
};

MobileEmailActivation.defaultProps = {
  countryCode: undefined,
  mobilePhone: undefined,
  phoneCountry: 'us',
  receiveSms: false,
  location: {},
  onWebTextedMobileLink: () => {},
};

export default withTranslate(MobileEmailActivation);
