import { fetchAllMedications } from '~/services/medicationService';

// ACTIONS
export const SELECT_MEDICATIONS = 'therapy/SELECT_MEDICATIONS';
export const FILTER_MEDICATIONS = 'therapy/FILTER_MEDICATIONS';
export const SELECT_NO_MEDICATIONS = 'therapy/SELECT_NO_MEDICATIONS';
export const MEDICATIONS_REQUESTED = 'therapy/MEDICATIONS_REQUESTED';
export const MEDICATIONS_RECEIVED = 'therapy/MEDICATIONS_RECEIVED';

const initialState = {
  allMedications: [],
  medicationsFilter: '',
  noMedications: false,
  selectedMedications: [],
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_MEDICATIONS:
      return {
        ...state,
        noMedications: false,
        selectedMedications: [].concat(action.value),
      };
    case SELECT_NO_MEDICATIONS:
      return {
        ...state,
        noMedications: action.value,
        selectedMedications: [],
      };
    case FILTER_MEDICATIONS:
      return {
        ...state,
        medicationsFilter: action.value,
      };
    case MEDICATIONS_REQUESTED:
      return {
        ...state,
        medsFetchStatus: 'loading',
        allMedications: [],
      };
    case MEDICATIONS_RECEIVED:
      return {
        ...state,
        medsFetchStatus: action.status,
        allMedications: [].concat(action.allMedications),
      };
    default:
      return state;
  }
};

// ACTION CREATORS
export const onSelectMedications = selection => (
  { type: SELECT_MEDICATIONS, value: selection }
);

export const onFilterMedications = filter => (
  { type: FILTER_MEDICATIONS, value: filter }
);

export const onSelectNoMedications = selection => (
  { type: SELECT_NO_MEDICATIONS, value: selection }
);

// thunk action creator
export const fetchMedications = () =>
  (dispatch) => {
    dispatch({ type: MEDICATIONS_REQUESTED });

    fetchAllMedications()
      .then((res) => {
        dispatch({
          type: MEDICATIONS_RECEIVED,
          medsFetchStatus: 'success',
          allMedications: res.data.medications,
        });
      })
      .catch(() => {
        dispatch({
          type: MEDICATIONS_RECEIVED,
          medsFetchStatus: 'failure',
          allMedications: [],
        });
      });
  };
