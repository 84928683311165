import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import CountryCodeSelect from '../CountryCodeSelect/CountryCodeSelect';
import TextField from '../TextField/TextField';
import Checkbox from '../Checkbox/Checkbox';
import withTranslate from '../../shared/withTranslate/withTranslate';
import { formattedPhoneNumber, strippedNumber, validPhone } from
  '../../utils/phoneNumberUtil';

import './style.sass';

const preventRegEx = (event, regex) => {
  if (!regex.test(event.key)) { event.preventDefault(); }
};
const preventNonNumeric = (event) => { preventRegEx(event, /[0-9\u0660-\u0669]+/g); }; // [\u0660-\u0669]

const countNonNumeric = (s) => {
  const charArray = s.split('');
  const filteredCharArray = charArray.filter(ch => /[0-9\u0660-\u0669]+/.test(ch));
  return charArray.length - filteredCharArray.length;
};

export class PhoneNumberField extends React.Component {
  constructor() {
    super();
    this.onChangeConsent = this.onChangeConsent.bind(this);
    this.onChangeCountry = this.onChangeCountry.bind(this);
    this.onChangePhone = this.onChangePhone.bind(this);
    this.touch = this.touch.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.getCursor = this.getCursor.bind(this);
    this.state = { touched: false, focused: false };
  }

  onChangePhone(value) {
    this.props.onChangePhone(strippedNumber(value));
  }

  onChangeCountry(country) {
    this.props.onChangeCountryCode(`+${country.dialCode}`);
    this.props.onChangePhoneCountry(country.iso2);
  }

  onChangeConsent(e) {
    this.props.onChangeConsent(e.target.checked);
  }

  getCursor(nonformattedValue, selectionStart) {
    const formattedValue = formattedPhoneNumber(
      strippedNumber(nonformattedValue),
      this.props.countryCode,
      this.props.phoneCountry,
    );
    const deleting = this.props.phoneNumber.length > strippedNumber(nonformattedValue).length;
    let cursorAdjust = 0;
    const beforeCountOld = countNonNumeric(nonformattedValue.slice(0, selectionStart + !deleting));
    const beforeCountNew = countNonNumeric(formattedValue.slice(0, selectionStart + !deleting));
    cursorAdjust = beforeCountNew - beforeCountOld;
    return (selectionStart + cursorAdjust);
  }

  touch() {
    if (this.props.phoneNumber !== '') {
      this.setState({ touched: true });
    } else this.setState({ touched: false });
  }

  focus() {
    this.setState({ focused: true });
  }

  blur() {
    this.setState({ focused: false });
    this.touch();
  }

  showError() {
    const show = this.state.touched &&
      !validPhone(
        this.props.countryCode,
        this.props.phoneNumber,
        this.props.phoneCountry,
      );
    return show;
  }

  classes() {
    return classnames({
      PhoneNumberField: true,
      invalid: this.showError(),
      focused: this.state.focused,
    });
  }

  fieldClasses() {
    return classnames({
      'PhoneNumberField-field': true,
      invalid: this.showError(),
      focused: this.state.focused,
    });
  }

  checkboxContent() {
    const label = this.props.isMobileEmailActivation
      ? this.props.t('receiveMobileSms')
      : this.props.t('receiveSms');
    return (
      <div className="PhoneNumberField-consent-text">
        { label }
      </div>
    );
  }

  render() {
    return (
      <div className={this.classes()}>
        <div className="PhoneNumberField-label">
          { this.props.isOptional ? this.props.t('phoneNumberOptional') :
          this.props.t('phoneMobile') }
        </div>
        <div className={this.fieldClasses()}>
          <CountryCodeSelect
            onSelectCountry={this.onChangeCountry}
            selectedCountry={this.props.phoneCountry}
            preferredCountries={['us', 'de']}
            showError={this.showError()}
          />
          <TextField
            onChange={this.onChangePhone}
            onKeyPress={preventNonNumeric}
            onFocus={this.focus}
            value={
              formattedPhoneNumber(
                this.props.phoneNumber,
                this.props.countryCode,
                this.props.phoneCountry,
              )
            }
            getCursor={this.getCursor}
            onBlur={this.blur}
          >
            <div className="PhoneNumberField-country-code">
              { this.props.countryCode }
            </div>
          </TextField>
        </div>
        <div className="PhoneNumberField-consent">
          <Checkbox
            value="receive_text"
            checked={this.props.receiveSms}
            labelContent={this.checkboxContent()}
            onChange={this.onChangeConsent}
          />
        </div>
      </div>
    );
  }
}

PhoneNumberField.propTypes = {
  countryCode: PropTypes.string,
  onChangeConsent: PropTypes.func.isRequired,
  onChangeCountryCode: PropTypes.func.isRequired,
  onChangePhone: PropTypes.func.isRequired,
  onChangePhoneCountry: PropTypes.func.isRequired,
  phoneCountry: PropTypes.string,
  phoneNumber: PropTypes.string,
  receiveSms: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isMobileEmailActivation: PropTypes.bool,
  isOptional: PropTypes.bool,
};

PhoneNumberField.defaultProps = {
  countryCode: '+1',
  phoneCountry: 'us',
  phoneNumber: '',
  receiveSms: false,
  isMobileEmailActivation: false,
  isOptional: false,
};

export default withTranslate(PhoneNumberField);
