/* eslint-disable camelcase */
/* eslint-disable no-console */
import moment from 'moment';
import { logTrackingEvent, identify, logTrackingEventWithCallback } from '~/services/loggingService';
import { wizardSteps, wizardEvents } from '~/utils/enrollmentWizardConfig';
import { FILTER_DIABETES_DEVICES } from './modules/deviceSelection';
import {
  GO_BACK,
  PROCEED_TO_UPLOADER,
  PROCEED_TO_WAYS_TO_SYNC,
  PROCEED_TO_SHIPPING,
  PROCEED_TO_DEVICE_SELECTION,
  AC_WEB_STARTED_ACTIVATION,
  AC_WEB_CLICKED_TAB,
  AC_WEB_TEXTED_MOBILE_LINK,
  AC_MOBILE_STARTED_ACTIVATION,
  AC_MOBILE_CLICKED_ACTIVATE_ON_APP,
  AC_QR_CODE_STARTED_ACTIVATION,
  AC_QR_CODE_CLICK_GET_STARTED,
  AC_QR_CODE_SUBMIT_DOB,
} from './modules/enrollmentWizard';
import { STARTED_ENROLLMENT, COMPLETED_ENROLLMENT } from './modules/accountInformation';

import { LOG_EVENT } from './modules/logger';

const stepEventNameMap = {
  [wizardSteps.shippingInformation.name]: 'en_join_navigated_shipping_screen',
  [wizardSteps.deviceSelection.name]: 'en_join_navigated_devices_screen',
  [wizardSteps.waysToSync.name]: 'en_join_navigated_sync_screen',
  [wizardSteps.therapy.name]: 'en_join_navigated_therapy_screen',
  [wizardEvents.startedEnrollment.name]: 'en_join_started_enrollment',
  [wizardEvents.completedEnrollment.name]: 'en_join_completed_enrollment',
  [wizardEvents.webStartedActivation.name]: 'ac_web_started_activation',
  [wizardEvents.webClickedTab.name]: 'ac_web_clicked_tab',
  [wizardEvents.webTextedMobileLink.name]: 'ac_web_texted_mobile_link',
  [wizardEvents.mobileStartedActivation.name]: 'ac_mobile_started_activation',
  [wizardEvents.mobileClickedActivateOnApp.name]: 'ac_mobile_clicked_activate_on_app',
  [wizardEvents.qrCodeStartedActivation.name]: 'ac_qr_code_started_activation',
  [wizardEvents.qrCodeClickGetStarted.name]: 'ac_qr_code_click_get_started',
  [wizardEvents.qrCodeSubmitDob.name]: 'ac_qr_code_submit_dob',
};

const stepEventFieldsMap = {
  [wizardSteps.shippingInformation.name]: [
    {
      eventFieldName: 'selection',
      getValue: () => 'next',
    },
  ],
  [wizardSteps.deviceSelection.name]: [
    {
      eventFieldName: 'page_load_timestamp',
      getValue: (action, state) => state.enrollmentWizard.pageLoadTimestamp,
    },
    {
      eventFieldName: 'smartphone_selection',
      getValue: (action, state) => state.deviceSelection.platform,
    },
    {
      eventFieldName: 'device_selection',
      getValue: (action, state) => state.deviceSelection.selectedDiabetesDevices,
    },
  ],
  [wizardSteps.waysToSync.name]: [
    {
      eventFieldName: 'selection',
      getValue: () => 'next',
    },
  ],
  [wizardSteps.therapy.name]: [
    {
      eventFieldName: 'page_load_timestamp',
      getValue: (action, state) => state.enrollmentWizard.pageLoadTimestamp,
    },
  ],
  [wizardEvents.startedEnrollment.name]: [
    {
      eventFieldName: 'enrollment_type',
      getValue: action => action.enrollmentType,
    },
  ],
  currentTimeStamp: [
    {
      eventFieldName: 'activation_start_timestamp',
      getValue: () => localStorage.getItem('activation_start_timestamp'),
    },
  ],
};

const actionToEventMap = {
  [FILTER_DIABETES_DEVICES]: {
    name: 'en_join_searched_device',
    shouldReport: (action, state) => state.deviceSelection.diabetesDeviceFilter === '',
    fields: [],
  },
  [GO_BACK]: {
    name: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    shouldReport: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    fields: [
      {
        eventFieldName: 'selection',
        getValue: () => 'back',
      },
    ],
  },
  [LOG_EVENT]: {
    name: 'en_generic',
  },
  [PROCEED_TO_DEVICE_SELECTION]: {
    name: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    shouldReport: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    fields: (action, state) => stepEventFieldsMap[state.enrollmentWizard.currentStep.name],
  },
  [PROCEED_TO_WAYS_TO_SYNC]: {
    name: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    fields: (action, state) => stepEventFieldsMap[state.enrollmentWizard.currentStep.name],
  },
  [PROCEED_TO_SHIPPING]: {
    name: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    fields: (action, state) => stepEventFieldsMap[state.enrollmentWizard.currentStep.name],
  },
  [PROCEED_TO_UPLOADER]: {
    name: (action, state) => stepEventNameMap[state.enrollmentWizard.currentStep.name],
    fields: (action, state) => stepEventFieldsMap[state.enrollmentWizard.currentStep.name],
  },
  [STARTED_ENROLLMENT]: {
    name: () => stepEventNameMap[wizardEvents.startedEnrollment.name],
    fields: () => stepEventFieldsMap[wizardEvents.startedEnrollment.name],
  },
  [COMPLETED_ENROLLMENT]: {
    name: () => stepEventNameMap[wizardEvents.completedEnrollment.name],
  },

  [AC_WEB_STARTED_ACTIVATION]: {
    name: () => stepEventNameMap[wizardEvents.webStartedActivation.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_WEB_CLICKED_TAB]: {
    name: () => stepEventNameMap[wizardEvents.webClickedTab.name],
    fields: () => [
      ...stepEventFieldsMap.currentTimeStamp,
      {
        eventFieldName: 'tab',
        getValue: action => action.tab,
      },
    ],
  },
  [AC_WEB_TEXTED_MOBILE_LINK]: {
    name: () => stepEventNameMap[wizardEvents.webTextedMobileLink.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_MOBILE_STARTED_ACTIVATION]: {
    name: () => stepEventNameMap[wizardEvents.mobileStartedActivation.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_MOBILE_CLICKED_ACTIVATE_ON_APP]: {
    name: () => stepEventNameMap[wizardEvents.mobileClickedActivateOnApp.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_QR_CODE_STARTED_ACTIVATION]: {
    name: () => stepEventNameMap[wizardEvents.qrCodeStartedActivation.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_QR_CODE_CLICK_GET_STARTED]: {
    name: () => stepEventNameMap[wizardEvents.qrCodeClickGetStarted.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
  [AC_QR_CODE_SUBMIT_DOB]: {
    name: () => stepEventNameMap[wizardEvents.qrCodeSubmitDob.name],
    fields: () => stepEventFieldsMap.currentTimeStamp,
  },
};

function getBaseData(state) {
  const sessionUser = state.session.currentUser;
  if (!sessionUser) {
    return {};
  }
  identify(sessionUser.glookoCode);

  return {
    application_name: 'server',
    application_version: WEBAPP_VERSION, // eslint-disable-line no-undef
    device_id: null,
    user_id: sessionUser.id,
    glooko_code: sessionUser.glookoCode,
    primary_authorized_site_id: sessionUser.primaryAuthorizedSiteId,
    provider_group: sessionUser.providerGroupSiteId,
    provider_group_sites: sessionUser.authorizedSiteIds,
    timestamp: moment().utc().format(),
    domain: window.location.href,
  };
}

function setAnonymousIp(type) {
  if (type === AC_WEB_STARTED_ACTIVATION ||
          type === AC_WEB_CLICKED_TAB ||
          type === AC_WEB_TEXTED_MOBILE_LINK ||
          type === AC_MOBILE_STARTED_ACTIVATION ||
          AC_MOBILE_CLICKED_ACTIVATE_ON_APP ||
          type === AC_QR_CODE_STARTED_ACTIVATION) {
    return { context: { ip: '0.0.0.0' } };
  }
  return {};
}

function setActivationStartTimestamp(type) {
  if (
    type === AC_WEB_STARTED_ACTIVATION ||
    type === AC_MOBILE_STARTED_ACTIVATION ||
    type === AC_QR_CODE_STARTED_ACTIVATION
  ) {
    localStorage.setItem(
      'activation_start_timestamp',
      moment()
        .utc()
        .format(),
    );
  }
}

function getData(event, action, state) {
  const fields = typeof event.fields === 'function' ? event.fields(action, state) : event.fields;
  let extraData = {};
  if (fields) {
    extraData = fields.map(field => (
      { [field.eventFieldName]: field.getValue(action, state) }
    ));
  }
  return Object.assign({}, getBaseData(state), ...extraData);
}

export function logger({ getState }) {
  return next => (action) => {
    const state = getState();
    setActivationStartTimestamp(action.type);
    const event = actionToEventMap[action.type];
    if (event && (!event.shouldReport || event.shouldReport(action, state))) {
      if (action.type === LOG_EVENT) {
        const data = Object.assign({}, getBaseData(state), action.data);
        if (action.callback) {
          logTrackingEventWithCallback(action.name, data, action.callback);
        } else {
          logTrackingEvent(action.name, data);
        }
      } else {
        logTrackingEvent(
          typeof event.name === 'function' ? event.name(action, state) : event.name,
          getData(event, action, state),
          setAnonymousIp(action.type),
        );
      }
    }
    return next(action);
  };
}
