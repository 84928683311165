import _ from 'lodash';
import { allCountries } from 'country-telephone-data';
import { PhoneNumberUtil, AsYouTypeFormatter, PhoneNumberFormat } from 'google-libphonenumber';

export const strippedNumber =
  number => _.replace(number, /(\(|\)|-| |\+)/g, '');

export const strippedCountryCode = c => _.replace(c, /(\(|\)|-| )/g, '');

export const findCountry = (code, iso2) => {
  if (iso2) { return allCountries.find(c => c.iso2 === iso2).iso2; }
  return allCountries.find(c => c.dialCode === strippedNumber(code)).iso2;
};

export const getIso2 = (code, country) =>
  (country && country.iso2) ||
  findCountry(code, country);

export const validPhone = (countryCode, phoneNumber, country) => {
  if (phoneNumber === '' || phoneNumber === undefined) { return true; }

  const iso2 = getIso2(countryCode, country);
  const numberWithCountryCode = `${countryCode}${phoneNumber}`;
  const phoneUtil = PhoneNumberUtil.getInstance();

  try {
    const number = phoneUtil.parse(numberWithCountryCode);
    // Format number in the E164 format
    const numberInInternationalE164Standard = phoneUtil.format(number, PhoneNumberFormat.E164);
    if (!(numberInInternationalE164Standard === numberWithCountryCode)) return false;
    return phoneUtil.isValidNumberForRegion(number, iso2.toUpperCase());
  } catch (e) { return false; }
};

export const formattedPhoneNumber = (phoneNumber, countryCode, country) => {
  const iso2 = getIso2(countryCode, country);
  const formatter = new AsYouTypeFormatter(iso2);
  let formatted = '';
  for (let i = 0; i < phoneNumber.length; i += 1) {
    formatted = formatter.inputDigit(phoneNumber.charAt(i));
  }
  return formatted;
};
