import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.sass';

class Checkbox extends Component {
  constructor() {
    super();
    this.onBlur = this.onBlur.bind(this);
    this.onCheckChange = this.onCheckChange.bind(this);
    this.state = {
      touched: false,
    };
  }

  onBlur() {
    this.touch();
  }

  onCheckChange(event) {
    this.props.onChange(event);
  }

  isValid() {
    return !this.props.required || this.props.checked;
  }

  touch() {
    this.setState({ touched: true });
  }

  showError() {
    return this.state.touched && !this.isValid();
  }

  render() {
    const classes = classnames({
      Checkbox: true, error: this.showError(),
    });
    return (
      <div className={classes}>
        <div className="Checkbox-box">
          <input
            className={classes}
            type="checkbox"
            id={this.props.value}
            name={this.props.value}
            value={this.props.value}
            checked={this.props.checked}
            onBlur={this.onBlur}
            onChange={this.props.onChange}
          />
        </div>
        <label
          htmlFor={this.props.value}
          className="Consents-check-descr"
        >
          {this.props.labelContent}
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  checked: PropTypes.bool,
  labelContent: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
};

Checkbox.defaultProps = {
  checked: false,
  required: false,
};

export default Checkbox;
