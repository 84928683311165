import React from 'react';
import PropTypes from 'prop-types';
import { includes } from 'lodash';

import Hardware from './Hardware/Hardware';
import withTranslate from '../../../shared/withTranslate/withTranslate';

import './style.sass';

export class HardwareSelection extends React.Component {
  componentDidMount() {
    if (!this.props.hardwareVisited) {
      this.props.availableHardware.forEach(hardware =>
        this.props.onSelectVariant(hardware.variantId));
    }
    this.props.onVisitHardwareSelection();
  }

  render() {
    const {
      t,
      availableHardware,
      onSelectVariant,
      onDeselectVariant,
      selectedVariants,
    } = this.props;
    if (availableHardware.length > 0) {
      return (
        <div className="HardwareSelection">
          <div className="HardwareSelection-header">
            {t('hardwareSelectionHeader')}
          </div>
          {availableHardware.map((hardware) => {
            const selected = includes(selectedVariants, hardware.variantId);
            return (
              <Hardware
                hardware={hardware}
                isSelected={selected}
                selectVariant={onSelectVariant}
                deselectVariant={onDeselectVariant}
                t={t}
                key={hardware.variantId}
              />
            );
          })}
        </div>
      );
    }
    return null;
  }
}

HardwareSelection.propTypes = {
  availableHardware: PropTypes.arrayOf(PropTypes.object),
  hardwareVisited: PropTypes.bool.isRequired,
  onDeselectVariant: PropTypes.func,
  onSelectVariant: PropTypes.func,
  onVisitHardwareSelection: PropTypes.func.isRequired,
  selectedVariants: PropTypes.arrayOf(PropTypes.number),
  t: PropTypes.func.isRequired,
};

HardwareSelection.defaultProps = {
  availableHardware: [],
  onDeselectVariant: () => {},
  onSelectVariant: () => {},
  selectedVariants: [],
};

export default withTranslate(HardwareSelection);
