import { notEmptyString } from '~/utils/validations';

export const fieldValidators = {
  country: notEmptyString,
  line1: notEmptyString,
  city: notEmptyString,
  zipcode: notEmptyString,
  state: notEmptyString,
};

export const shippingInfoValid = form =>
  Object.keys(fieldValidators).every(field => fieldValidators[field](form[field]));
