/* global API_GLOOKO_URL */
import axios from './apiClient/axiosMocked';

let baseURL = API_GLOOKO_URL;

const apiEndPointURL = endpoint => `${baseURL}/${endpoint}`;

function findApiBaseUrlByCountry(arr, countrySelected) {
  return arr.filter(elem => elem.countryCode === countrySelected)[0].urls.apiBase;
}

export default function ExecuteApiCall(method, endpoint, payload = {}) {
  if (
    window.countriesWithApiUrls &&
    window.countriesWithApiUrls.countriesList.length >= 1 &&
    window.countriesWithApiUrls.countrySelected
  ) {
    baseURL = findApiBaseUrlByCountry(
      window.countriesWithApiUrls.countriesList,
      window.countriesWithApiUrls.countrySelected,
    );
  }
  const options = {
    method,
    url: apiEndPointURL(endpoint),
    params: {},
    data: {},
    withCredentials: true,
  };

  switch (method) {
    case 'patch':
    case 'post':
    case 'put':
      Object.assign(options, { data: payload });
      break;
    case 'delete':
    case 'get':
      Object.assign(options.params, payload);
      break;
    default:
      break;
  }

  return axios(options);
}
