import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchUserEligibility, onUpdateAttribute, onSaveShipping } from
  '~/store/modules/shippingInfo';
import { fetchCountries } from
  '~/store/modules/countries';
import { onBack } from '~/store/modules/enrollmentWizard';
import ShippingInfo from './ShippingInfo';
import { shippingInfoValid } from './validations/shippingInfo';

const mapStateToProps = state => ({
  country: (state.shippingInfo.country) ||
    state.accountInformation.countryOfResidence || '',
  line1: state.shippingInfo.line1,
  line2: state.shippingInfo.line2,
  zipcode: state.shippingInfo.zipcode,
  city: state.shippingInfo.city,
  state: state.shippingInfo.state,
  allCountries: state.countries.allCountries,
  language: state.language.language,
  isValid: shippingInfoValid(state.shippingInfo),
  isFetchingAddress: !state.shippingInfo.eligibityStatus || state.shippingInfo.eligibityStatus === 'pending',
  isSaving: state.shippingInfo.isSaving,
  savingError: !!state.shippingInfo.savingError,
});

const mapDispatchToProps = dispatch => ({
  onBack: () => dispatch(onBack()),
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  fetchCountries: language => dispatch(fetchCountries(language)),
  fetchEligibilityAddress: country => dispatch(fetchUserEligibility({ country })),
  saveShippingInfo: () => dispatch(onSaveShipping()),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(ShippingInfo));
