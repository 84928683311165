import ExecuteApiCall from './apiClient';

export function sendSMSLink(countryCode, mobilePhone, locale, consent) {
  const payload = {
    mobilePhone: `${countryCode}${mobilePhone}`,
    language: locale,
    receiveSms: consent,
  };
  return ExecuteApiCall('put', 'api/v3/enrollments/app_link', payload);
}


export function sendDeepLink(countryCode, mobilePhone, locale, consent, tempToken) {
  const payload = {
    mobilePhone: `${countryCode}${mobilePhone}`,
    language: locale,
    receiveSms: consent,
    tempToken,
  };
  return ExecuteApiCall('put', 'api/v3/enrollments/branch_deep_link', payload);
}
