import { connect } from 'react-redux';
import { logEvent } from '~/store/modules/logger';
import WebAppLink from './WebAppLink';

const getCountrySpecificUrl = () => {
  let webAppUrl = '';
  if (window.countriesWithApiUrls) {
    const { countrySelected, countriesList } = window.countriesWithApiUrls;
    const countryData = countriesList.filter(country => country.countryCode === countrySelected);
    [webAppUrl] = countryData.map(entry => entry.urls.webApp);
  }
  return webAppUrl || MY_GLOOKO_URL; // eslint-disable-line
};

const mapStateToProps = state => ({
  getWebAppUrl: getCountrySpecificUrl,
  language: state.language.language,
});

const mapDispatchToProps = dispatch => ({
  logEvent: (name, data, callback) => dispatch(logEvent(name, data, callback)),
});

export default
connect(mapStateToProps, mapDispatchToProps)(WebAppLink);
