import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LinkedText } from '@glooko/common-ui';
import images from '~/utils/ImageStore';
import withTranslate from '../shared/withTranslate/withTranslate';
import EmailActivationHeader from './EmailActivationHeader';
import { languageOptions } from
  '../utils/defaultLanguageSelection';
import { disableMobileActivation, getLanguage } from '../utils/navigation';
import Select from '../shared/Select/Select';
import './style.sass';

export class Header extends React.Component {
  componentDidMount = () => {
    const languageCode = getLanguage();
    if (languageCode && this.isSupportedLanguage(languageCode)) {
      this.props.onChangeLanguage(languageCode);
    }
  }

  isSupportedLanguage = languageCode =>
    !!languageOptions().find(language => (language.value === languageCode));

  render() {
    const isMobileActivation = disableMobileActivation();
    const isQrCodeActivationPage = !!(
      this.props.currentStep && this.props.currentStep.name === 'qrCodeActivation'
    );
    if (this.props.isMinorPage) {
      return (
        <div className="MinorEmailActivationHeader">
          <div className="MinorEmailActivationHeader-logo">
            <img
              className="Header-logo-img"
              src={images.glookoWhiteLogo}
              alt="Glooko logo"
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {this.props.currentStep &&
          (this.props.currentStep.name !== 'EmailActivation' ||
            isMobileActivation) &&
            <div
              className={classNames({
                Header: true,
                qrCodeActivation: isQrCodeActivationPage,
              })}
            >
              <div
                className={classNames({
                'Header-logo': true,
                'qrCodeActivation-logo': isQrCodeActivationPage,
              })}
              >
                <img
                  className="Header-logo-img"
                  src={images.glookoLogo}
                  alt="Glooko logo"
                />
                {this.props.partnerImage &&
                <div className="Header-logo-partner">
                  <div className="Header-logo-partner-plus">+</div>
                  <img
                    className="Header-logo-partner-img"
                    src={this.props.partnerImage}
                    alt="Partner logo"
                  />
                </div>
              }
              </div>
              {(this.props.currentStep.name === 'AccountInformation' ||
              this.props.currentStep.name === 'CodeActivation' || this.props.currentStep.name === 'EmailActivation') &&
              <div className="Header-language-selector">
                <Select
                  options={languageOptions()}
                  onChange={this.props.onChangeLanguage}
                  value={this.props.language}
                />
              </div>
            }
              {(this.props.currentStep.name === 'AccountInformation' ||
              this.props.currentStep.name === 'CodeActivation' || this.props.currentStep.name === 'EmailActivation') &&
              <div className="Header-log-in">
                {this.props.currentStep.name !== 'AccountInformation' &&
                  <p>
                    <LinkedText
                      template={this.props.t('alreadyHaveAccount')}
                      linkContent={[{ title: this.props.t('logIn'), url: this.props.loginUrl }]}
                    />
                  </p>
                }
              </div>
            }
            </div>
        }
        {this.props.currentStep && this.props.currentStep.name === 'EmailActivation' && !isMobileActivation &&
          <EmailActivationHeader
            language={this.props.language}
            onChangeLanguage={this.props.onChangeLanguage}
          />
        }
      </div>
    );
  }
}

Header.propTypes = {
  currentStep: PropTypes.object,
  isMinorPage: PropTypes.bool,
  language: PropTypes.string.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
  loginUrl: PropTypes.string.isRequired,
  partnerImage: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Header.defaultProps = {
  currentStep: undefined,
  partnerImage: undefined,
  isMinorPage: false,
};

export default withTranslate(Header);
