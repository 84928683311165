import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onChangeLanguage } from '../store/modules/language';
import { getPartner } from '../utils/navigation';
import { getPartnerInfo } from '../utils/partners';
import { getLogInURL } from '../utils/links';

import Header from './Header';

const mapStateToProps = (state) => {
  const partner = getPartner();
  const partnerInfo = partner ? getPartnerInfo(partner) : undefined;
  const { language } = state.language;
  const loginUrl = (partnerInfo && partnerInfo.loginUrl) ?
    partnerInfo.loginUrl :
    getLogInURL(language);

  return ({
    language,
    currentStep: state.enrollmentWizard.currentStep,
    isMinorPage: state.enrollmentWizard.isMinorPage,
    partnerImage: partnerInfo ? partnerInfo.image : undefined,
    loginUrl,
  });
};

const mapDispatchToProps = dispatch => ({
  onChangeLanguage: language => dispatch(onChangeLanguage(language)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
