import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import DeviceSummaryItem from './DeviceSummaryItem/DeviceSummaryItem';
import './style.sass';

const DiabetesDeviceSummary = ({ devices, selected, t }) => {
  const selectedDevices = selected.map(deviceId => (
    <div
      className="DiabetesDeviceSummary-device"
      key={deviceId}
    >
      <DeviceSummaryItem
        device={devices.find(device => device.id === deviceId)}
      />
    </div>
  ));

  return (
    <div className="DiabetesDeviceSummary">
      {selected.length > 0 &&
        <div>
          <div className="DiabetesDeviceSummary-divider" />
          <div className="DiabetesDeviceSummary-header">
            { t('diabetesDeviceSelectHeader') }
          </div>
          <div className="DiabetesDeviceSummary-selections">
            {selectedDevices}
          </div>
          <div className="DiabetesDeviceSummary-divider" />
        </div>
      }
    </div>
  );
};

DiabetesDeviceSummary.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
};

DiabetesDeviceSummary.defaultProps = {
};

export default withTranslate(DiabetesDeviceSummary);
