import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import CheckboxList from '~/shared/CheckboxList/CheckboxList.jsx';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import Checkbox from '~/shared/Checkbox/Checkbox.jsx';
import TextField from '~/shared/TextField/TextField.jsx';
import images from '~/utils/ImageStore';
import './style.sass';

class MedsSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
    this.onNoMedsCheckedChange = this.onNoMedsCheckedChange.bind(this);
    this.getTypeDisplayName = this.getTypeDisplayName.bind(this);
    this.compareCategories = this.compareCategories.bind(this);
    this.medTypeMap = {
      injectable: { titleKey: props.t('injectable'), sortKey: 1 },
      insulin: { titleKey: props.t('insulin'), sortKey: 0 },
      oral: { titleKey: props.t('pills'), sortKey: 2 },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.medications.length === 0) {
      this.props.fetchMedications();
    }
  }

  onNoMedsCheckedChange(e) {
    this.props.onNoMedicationsChange(e.target.checked);
  }

  getTypeDisplayName(type) {
    if (!this.medTypeMap[type]) {
      return type.charAt(0).toUpperCase() + type.slice(1);
    }
    return this.medTypeMap[type].titleKey;
  }

  compareCategories(a, b) {
    const a1 = this.medTypeMap[a] ? this.medTypeMap[a].sortKey : 100;
    const b1 = this.medTypeMap[b] ? this.medTypeMap[b].sortKey : 100;
    if (a1 < b1) {
      return -1;
    } else if (a1 > b1) {
      return 1;
    }

    return 0;
  }

  medicationMatchesFilter(medication, filter) {
    return (
      medication.brand.toLowerCase().indexOf(filter.toLowerCase()) > -1 ||
      this.getTypeDisplayName(medication.type).toLowerCase().indexOf(filter.toLowerCase()) > -1
    );
  }

  showError() {
    return !this.props.isValid(this.props) && this.state.touched;
  }

  touch() {
    this.setState({ touched: true });
  }

  classes() {
    return classnames({
      MedsSelect: true,
      error: this.showError(),
    });
  }

  render() {
    const {
      noMedications, medications, filter, isLoading,
      onFilterChange, onSelectionChange,
      selectedMedications, t,
    } = this.props;

    const noMedicationContent = <span>{t('noMedications')}</span>;
    const filteredMedications = medications.filter(medication =>
      this.medicationMatchesFilter(medication, filter));

    return (
      <div className={this.classes()} >
        <div className="MedsSelect-header">
          { t('medsSelectHeader') }
        </div>
        <div className="MedsSelect-search">
          <TextField
            onChange={onFilterChange}
            value={filter}
            placeholder={t('searchPlaceholder')}
          >
            <img alt="search" src={images.search} />
          </TextField>
        </div>
        <div className="MedsSelect-list">
          {isLoading &&
            '...'
          }
          <CheckboxList
            data={filteredMedications}
            idField="guid"
            valueField="guid"
            labelField="brand"
            categoryField="type"
            selected={selectedMedications}
            onSelectionChange={onSelectionChange}
            getCategoryDisplay={this.getTypeDisplayName}
            categoryComparator={this.compareCategories}
          />
        </div>
        <div className="MedsSelect-noMedications">
          <Checkbox
            value="noMedications"
            checked={noMedications}
            labelContent={noMedicationContent}
            onChange={this.onNoMedsCheckedChange}
          />
        </div>
      </div>
    );
  }
}

MedsSelect.propTypes = {
  isValid: PropTypes.func.isRequired,
  noMedications: PropTypes.bool,
  medications: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedMedications: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string,
  fetchMedications: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  onNoMedicationsChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

MedsSelect.defaultProps = {
  noMedications: false,
  filter: '',
  isLoading: false,
  onFilterChange: () => {},
};

export default withTranslate(MedsSelect);
