import FilesContext from './FilesContext';

const localeRegex = /.*\/i18n\/(.*)\.([A-Za-z-]+)\.json$/;
const translations = {};

FilesContext.keys()
  .forEach((file) => {
    const match = localeRegex.exec(file);
    const namespace = match[1];
    const lang = match[2];

    translations[lang] = translations[lang] || {};
    translations[lang][namespace] = FilesContext(file);
  });

export default translations;
