
export const therapyValid = ({ noMedications, selectedMedications }) =>
  noMedications || (selectedMedications && selectedMedications.length > 0);

export const fieldValidators = {
  selectedMedications: therapyValid,
};

export const medsSelectionValid = form =>
  Object.keys(fieldValidators).every(field => fieldValidators[field](form));
