import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import MedsSummaryItem from './MedsSummaryItem/MedsSummaryItem';
import './style.sass';

const MedsSummary = ({ medications, selected, t }) => {
  const selectedDevices = medications.length > 0 ? selected.map(medId => (
    <div
      className="MedsSummary-med"
      key={medId}
    >
      <MedsSummaryItem
        medication={medications.find(med => med.guid === medId)}
      />
    </div>
  )) : null;

  return (
    <div className="MedsSummary">
      {medications.length > 0 && selected.length > 0 &&
        <div>
          <div className="MedsSummary-divider" />
          <div className="MedsSummary-header">
            { t('medicationSelectHeader') }
          </div>
          <div className="MedsSummary-selections">
            {selectedDevices}
          </div>
          <div className="MedsSummary-divider bottom" />
        </div>
      }
    </div>
  );
};

MedsSummary.propTypes = {
  medications: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  t: PropTypes.func.isRequired,
};

MedsSummary.defaultProps = {
};

export default withTranslate(MedsSummary);
