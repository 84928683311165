import React from 'react';
import queryString from 'query-string';
import pseudoLocalization from 'pseudo-localization';
import { isLocalhost } from './registerServiceWorker';
import App from './App.jsx';

const pseudoStrategies = ['accented', 'bidi'];
const queryParams = queryString.parse(window.location.search);
if (isLocalhost && pseudoStrategies.includes(queryParams.pseudo)) {
  pseudoLocalization.start({ strategy: queryParams.pseudo });
}

const AppContainer = () => (
  <App />
);

export default AppContainer;
