import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { detectedOS } from '~/utils/deviceDetection';

import { COMPLETED_ENROLLMENT } from '~/store/modules/accountInformation';
import { hasSelectedMobileCompatibleDevice } from '../../utils/deviceCompatibility';

import DownloadUploader from './DownloadUploader';

const fullSelectedDevices = (selected, references) => (
  selected.map(deviceId =>
    references.find(reference => reference.id === deviceId))
);

const checkGlookoResearchSite = state => state.session && state.session.currentUser
  && state.session.currentUser.glookoResearchSite;

const mapStateToProps = state => ({
  hasMobileCompatibleDevice: hasSelectedMobileCompatibleDevice(
    state.deviceSelection.platform,
    fullSelectedDevices(
      state.deviceSelection.selectedDiabetesDevices,
      state.deviceSelection.allDiabetesDevices,
    ),
  ),
  language: state.language.language,
  platformOS: detectedOS().name,
  isGlookoResearchSite: checkGlookoResearchSite(state),
  isSignIn: !!(state.session && state.session.currentUser),
});

const mapDispatchToProps = dispatch => ({
  onCompletedEnrollment: () => dispatch({ type: COMPLETED_ENROLLMENT }),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadUploader));
