import moment from 'moment';
import { wizardSteps } from '../../utils/enrollmentWizardConfig';
import { userDataFromQRCode, userDataFromMinorURL, userDataFromMinorParentalConsentURL, userDataFromWithdrawalConsentURL } from '../../utils/decrypt';

// ACTIONS
export const GO_BACK =
  'enrollmentWizard/GO_BACK';
export const PROCEED_TO_ACCOUNT_INFO =
  'enrollmentWizard/PROCEED_TO_ACCOUNT_INFO';
export const PROCEED_TO_CODE_ACTIVATION =
  'enrollmentWizard/PROCEED_TO_CODE_ACTIVATION';
export const PROCEED_TO_QR_CODE_ACTIVATION =
  'enrollmentWizard/PROCEED_TO_QR_CODE_ACTIVATION';
export const PROCEED_TO_EMAIL_ACTIVATION =
  'enrollmentWizard/PROCEED_TO_EMAIL_ACTIVATION';
export const PROCEED_TO_DEVICE_SELECTION =
  'enrollmentWizard/PROCEED_TO_DEVICE_SELECTION';
export const PROCEED_TO_WAYS_TO_SYNC =
  'enrollmentWizard/PROCEED_TO_WAYS_TO_SYNC';
export const PROCEED_TO_SHIPPING =
  'enrollmentWizard/PROCEED_TO_SHIPPING';
export const PROCEED_TO_UPLOADER =
  'enrollmentWizard/PROCEED_TO_UPLOADER';
export const PROCEED_TO_THERAPY =
  'enrollmentWizard/PROCEED_TO_THERAPY';
export const PROCEED_TO_INSULET_WEB =
  'enrollmentWizard/PROCEED_TO_INSULET_WEB';
export const AC_WEB_STARTED_ACTIVATION = 'logTrackingEvent/AC_WEB_STARTED_ACTIVATION';
export const AC_WEB_CLICKED_TAB = 'logTrackingEvent/AC_WEB_CLICKED_TAB';
export const AC_WEB_TEXTED_MOBILE_LINK = 'logTrackingEvent/AC_WEB_TEXTED_MOBILE_LINK';
export const AC_MOBILE_STARTED_ACTIVATION = 'logTrackingEvent/AC_MOBILE_STARTED_ACTIVATION';
export const AC_MOBILE_CLICKED_ACTIVATE_ON_APP = 'logTrackingEvent/AC_MOBILE_CLICKED_ACTIVATE_ON_APP';
export const AC_QR_CODE_STARTED_ACTIVATION = 'logTrackingEvent/AC_QR_CODE_STARTED_ACTIVATION';
export const AC_QR_CODE_CLICK_GET_STARTED = 'logTrackingEvent/AC_QR_CODE_CLICK_GET_STARTED';
export const AC_QR_CODE_SUBMIT_DOB = 'logTrackingEvent/AC_QR_CODE_SUBMIT_DOB';

export const PROCEED_TO_MINOR_ACTIVATION =
  'enrollmentWizard/PROCEED_TO_MINOR_ACTIVATION';
export const PROCEED_TO_MINOR_PARENTAL_CONSENT_ACTIVATION =
  'enrollmentWizard/PROCEED_TO_MINOR_PARENTAL_CONSENT_ACTIVATION';
export const PROCEED_TO_WITHDRAW_CONSENT =
  'enrollmentWizard/PROCEED_TO_WITHDRAW_CONSENT';

const initialState = {
  history: [],
};

const addToHistory = (history, wizardStep) => {
  const newHistory = [].concat(history);
  newHistory.push(wizardStep);
  return newHistory;
};

// REDUCER
export default (state = initialState, action) => {
  const now = moment().utc().format();
  switch (action.type) {
    case GO_BACK: {
      const newHistory = [].concat(state.history);
      newHistory.pop();
      // only go back if something in history
      if (state.history.length === 0) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        currentStep: state.history[state.history.length - 1],
        history: newHistory,
        pageLoadTimestamp: now,
      };
    }

    case PROCEED_TO_ACCOUNT_INFO:
      return {
        ...state,
        currentStep: wizardSteps.accountInformation,
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_CODE_ACTIVATION:
      return {
        ...state,
        currentStep: wizardSteps.codeActivation,
        pageLoadTimestamp: now,
      };
    case PROCEED_TO_QR_CODE_ACTIVATION:
      return {
        ...state,
        currentStep: wizardSteps.qrCodeActivation,
        userDecryptedDetails: userDataFromQRCode(),
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_EMAIL_ACTIVATION:
      return {
        ...state,
        currentStep: wizardSteps.emailActivation,
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_MINOR_ACTIVATION:
      return {
        ...state,
        currentStep: wizardSteps.minorEmailActivation,
        isMinorPage: true,
        pageLoadTimestamp: now,
        userDecryptedDetails: userDataFromMinorURL(),
      };

    case PROCEED_TO_MINOR_PARENTAL_CONSENT_ACTIVATION:
      return {
        ...state,
        currentStep: wizardSteps.minorParentalConsentActivation,
        isMinorPage: true,
        pageLoadTimestamp: now,
        userDecryptedDetails: userDataFromMinorParentalConsentURL(),
      };

    case PROCEED_TO_WITHDRAW_CONSENT:
      return {
        ...state,
        currentStep: wizardSteps.withdrawParentalConsent,
        isMinorPage: true,
        pageLoadTimeStamp: now,
        userDecryptedDetails: userDataFromWithdrawalConsentURL(),
      };

    case PROCEED_TO_THERAPY:
      return {
        ...state,
        currentStep: wizardSteps.therapy,
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_DEVICE_SELECTION:
      return {
        ...state,
        currentStep: wizardSteps.deviceSelection,
        history: state.currentStep === wizardSteps.therapy
          ? addToHistory(state.history, state.currentStep) : [],
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_WAYS_TO_SYNC:
      return {
        ...state,
        currentStep: wizardSteps.waysToSync,
        history: addToHistory(state.history, state.currentStep),
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_SHIPPING:
      return {
        ...state,
        currentStep: wizardSteps.shippingInformation,
        history: addToHistory(state.history, state.currentStep),
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_UPLOADER:
      return {
        ...state,
        currentStep: wizardSteps.downloadUploader,
        history: [],
        pageLoadTimestamp: now,
      };

    case PROCEED_TO_INSULET_WEB:
      return {
        ...state,
        currentStep: wizardSteps.autoInsuletWebLink,
        history: [],
        pageLoadTimestamp: now,
      };

    default:
      return state;
  }
};

// ACTION CREATORS
export const onBack =
  () => ({ type: GO_BACK });

export const onProceedToAccountInfo =
  () => ({ type: PROCEED_TO_ACCOUNT_INFO });

export const onProceedToCodeActivation =
  () => ({ type: PROCEED_TO_CODE_ACTIVATION });

export const onProceedToQRCodeActivation =
  data => ({ type: PROCEED_TO_QR_CODE_ACTIVATION, data });


export const onProceedToEmailActivation =
  () => ({ type: PROCEED_TO_EMAIL_ACTIVATION });

export const onProceedToTherapy =
  () => ({ type: PROCEED_TO_THERAPY });

export const onProceedToDeviceSelection =
  () => ({ type: PROCEED_TO_DEVICE_SELECTION });

export const onProceedToWaysToSync =
  () => ({ type: PROCEED_TO_WAYS_TO_SYNC });

export const onProceedToShipping =
  () => ({ type: PROCEED_TO_SHIPPING });

export const onProceedToUploader =
  () => ({ type: PROCEED_TO_UPLOADER });

export const onProceedToInsuletWeb =
  () => ({ type: PROCEED_TO_INSULET_WEB });

export const onProceedToMinorActivation =
  () => ({ type: PROCEED_TO_MINOR_ACTIVATION });

export const onProceedToMinorParentalConsentActivation =
  () => ({ type: PROCEED_TO_MINOR_PARENTAL_CONSENT_ACTIVATION });

export const onProceedToWithdrawalConsent =
  () => ({ type: PROCEED_TO_WITHDRAW_CONSENT });
