import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  onSelectAndroidChargingCord,
  onSelectPlatform,
} from '../../store/modules/deviceSelection';
import { deviceSelectionValid } from './validations/deviceSelection';
import { onBack, onProceedToUploader, onProceedToWaysToSync } from '../../store/modules/enrollmentWizard';
import { fetchShopifyProducts } from '../../store/modules/shopify';

import DeviceSelection from './DeviceSelection';

const mapStateToProps = state => ({
  platform: state.deviceSelection.platform,
  androidChargingCord: state.deviceSelection.androidChargingCord,
  selectedDiabetesDevices: state.deviceSelection.selectedDiabetesDevices.map(deviceId =>
    state.deviceSelection.allDiabetesDevices.find(device => device.id === deviceId)),
  skipDiabetesDevices: state.deviceSelection.skipDiabetesDevices,
  language: state.language.language,
  isValid: deviceSelectionValid(state.deviceSelection),
  canGoBack: state.enrollmentWizard.history.length > 0,
});

const mapDispatchToProps = dispatch => ({
  fetchShopifyProducts: () => dispatch(fetchShopifyProducts()),
  onSelectPlatform: platform => dispatch(onSelectPlatform(platform)),
  onSelectAndroidChargingCord:
      cord => dispatch(onSelectAndroidChargingCord(cord)),
  onBack: () => dispatch(onBack()),
  onContinue: (skipDiabetesDevices) => {
    if (skipDiabetesDevices) {
      dispatch(onProceedToUploader());
    } else {
      dispatch(onProceedToWaysToSync());
    }
  },
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceSelection));
