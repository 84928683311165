import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, Radio, Button } from '@glooko/common-ui';
import { getLogInURL } from '~/utils/links';
import withTranslate from '../../shared/withTranslate/withTranslate';

import './style.sass';

const WithdrawParentalConsent = ({
  t,
  userDecryptedDetails,
  language,
  captureParentalConsent,
  registerStatus,
}) => {
  const { email, partnerConsent, version } = userDecryptedDetails;
  const [choice, setChoice] = useState('unknown');
  const [confirmationPage, setConfirmationPage] = useState(false);

  useEffect(() => {
    if (registerStatus === 'registered') {
      setConfirmationPage(true);
    }
  }, [registerStatus]);

  const onClickLogin = () => {
    const loginUrl = getLogInURL(language);
    window.location.assign(loginUrl);
  };

  const handleStopSharingData = (sharingStatus) => {
    setChoice(sharingStatus);
  };

  const onClickWithdrawButton = () => {
    const payload = {
      consents: partnerConsent,
      email,
      choice,
      version,
    };
    captureParentalConsent(payload);
  };

  return (
    <div className="WithdrawParentalConsent">
      <div className="WithdrawParentalConsent-content">
        { !confirmationPage &&
        <div className="WithdrawParentalConsent-completeContent">
          <div className="WithdrawParentalConsent-header">
            {t('header')}
          </div>
          <div className="WithdrawParentalConsent-divContent">
            {t('content', { partnerConsent })}
          </div>
          <div className="WithdrawParentalConsent-horizontalLine" />
          <div className="WithdrawParentalConsent-selectionWidget">
            <RadioGroup
              className="WithdrawParentalConsent-radio"
              value={choice === 'unknown' ? 'none' : choice}
              onChange={(event) => { handleStopSharingData(event.currentTarget.value); }}
              dataAttributes={{ testid: 'unit-of-measure' }}
            >
              <Radio label={t('stopSharing', { partnerConsent })} value="disagree" name="radio-group-disagree" />
              <Radio label={t('stopSharingAndDelete', { partnerConsent })} value="disagree_delete" name="radio-group-disagree-delete" />
            </RadioGroup>
            <div className="WithdrawParentalConsent-divButton" data-testid="withdraw-consent-button-div">
              <p className="WithdrawParentalConsent-paragraph">
                <Button
                  className="WithdrawParentalConsent-secondaryButton"
                  onClick={onClickLogin}
                  dataAttributes={{ testid: 'withdraw-parental-cancel-button' }}
                  variation="secondary"
                >
                  {t('cancel')}
                </Button>
              </p>
              <p className="WithdrawParentalConsent-paragraph">
                <Button
                  className="WithdrawParentalConsent-primaryButton"
                  disabled={choice === 'unknown'}
                  onClick={onClickWithdrawButton}
                  dataAttributes={{ testid: 'withdraw-parental-consent-button' }}
                >
                  {t('withdrawConsent')}
                </Button>
              </p>
            </div>
          </div>
        </div>}
        {confirmationPage &&
        <div>
          <div className="WithdrawParentalConsent-header">
            {t('confirmation')}
          </div>
          <div className="WithdrawParentalConsent-divContent">
            {t('registeredMessage', { partnerConsent })}
          </div>
          <div className="WithdrawParentalConsent-doneDiv">
            <Button
              className="WithdrawParentalConsent-doneButton"
              onClick={onClickLogin}
              dataAttributes={{ testid: 'withdraw-parental-done-button' }}
            >
              {t('done')}
            </Button>
          </div>
        </div>}
      </div>
    </div>
  );
};

WithdrawParentalConsent.propTypes = {
  t: PropTypes.func.isRequired,
  userDecryptedDetails: PropTypes.object.isRequired,
  language: PropTypes.string,
  captureParentalConsent: PropTypes.func.isRequired,
  registerStatus: PropTypes.string,
};

WithdrawParentalConsent.defaultProps = {
  language: 'en',
  registerStatus: '',
};

export default withTranslate(WithdrawParentalConsent);
