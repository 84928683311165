import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { logEvent } from '~/store/modules/logger';
import { onUpdateAttribute } from '~/store/modules/accountInformation';
import DownloadUploaderMobile from './DownloadUploaderMobile';

const mapStateToProps = state => ({
  countryCode: state.accountInformation.countryCode,
  language: state.language.language,
  mobilePhone: state.accountInformation.mobilePhone,
  phoneCountry: state.accountInformation.phoneCountry,
  receiveSms: state.accountInformation.receiveSms,
  platform: state.deviceSelection.platform,
});

const mapDispatchToProps = dispatch => ({
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  logEvent: (name, data) => dispatch(logEvent(name, data)),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(DownloadUploaderMobile));
