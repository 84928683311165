import MockAdapter from 'axios-mock-adapter';
import axios from 'axios';
import replyApiV2RefDevices from './mocks/api/v2/reference/devices';
// import replyApiV2RefMedications from './mocks/api/v2/reference/medications';
// import apiV3UsrActivations from './mocks/api/v3/users/activations';
// import replyApiV2Countries from './mocks/api/v2/countries';
// import apiV3EligibilityAddress from './mocks/api/v3/eligibility/addresses';
// import apiV3Enrollment from './mocks/api/v3/enrollments';
// import apiV3Products from './mocks/api/v3/shopify/products';

const mock = new MockAdapter(axios, { delayResponse: 500 });

/* eslint newline-per-chained-call: 0 */

mock.onGet(/api\/v1\/dummy\/.*\//).reply(replyApiV2RefDevices)
  // .onGet(/api\/v2\/reference\/devices/).reply(replyApiV2RefDevices)
  // .onGet(/api\/v2\/reference\/medications/).reply(replyApiV2RefMedications)
  // .onGet(/api\/v2\/countries/).reply(replyApiV2Countries)
  // .onGet(/api\/v3\/eligibility\/addresses/).reply(apiV3EligibilityAddress)
  // .onGet(/api\/v3\/shopify\/products/).reply(apiV3Products)
  // .onPut(/api\/v3\/users\/addresses/).reply(200)
  // .onPost(/api\/v3\/enrollments/).reply(apiV3Enrollment)
  // .onPost(/api\/v3\/enrollment\/welcome_kits/).reply(200)
  // .onPatch(/api\/v3\/users\/passwords/).reply(apiV3UsrActivations)
  // .onPatch(/api\/v3\/users\/activations/).reply(apiV3UsrActivations)
  // .onPost(/api\/v3\/users\/minor\/consent/).reply(201)

  .onAny().passThrough(); // pass through other requests

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common.Accept = 'application/json';

export default axios;
