export const platformValid = ({ platform }) => platform && platform !== '';

export const androidChargingCordValid = ({ androidChargingCord, platform }) =>
  platform !== 'android' || !!androidChargingCord;

export const devicesValid = ({ selectedDiabetesDevices, skipDiabetesDevices }) =>
  (selectedDiabetesDevices && selectedDiabetesDevices.length > 0) || skipDiabetesDevices;

export const fieldValidators = {
  platform: platformValid,
  androidChargingCord: androidChargingCordValid,
  selectedDiabetesDevices: devicesValid,
};

export const deviceSelectionValid = form =>
  Object.keys(fieldValidators).every(field => fieldValidators[field](form));
