import React from 'react';
import PropTypes from 'prop-types';

import Checkbox from '../../../../shared/Checkbox/Checkbox';
import HardwareDescription from './HardwareDescription';

const Hardware = ({
  hardware, isSelected, selectVariant, t, deselectVariant,
}) => {
  const {
    variantId, price, title, device, imgSrc,
  } = hardware;
  const onSelectVariant = () => {
    if (isSelected) {
      deselectVariant(variantId);
    } else { selectVariant(variantId); }
  };

  const description = (
    <HardwareDescription
      device={device}
      price={price}
      title={title}
      t={t}
    />
  );
  const backgroundStyle = { backgroundImage: `url(${imgSrc})` };

  return (
    <div className="Hardware">
      <div className="Hardware-image" style={backgroundStyle} />
      <Checkbox
        value={variantId}
        checked={isSelected}
        labelContent={description}
        onChange={onSelectVariant}
      />
    </div>
  );
};

Hardware.propTypes = {
  deselectVariant: PropTypes.func.isRequired,
  hardware: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  selectVariant: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Hardware.defaultProps = {
  isSelected: false,
};

export default Hardware;
