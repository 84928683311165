import { fetchCurrentUser, fetchEligibilityAddress } from '~/services/userService';
import { logError } from '~/services/loggingService';
import { onProceedToDeviceSelection, onProceedToUploader } from './enrollmentWizard';
// ACTIONS
export const SESSION_REQUESTED = 'session/SESSION_REQUESTED';
export const SESSION_RECEIVED = 'session/SESSION_RECEIVED';
export const ELIGIBILTY_REQUESTED = 'session/ELIGIBILTY_REQUESTED';
export const ELIGIBILTY_RECEIVED = 'session/ELIGIBILTY_RECEIVED';

const initialState = {
  currentUser: undefined,
  address: {},
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case SESSION_REQUESTED:
      return {
        ...state,
        sessionStatus: 'pending',
      };

    case SESSION_RECEIVED:
      return {
        ...state,
        sessionStatus: action.sessionStatus,
        currentUser: action.currentUser,
      };

    case ELIGIBILTY_REQUESTED:
      return {
        ...state,
        eligibityStatus: 'pending',
      };

    case ELIGIBILTY_RECEIVED:
      return {
        ...state,
        eligibityStatus: action.eligibityStatus,
        address: Object.assign({}, action.eligibilityAddress, state.address),
      };

    default:
      return state;
  }
};

// ACTION CREATORS
export const onSessionReceived = (status, user) => (
  { type: SESSION_RECEIVED, sessionStatus: status, currentUser: user }
);

export const fetchSessionUser = () =>
  (dispatch) => {
    dispatch({ type: SESSION_REQUESTED });

    fetchCurrentUser()
      .then((res) => {
        const isGlookoResearchSite = res.data.currentUser
                                    && res.data.currentUser.glookoResearchSite;
        dispatch({
          type: SESSION_RECEIVED,
          sessionStatus: 'verified',
          currentUser: res.data.currentUser,
        });
        if (isGlookoResearchSite) {
          dispatch(onProceedToUploader());
        } else {
          dispatch(onProceedToDeviceSelection());
        }
      })
      .catch(() => {
        dispatch({
          type: SESSION_RECEIVED,
          sessionStatus: 'unauthorized',
        });
      });
  };

export const fetchUserEligibility = () =>
  (dispatch) => {
    dispatch({ type: ELIGIBILTY_REQUESTED });

    fetchEligibilityAddress()
      .then((res) => {
        dispatch({
          type: ELIGIBILTY_RECEIVED,
          eligibityStatus: 'fetched',
          eligibilityAddress: res.data.address,
        });
      })
      .catch((error) => {
        dispatch({
          type: ELIGIBILTY_RECEIVED,
          eligibityStatus: 'unauthorized',
        });
        logError(error);
      });
  };
