import React from 'react';
import PropTypes from 'prop-types';
import momentPropTypes from 'react-moment-proptypes';
import scrollToComponent from 'react-scroll-to-component';
import images from '~/utils/ImageStore';

import TextField from '../../shared/TextField/TextField';
import PasswordField from '../../shared/PasswordField/PasswordField';

import DobPicker from '../../shared/DobPicker/DobPicker';
import Consents from '../AccountInformation/Consents/Consents';
import GuardianInfo from '../AccountInformation/GuardianInfo/GuardianInfo';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';

import withTranslate from '../../shared/withTranslate/withTranslate';

import {
  emailValid,
  passwordValid,
  codeActivationFieldValidators,
  consentsValid,
  sizeValid,
  strengthValue,
} from
  '../AccountInformation/validations/accountInformation';

import './style.sass';

const formFields = [
  'code',
  'email',
  'password',
  'dateOfBirth',
  'gaurdianInfo',
  'consents',
];

export class CodeActivation extends React.Component {
  constructor() {
    super();
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onCodeActivate = this.onCodeActivate.bind(this);
  }

  componentDidMount() {
    if (this.codeRef) this.codeRef.focus();
  }

  onCodeActivate(e) {
    if (this.props.isValid || (e.shiftKey && (e.altKey || e.ctrlKey))) {
      this.props.onCodeActivate();
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !codeActivationFieldValidators[field](this.props[field], this.props, ref) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  onChangeValue(value, attribute) {
    this.props.onUpdateAttribute({ value, attribute });
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref.touch) { ref.touch(); }
    });
  }

  render() {
    const { t } = this.props;

    return (
      <div className="CodeActivation">
        <img className="EmailActivation-headerImage" alt="" src={images.activation} />
        <div className="CodeActivation-header">
          {t('activateYourAccount')}
          <div className="CodeActivation-header-sub">
            {t('activateDetail')}
          </div>
        </div>
        <div className="CodeActivation-header-small">
          {t('activateAccountTitle')}
        </div>
        <form className="CodeActivation-form">
          <TextField
            label={t('code')}
            onChange={(v) => { this.onChangeValue(v, 'code'); }}
            value={this.props.code}
            ref={(el) => { this.codeRef = el; }}
            required
          />
          <TextField
            isValid={emailValid}
            label={t('email')}
            onChange={(v) => { this.onChangeValue(v, 'email'); }}
            value={this.props.email}
            ref={(el) => { this.emailRef = el; }}
            required
          />
          <PasswordField
            isValid={passwordValid}
            onChange={(v) => { this.onChangeValue(v, 'password'); }}
            value={this.props.password}
            ref={(el) => { this.passwordRef = el; }}
            required
            onKeyPress={() => {}}
            sizeValid={sizeValid}
            strengthValue={strengthValue}
            withStrengthMeter
          />
          <DobPicker
            label={t('birthDate')}
            language={this.props.language}
            onDateChange={(v) => { this.onChangeValue(v, 'dateOfBirth'); }}
            value={this.props.dateOfBirth}
            ref={(el) => { this.dateOfBirthRef = el; }}
          />
          <GuardianInfo
            label={this.props.t('birthDate')}
            dateOfBirth={this.props.dateOfBirth}
            majorityAge={this.props.majorityAge}
            onChange={(v) => { this.onChangeValue(v, 'guardianInfo'); }}
            values={this.props.guardianInfo}
            ref={(el) => { this.gaurdianInfoRef = el; }}
          />
          <Consents
            label={t('termsAndConditions')}
            onChange={(v) => {
              this.onChangeValue(v, 'consents');
              this.forceUpdate();
            }}
            isValid={consentsValid}
            values={this.props.consents}
            ref={(el) => { this.consentsRef = el; }}
          />
          {this.props.registerStatus === 'failed' &&
            <div className="AccountInformation-form-error">
              {this.props.registerError.error ? t('invalidCodeError') : t('codeActivateError')}
            </div>}
          <EnrollmentWizardNavigation
            canContinue={this.props.isValid}
            onContinue={this.onCodeActivate}
            primaryLabel={t('activate')}
            showContinue
          />
        </form>
      </div>
    );
  }
}

CodeActivation.propTypes = {
  code: PropTypes.string,
  consents: PropTypes.object,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  email: PropTypes.string,
  language: PropTypes.string,
  onCodeActivate: PropTypes.func.isRequired,
  onUpdateAttribute: PropTypes.func.isRequired,
  password: PropTypes.string,
  isValid: PropTypes.bool,
  registerStatus: PropTypes.string,
  registerError: PropTypes.object,
  guardianInfo: PropTypes.object,
  t: PropTypes.func.isRequired,
};

CodeActivation.defaultProps = {
  code: undefined,
  consents: undefined,
  dateOfBirth: undefined,
  majorityAge: undefined,
  registerStatus: undefined,
  registerError: undefined,
  email: undefined,
  isValid: false,
  language: 'en',
  password: undefined,
  guardianInfo: undefined,
};

export default withTranslate(CodeActivation);
