import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import images from '../../../utils/ImageStore';

import './style.sass';
import withTranslate from '../../../shared/withTranslate/withTranslate';


export const ChargingCordButton = ({
  chargingCord, onSelectCord, selected, imageSrc, description,
}) => {
  const classes = classnames({ ChargingCordButton: true, selected });
  const overlayClasses =
    classnames({ 'ChargingCordButton-overlay': true, selected });
  const descriptionClasses = classnames({
    'ChargingCordButton-description': true, selected,
  });
  return (
    <div className="ChargingCordButton">
      <button
        className={classes}
        onClick={() => onSelectCord(chargingCord)}
        type="button"
      >
        <img alt={chargingCord} src={imageSrc} />
        <div className={overlayClasses} />
      </button>
      <div className={descriptionClasses}>
        { description }
      </div>
    </div>
  );
};

class AndroidChargingCordSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
  }

  showError() {
    return !this.props.isValid(this.props.selectedCord) && this.state.touched;
  }

  touch() {
    this.setState({ touched: true });
  }

  classes() {
    return classnames({
      AndroidChargingCordSelector: true,
      error: this.showError(),
    });
  }

  render() {
    const { onSelectCord, selectedCord, t } = this.props;
    return (
      <div className={this.classes()}>
        <div className="AndroidChargingCordSelector-dialog">
          { t('chargingCordDialog') }
        </div>
        <div className="AndroidChargingCordSelector-buttons">
          <ChargingCordButton
            chargingCord="usbMicro"
            description={t('microUsb')}
            onSelectCord={onSelectCord}
            selected={selectedCord === 'usbMicro'}
            imageSrc={images.usbMicro}
          />
          <ChargingCordButton
            chargingCord="usbC"
            description={t('usbC')}
            onSelectCord={onSelectCord}
            selected={selectedCord === 'usbC'}
            imageSrc={images.usbC}
          />
        </div>
      </div>
    );
  }
}

AndroidChargingCordSelector.propTypes = {
  isValid: PropTypes.func.isRequired,
  onSelectCord: PropTypes.func.isRequired,
  selectedCord: PropTypes.string,
  t: PropTypes.func.isRequired,
};

AndroidChargingCordSelector.defaultProps = {
  selectedCord: undefined,
};

ChargingCordButton.propTypes = {
  chargingCord: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageSrc: PropTypes.any.isRequired,
  onSelectCord: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default withTranslate(AndroidChargingCordSelector);
