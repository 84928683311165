import _ from 'lodash';
import { detectedBrowser } from '~/utils/deviceDetection';
import ExecuteApiCall from './apiClient';

export function fetchCurrentUser() {
  return ExecuteApiCall('get', 'api/v3/session/users');
}

export function fetchEligibilityAddress() {
  return ExecuteApiCall('get', 'api/v3/eligibility/addresses');
}

export function saveUserShippingInfo(address) {
  return ExecuteApiCall('put', 'api/v3/users/addresses', address);
}

export function sendWelcomeKit(products, devices, mobilePlatform) {
  return ExecuteApiCall(
    'post', 'api/v3/enrollments/welcome_kit_orders',
    { productVariantIds: products, referenceDeviceIds: devices, mobilePlatform },
  );
}

function buildConsents(user, isMinor) {
  const consents = [];
  if (user.consents.privacy) {
    consents.push('privacy');
  }
  if (user.consents.termsOfUse) {
    consents.push('terms_of_use');
  }
  if (user.consents.marketing) {
    consents.push('email');
  }
  if (user.guardianInfo && user.guardianInfo.consent) {
    consents.push('parental');
  }
  if (user.professional || isMinor) {
    consents.push('');
  }

  return consents;
}

export function codeActivate(user) {
  const guardianInfo = _.pick(
    user.guardianInfo,
    'guardianFirstName',
    'guardianLastName',
    'guardianEmail',
  );
  const userPayload = Object.assign(
    _.omit(user, 'consents', 'dateOfBirth', 'guardianInfo'),
    guardianInfo,
    { consents: buildConsents(user) },
    { dateOfBirth: user.dateOfBirth.toISOString() },
  );

  return ExecuteApiCall('patch', 'api/v3/users/activations', userPayload);
}

export function emailActivate(user, isMinor) {
  const guardianInfo = _.pick(
    user.guardianInfo,
    'guardianFirstName',
    'guardianLastName',
    'guardianEmail',
  );
  const userPayload = Object.assign(
    _.omit(user, 'consents', 'dateOfBirth', 'professional', 'guardianInfo'),
    guardianInfo,
    { consents: buildConsents(user, isMinor) },
    user.professional ? {} : { dateOfBirth: user.dateOfBirth.toISOString() },
  );

  return ExecuteApiCall('patch', 'api/v3/users/passwords', userPayload);
}

export function register(user, language, isMinor) {
  const guardianInfo = _.pick(
    user.guardianInfo,
    'guardianFirstName',
    'guardianLastName',
    'guardianEmail',
  );
  const deviceInformation = {
    os: 'browser',
    osVersion: detectedBrowser().version,
    applicationVersion: WEBAPP_VERSION, // eslint-disable-line no-undef
    applicationType: 'logbook',
  };
  const userPayload = Object.assign(
    _.omit(user, 'guardianInfo', 'consents', 'dateOfBirth'),
    guardianInfo,
    { consents: buildConsents(user, isMinor) },
    { dateOfBirth: user.dateOfBirth.toISOString() },
    { language },
    { deviceInformation },
  );

  return ExecuteApiCall('post', 'api/v3/enrollments', userPayload);
}

export function minorActivate(payload) {
  return ExecuteApiCall('post', 'api/v3/users/minor/consent', payload);
}

export function captureParentalConsent(payload) {
  return ExecuteApiCall('post', 'api/v3/users/minor_parental_consent', payload);
}

export function fetchPendingUserConsents(userEmail, language, partnerConsent) {
  const payload = {
    email: userEmail,
    locale: language,
    consents: partnerConsent,
  };

  return ExecuteApiCall('get', 'api/v3/users/minor_partner_consent', payload);
}
