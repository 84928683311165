import { filter } from 'lodash';

// ACTIONS
import { fetchProducts } from '../../services/shopifyService';
import { saveSelectedDiabetesDevices } from '../../services/deviceService';
import { pgsSponsored } from '../../utils/hardwareSelectionUtil';
import { GO_BACK, PROCEED_TO_SHIPPING, PROCEED_TO_UPLOADER } from
  './enrollmentWizard';
import { checkoutUrl } from '../../utils/shopifyUtil';

export const PRODUCTS_REQUESTED = 'shopify/PRODUCTS_REQUESTED';
export const PRODUCTS_RECEIVED = 'shopify/PRODUCTS_RECEIVED';
export const SELECT_VARIANT = 'shopify/SELECT_VARIANT';
export const DESELECT_VARIANT = 'shopify/DESELECT_VARIANT';
export const CLEAR_VARIANTS = 'shopify/CLEAR_VARIANTS';
export const UNVISIT_HARDWARE = 'shopify/UNVISIT_HARDWARE';
export const VISIT_HARDWARE = 'shopify/VISIT_HARDWARE';

const initialState = {
  products: [],
  selectedVariants: [],
  hardwareVisited: false,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case PRODUCTS_REQUESTED:
      return {
        ...state,
        productsStatus: 'requested',
      };

    case PRODUCTS_RECEIVED:
      return {
        ...state,
        products: action.products,
        productsStatus: 'received',
      };

    case SELECT_VARIANT:
      return {
        ...state,
        selectedVariants: state.selectedVariants.concat(action.variantId),
      };

    case DESELECT_VARIANT:
      return {
        ...state,
        selectedVariants:
          filter(state.selectedVariants, v => v !== action.variantId),
      };

    case CLEAR_VARIANTS:
      return {
        ...state,
        selectedVariants: [],
      };

    case VISIT_HARDWARE:
      return {
        ...state,
        hardwareVisited: true,
      };

    case UNVISIT_HARDWARE:
      return {
        ...state,
        hardwareVisited: false,
      };

    default:
      return state;
  }
};

// ACTION CREATORS
export const fetchShopifyProducts = () =>
  (dispatch) => {
    dispatch({ type: PRODUCTS_REQUESTED });
    return fetchProducts()
      .then(({ data }) => {
        dispatch({
          type: PRODUCTS_RECEIVED,
          products: data,
        });
      })
      .catch(() => {
        dispatch({
          type: PRODUCTS_RECEIVED,
          products: [],
        });
      });
  };

export const onSelectVariant = variantId =>
  ({ type: SELECT_VARIANT, variantId });

export const onDeselectVariant = variantId =>
  ({ type: DESELECT_VARIANT, variantId });

export const onSubmitHardware = () =>
  (dispatch, getState) => {
    const { selectedVariants } = getState().shopify;
    const { language } = getState().language;
    const { currentUser } = getState().session;

    const devices = getState().deviceSelection.selectedDiabetesDevices;

    // We skip the download uploader page when the only thing selected is iGlucose.
    // It then forwards to the iGlucose registration.
    if (devices.length === 1 && devices[0] === 'SMART_METER_IGLUCOSE') {
      window.location.href = `${MY_GLOOKO_URL}/iglucose/connect`; // eslint-disable-line no-undef
      return;
    }

    const { selectedDiabetesDevices } = getState().deviceSelection;

    const payload = {};
    payload.selectedDevices = [];

    const payloadItems = selectedDiabetesDevices.map(item => (
      { referenceDeviceId: item }
    ));

    payload.selectedDevices = payloadItems;

    saveSelectedDiabetesDevices(payload);

    if (selectedVariants.length < 1) {
      dispatch({ type: PROCEED_TO_UPLOADER });
      return;
    }

    if (pgsSponsored(currentUser)) {
      dispatch({ type: PROCEED_TO_SHIPPING });
    } else {
      window.open(checkoutUrl(selectedVariants, language), '_blank');
      dispatch({ type: PROCEED_TO_UPLOADER });
    }
  };

export const onVisitHardwareSelection = () =>
  ({ type: VISIT_HARDWARE });

export const cancelSelectHardware = () =>
  (dispatch) => {
    dispatch({ type: CLEAR_VARIANTS });
    dispatch({ type: UNVISIT_HARDWARE });
    dispatch({ type: GO_BACK });
  };
