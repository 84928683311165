import React from 'react';
import PropTypes from 'prop-types';

import { APPLE_STORE_GLOOKO_APP, GOOGLE_PLAY_GLOOKO_APP, PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '~/utils/links';
import { getAppleBadgeImage, getGoogleBadgeImage } from '~/utils/appBadges';
import { disableMobileActivation } from '../utils/navigation';
import EmailActivationFooter from './EmailActivationFooter';
import withTranslate from '../shared/withTranslate/withTranslate';

import './style.sass';

const Footer = ({
  language,
  t,
  currentStep,
  isMinorPage,
  isGlookoResearchSite,
  isCountryOfResidenceUSAInQRCodeURL,
  logEvent,
}) => {
  const isMobileActivation = disableMobileActivation();
  const isQRCodeActivation =
    currentStep && currentStep.name === 'qrCodeActivation';
  if (isMinorPage) {
    return null;
  }

  const onDownloadClicked = () => {
    logEvent(
      'en_join_downloaded_mobile_app',
      { linked_app: 'glooko' }, // eslint-disable-line camelcase
    );
  };

  return (
    <div>
      {currentStep && ((currentStep.name !== 'EmailActivation' || isMobileActivation) &&
        (!isGlookoResearchSite)) &&
        !isQRCodeActivation &&
          <div className="Footer">
            <div className="Footer-support">
              <div>
                <div className="Footer-AppStoreBadge" >
                  <a
                    href={APPLE_STORE_GLOOKO_APP}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="footer-apple-store"
                    onClick={() => onDownloadClicked()}
                  >
                    <img
                      alt={t('downloadAppStore')}
                      src={getAppleBadgeImage(language)}
                    />
                  </a>
                </div>
                <div className="Footer-GooglePlayBadge" >
                  <a
                    href={GOOGLE_PLAY_GLOOKO_APP}
                    target="_blank"
                    rel="noopener noreferrer"
                    data-testid="footer-google-play"
                    onClick={() => onDownloadClicked()}
                  >
                    <img
                      alt={t('downloadGooglePlay')}
                      src={getGoogleBadgeImage(language)}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="Footer-contact">
              <h4>{t('visitGlooko')}</h4>
              <p><a href="https://support.glooko.com" target="_blank" rel="noopener noreferrer" data-testid="footer-support-glooko">support.glooko.com</a></p>
            </div>
            <div className="Footer-legal">
              <h4>&nbsp;</h4>
              <p>&copy;&nbsp;{new Date(Date.now()).getFullYear()} {t('copyright')}</p>
              <p>MKT-0087 01</p>
              <p className="Footer-privacyPolicy">
                <a
                  href={PRIVACY_POLICY_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="footer-privacy-policy"
                >
                  {t('privacyPolicy')}
                </a>&nbsp;|&nbsp;
                <a
                  href={TERMS_OF_USE_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  data-testid="footer-terms-of-use"
                >
                  {t('termsOfUse')}
                </a>
              </p>
            </div>
          </div>
        }
      {currentStep && currentStep.name === 'EmailActivation' && !isMobileActivation && !isGlookoResearchSite &&
        <EmailActivationFooter
          language={language}
          t={t}
        />
        }
      {isGlookoResearchSite &&
        <div className="Footer">
          <div>
            <p>&copy;&nbsp;{new Date(Date.now()).getFullYear()} {t('copyright')}</p>
            <p className="Footer-privacyPolicy">
              <a
                href={PRIVACY_POLICY_URL}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="footer-privacy-policy"
              >
                {t('privacyPolicy')}
              </a> &nbsp;|&nbsp;
              <a
                href={TERMS_OF_USE_URL}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="footer-terms-of-use"
              >
                {t('termsOfUse')}
              </a>
            </p>
          </div>
        </div>
      }
      {isQRCodeActivation && (
        <div className="QRCodeActivationFooter">
          <div className="QRCodeActivationFooter-needHelp">{t('needHelp')}</div>
          {isCountryOfResidenceUSAInQRCodeURL && (
            <div className="QRCodeActivationFooter-callUs">
              <span>{t('callUs')}</span>&nbsp;
              <span className="QRCodeActivationFooter-supportContact">(650) 720-5310</span>
            </div>
          )}
          {
            <div>
              <span>
                {isCountryOfResidenceUSAInQRCodeURL
                  ? `${t('or')}  ${t('visit').toLowerCase()}`
                  : t('visit')}
              </span>
              &nbsp;
              <span className="QRCodeActivationFooter-supportContact">
                <a href="https://support.glooko.com" target="_blank" rel="noopener noreferrer" data-testid="footer-support-glooko">support.glooko.com</a>
              </span>
            </div>
          }
        </div>
      )}
    </div>
  );
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  logEvent: PropTypes.func,
  currentStep: PropTypes.object,
  isMinorPage: PropTypes.bool,
  isGlookoResearchSite: PropTypes.bool,
  isCountryOfResidenceUSAInQRCodeURL: PropTypes.bool,
};

Footer.defaultProps = {
  currentStep: undefined,
  isMinorPage: false,
  language: 'de',
  logEvent: () => {},
  isGlookoResearchSite: false,
  isCountryOfResidenceUSAInQRCodeURL: true,
};

export default withTranslate(Footer);
