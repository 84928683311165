import 'idempotent-babel-polyfill';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { resetEventLogging } from '~/services/loggingService';
import AppContainer from './AppContainer';
import store from './store/store';

resetEventLogging();
const root = createRoot(document.getElementById('root'));
root.render(<Provider store={store}>
  <BrowserRouter>
    <AppContainer />
  </BrowserRouter>
</Provider>); // eslint-disable-line react/jsx-closing-tag-location
