/* eslint-disable react/prop-types */
/* eslint-disable arrow-parens */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@glooko/common-ui';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import images from '~/utils/ImageStore';
import './style.sass';

const hideHeader = () => {
  const header = document.getElementsByClassName('EmailActivationHeader')[0];
  if (header) {
    header.style.display = 'none';
  }
};

const TextSent = props => {
  hideHeader();
  const { t, setTextMessageSentStatus } = props;
  return (
    <div className="TextSent-box">
      <div className="TextSent-container">
        <img alt="TextSent" src={images.textSent} />
        <div className="TextSent-message-container">
          <div className="TextSent-title">{t('textSentTitle')}</div>
          <div className="TextSent-message">{t('textSentMessage')}</div>
        </div>
      </div>
      <Button
        className="TextSent-resend-button"
        variation="secondary"
        onClick={() => setTextMessageSentStatus()}
        dataAttributes={{ testid: 'text-sent-resend' }}
      >
        {t('resend')}
      </Button>
    </div>
  );
};

TextSent.propTypes = {
  setTextMessageSentStatus: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslate(TextSent);
