import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { reactI18nextModule } from 'react-i18next';
import translations from './translations';

i18next
  .use(LanguageDetector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources: translations,
    fallbackLng: {
      nb: ['nb-NO'],
      default: ['en'],
    },
    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['querystring', 'navigator'],
      lookupQuerystring: 'locale',
    },
  });

const getTextDirection = () => (i18next.language === 'ar' ? 'rtl' : 'ltr');

const setTextDirection = () => {
  document.getElementsByTagName('html')[0].setAttribute('dir', getTextDirection());
};

i18next.on('languageChanged', () => {
  setTextDirection();
});

// Initialize Text Direction when page is loaded
setTextDirection();

export default i18next;
