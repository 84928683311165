import i18nCountries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import frLocale from 'i18n-iso-countries/langs/fr.json';
import csLocale from 'i18n-iso-countries/langs/cs.json';
import daLocale from 'i18n-iso-countries/langs/da.json';
import deLocale from 'i18n-iso-countries/langs/de.json';
import esLocale from 'i18n-iso-countries/langs/es.json';
import hrLocale from 'i18n-iso-countries/langs/hr.json';
import itLocale from 'i18n-iso-countries/langs/it.json';
import nlLocale from 'i18n-iso-countries/langs/nl.json';
import nbLocale from 'i18n-iso-countries/langs/nb.json';
import plLocale from 'i18n-iso-countries/langs/pl.json';
import ptLocale from 'i18n-iso-countries/langs/pt.json';
import skLocale from 'i18n-iso-countries/langs/sk.json';
import svLocale from 'i18n-iso-countries/langs/sv.json';
import trLocale from 'i18n-iso-countries/langs/tr.json';
import elLocale from 'i18n-iso-countries/langs/el.json';
import fiLocale from 'i18n-iso-countries/langs/fi.json';

const localesToRegister = {
  en: enLocale,
  fr: frLocale,
  cs: csLocale,
  da: daLocale,
  de: deLocale,
  es: esLocale,
  hr: hrLocale,
  it: itLocale,
  nl: nlLocale,
  nb: nbLocale,
  pl: plLocale,
  pt: ptLocale,
  sk: skLocale,
  sv: svLocale,
  tr: trLocale,
  el: elLocale,
  fi: fiLocale,
};


export const registerLocales = () => {
  Object.entries(localesToRegister).forEach(([locale]) => {
    i18nCountries.registerLocale(localesToRegister[locale]);
  });
};
