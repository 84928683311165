import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const MedsSummaryItem = ({ medication }) => (
  <div className="MedsSummaryItem">
    <div className="MedsSummaryItem-Name">
      {medication.brand}
    </div>
  </div>
);

MedsSummaryItem.propTypes = {
  medication: PropTypes.object.isRequired,
};


export default MedsSummaryItem;
