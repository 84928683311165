import { fetchPendingUserConsents } from '~/services/userService';

// ACTIONS
export const CONSENTS_REQUESTED =
  'partnerConsents/CONSENTS_REQUESTED';
export const CONSENTS_RECEIVED =
  'partnerConsents/CONSENTS_RECEIVED';

const initialState = {
  consents: [],
  isConsentLoading: true,
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case CONSENTS_REQUESTED:
      return {
        ...state,
        consents: [],
        isConsentLoading: true,
      };
    case CONSENTS_RECEIVED:
      return {
        ...state,
        consents: [].concat(action.consents),
        isConsentLoading: false,
      };
    default:
      return state;
  }
};

// ACTION CREATORS

// thunk action creator
export const fetchParentalConsents = (userEmail, lang, partnerConsent) =>
  (dispatch) => {
    if (partnerConsent === 'Insulet') {
      return;
    }
    dispatch({ type: CONSENTS_REQUESTED });

    fetchPendingUserConsents(userEmail, lang, partnerConsent)
      .then((res) => {
        dispatch({
          type: CONSENTS_RECEIVED,
          consents: res.data,
          isConsentLoading: true,
        });
      })
      .catch(() => {
        dispatch({
          type: CONSENTS_RECEIVED,
          consents: [],
          isConsentLoading: true,
        });
      });
  };
