import React from 'react';
import PropTypes from 'prop-types';
import { Button, LinkedText } from '@glooko/common-ui';
import Checkbox from '~/shared/Checkbox/Checkbox.jsx';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL, getLogInURL } from '~/utils/links';
import withTranslate from '../../shared/withTranslate/withTranslate';
import './style.sass';

class MinorActivation extends React.Component {
  constructor() {
    super();
    this.state = {
      isGuardianAgree: false,
      isAgreeInformational: false,
      isAgreeTextMessages: false,
      isActivatedSuccess: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) { // eslint-disable-line camelcase
    const { registerStatus } = this.props;
    if (registerStatus !== nextProps.registerStatus) {
      this.updateMinorDetails(nextProps);
    }
  }

 onChangeGuardianAgree = (event, name) => {
   this.setState({ [name]: event.target.checked });
 }

 onClickActivateAccount = () => {
   const { onMinorActivate, userDecryptedDetails } = this.props;
   const { email } = userDecryptedDetails;
   const { isAgreeTextMessages } = this.state;
   const payload = {
     consents: this.getConsents(),
     email,
     receiveSms: isAgreeTextMessages,
   };
   onMinorActivate(payload);
 }

 onClickLogin = () => {
   const { language } = this.props;
   const loginUrl = getLogInURL(language);
   window.location.assign(loginUrl);
 }

 getConsents = () => {
   const { isAgreeInformational } = this.state;
   const consents = ['privacy', 'terms_of_use', 'parental'];
   if (isAgreeInformational) {
     consents.push('email');
   }
   return consents;
 }

 updateMinorDetails = (nextProps) => {
   const { registerStatus } = nextProps;
   if (registerStatus === 'registered') {
     this.setState({ isActivatedSuccess: true });
   }
 }

 render() {
   const {
     isGuardianAgree, isAgreeInformational, isAgreeTextMessages,
     isActivatedSuccess,
   } = this.state;
   const { t, userDecryptedDetails } = this.props;
   const { email, clinicCode } = userDecryptedDetails;

   const privacyContent = (
     <LinkedText
       template={t('privacyConsent')}
       linkContent={[{ title: t('termsOfUse'), url: TERMS_OF_USE_URL }, { title: t('privacyNotice'), url: PRIVACY_POLICY_URL }]}
       openInNewTab
     />
   );
   const guardianConsentDesc = (
     <LinkedText
       template={t('guardianConsentDesc')}
       linkContent={[{ title: email, url: `mailto:${email}` }]}
       openInNewTab
     />
   );
   const successfulMessageWithEmail = (
     <LinkedText
       template={t('successfulMessage')}
       linkContent={[{ title: email, url: `mailto:${email}` }]}
       openInNewTab
     />
   );
   return (
     <div className="MinorEmailActivation">
       <div className="MinorEmailActivation-content">
         <div className="MinorEmailActivation-title">
           {t('guardianConsent')}
         </div>
         {!isActivatedSuccess &&
         <div className="MinorEmailActivation-body">
           <p className="MinorEmailActivation-description">
             {guardianConsentDesc}
           </p>
           <div>
             <div className="MinorEmailActivation-options">
               <Checkbox
                 value="isGuardianAgree"
                 checked={isGuardianAgree}
                 labelContent={privacyContent}
                 onChange={event => this.onChangeGuardianAgree(event, 'isGuardianAgree')}
               />
             </div>
             <div className="MinorEmailActivation-divider" />
             <div className="MinorEmailActivation-options">
               <Checkbox
                 value="isAgreeInformational"
                 checked={isAgreeInformational}
                 labelContent={t('recieveInformationalUpdates')}
                 onChange={event => this.onChangeGuardianAgree(event, 'isAgreeInformational')}
               />
             </div>
             <div className="MinorEmailActivation-options">
               <Checkbox
                 value="isAgreeTextMessages"
                 checked={isAgreeTextMessages}
                 labelContent={t('receiveTextMessages')}
                 onChange={event => this.onChangeGuardianAgree(event, 'isAgreeTextMessages')}
               />
             </div>
           </div>
           {clinicCode &&
           <p className="MinorEmailActivation-description">
             {t('shareDate', { clinicCode })}
           </p>
           }
           <p>
             <Button
               disabled={!isGuardianAgree}
               onClick={isGuardianAgree ? this.onClickActivateAccount : undefined}
               dataAttributes={{ testid: 'minor-activation-activate-account' }}
             >
               {t('activateAccount')}
             </Button>
           </p>
         </div>
        }
         {isActivatedSuccess &&
         <div>
           <p> {successfulMessageWithEmail} </p>
           <div>
             <Button
               onClick={this.onClickLogin}
               className="MinorEmailActivation-login"
               dataAttributes={{ testid: 'minor-activation-login' }}
             >
               {t('login')}
             </Button>
           </div>
         </div>
 }
       </div>
     </div>
   );
 }
}

MinorActivation.propTypes = {
  t: PropTypes.func.isRequired,
  userDecryptedDetails: PropTypes.object.isRequired,
  language: PropTypes.string,
  onMinorActivate: PropTypes.func.isRequired,
  registerStatus: PropTypes.string,
};

MinorActivation.defaultProps = {
  language: 'en',
  registerStatus: '',
};


export default withTranslate(MinorActivation);
