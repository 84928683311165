/* eslint-disable camelcase */
import { getUserEncryptedDetailsFromURL } from './navigation';

const crypto = require('crypto');
const config = require('../../config/decrypt.config.json');

const algorithm = 'aes-256-cbc';
const cipherKey = config.openssl_cipher_key; // 32 Characters
const cipherIv = config.openssl_cipher_iv;

export function encrypt(text) {
  const cipher = crypto.createCipheriv(algorithm, cipherKey, cipherIv);
  let crypted = cipher.update(text, 'utf-8', 'base64');
  crypted += cipher.final('base64');
  return crypted;
}

export function decryptData(text) {
  const cipher = crypto.createDecipheriv(algorithm, cipherKey, cipherIv);
  let crypted = cipher.update(text, 'base64', 'utf-8');
  crypted += cipher.final('utf-8');
  return crypted;
}

// splitting string into array
export function splitString(input, splitChar) {
  return input.split(splitChar);
}

// return json from whole user data string
export function createJsonFromDecryptedString(input) {
  const dataJson = {};
  input.forEach((elem) => {
    const [key, value] = splitString(elem, '=');
    dataJson[key] = value;
  });
  return dataJson;
}

// function return user details in json from QR code Encrypted URl
export function userDataFromQRCode() {
  const { user_details, branch_link } = getUserEncryptedDetailsFromURL();
  if (!user_details) return {};
  const decryptedUserDetails = decryptData(user_details);
  const data = createJsonFromDecryptedString(splitString(decryptedUserDetails, '&'));
  data.branch_link = branch_link;
  return data;
}

// function return user details in json from minor Encrypted URl
export function userDataFromMinorURL() {
  const { userDetails, clinicCode } = getUserEncryptedDetailsFromURL();
  const data = {};
  const decryptedUserDetails = userDetails && decryptData(userDetails);
  data.email = decryptedUserDetails;
  data.clinicCode = clinicCode;
  return data;
}

// function return user details in json from minor Encrypted URl
export function userDataFromMinorParentalConsentURL() {
  const { userDetails, deviceName, partnerConsent } = getUserEncryptedDetailsFromURL();
  const data = {};
  const decryptedUserDetails = userDetails && decryptData(userDetails);
  data.email = decryptedUserDetails;
  data.deviceName = deviceName;
  data.partnerConsent = partnerConsent;
  return data;
}

export function userDataFromWithdrawalConsentURL() {
  const { userDetails, partnerConsent, version } = getUserEncryptedDetailsFromURL();
  const data = {};
  const decryptedUserDetails = userDetails && decryptData(userDetails);
  data.email = decryptedUserDetails;
  data.partnerConsent = partnerConsent;
  data.version = version;
  return data;
}
