import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import WaysToSync from './WaysToSync';
import { onSubmitHardware, cancelSelectHardware } from
  '../../store/modules/shopify';

const fullSelectedDevices = (selected, references) => (
  selected.map(deviceId =>
    references.find(reference => reference.id === deviceId))
);

const mapStateToProps = state => ({
  mobilePlatform: state.deviceSelection.platform,
  selectedDevices:
    fullSelectedDevices(
      state.deviceSelection.selectedDiabetesDevices,
      state.deviceSelection.allDiabetesDevices,
    ),
});

const mapDispatchToProps = dispatch => ({
  onBack: () => dispatch(cancelSelectHardware()),
  onContinue: () => dispatch(onSubmitHardware()),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(WaysToSync));
