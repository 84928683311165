import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { PRIVACY_POLICY_URL, TERMS_OF_USE_URL } from '~/utils/links';
import { isOfAge } from '~/utils/dateUtils';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { LinkedText } from '@glooko/common-ui';
import Checkbox from '~/shared/Checkbox/Checkbox.jsx';

import './style.sass';

class Consents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
    };
    this.onCheckedChange = this.onCheckedChange.bind(this);
  }

  onCheckedChange(e) {
    this.props.onChange({
      ...this.props.values, [e.target.value]: e.target.checked,
    });
  }

  touch() {
    this.privacyRef.touch();
    this.termsOfUseRef.touch();
    this.setState({ touched: true });
  }

  classes() {
    const { values, isValid } = this.props;
    return classnames({
      'Consents-header': true,
      error: !isValid(values) && this.state.touched,
    });
  }

  render() {
    const {
      t, dateOfBirth, majorityAge, isNewEmailNotification,
    } = this.props;
    const privacyContent = (
      <LinkedText
        template={isNewEmailNotification ? t('privacyNoticeDescription') : t('privacyConsent')}
        linkContent={[{ title: t('privacyNotice'), url: PRIVACY_POLICY_URL }]}
        openInNewTab
      />
    );
    const termsContent = (
      <LinkedText
        template={t('termsOfUseConsent')}
        linkContent={[{ title: t('termsOfUse'), url: TERMS_OF_USE_URL }]}
        openInNewTab
      />
    );
    const marketingContent = <span>{isNewEmailNotification ? t('marketingDescription') : t('marketingConsent')}</span>;

    // we will only display if of age
    if (moment.isMoment(dateOfBirth) && majorityAge && !isOfAge(dateOfBirth, majorityAge)) {
      return null;
    }

    return (
      <div className="Consents">
        <div className={this.classes()}>{t('header')}</div>
        <Checkbox
          value="privacy"
          checked={this.props.values.privacy}
          labelContent={privacyContent}
          onChange={this.onCheckedChange}
          ref={(el) => { this.privacyRef = el; }}
          required
        />
        <Checkbox
          value="termsOfUse"
          checked={this.props.values.termsOfUse}
          labelContent={termsContent}
          onChange={this.onCheckedChange}
          ref={(el) => { this.termsOfUseRef = el; }}
          required
        />
        <Checkbox
          value="marketing"
          checked={this.props.values.marketing}
          labelContent={marketingContent}
          onChange={this.onCheckedChange}
        />
      </div>
    );
  }
}

Consents.propTypes = {
  t: PropTypes.func.isRequired,
  isValid: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.object,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  isNewEmailNotification: PropTypes.bool,
};

Consents.defaultProps = {
  onChange: () => {},
  values: { privacy: false, termsOfUse: false, marketing: false },
  dateOfBirth: null,
  majorityAge: null,
  isNewEmailNotification: false,
};

export default withTranslate(Consents);
