import { getRedirectTo } from './navigation';

export function isOauthEnrollment() {
  const redirectTo = getRedirectTo();
  if (!redirectTo) {
    return false;
  }

  return redirectTo.includes('/oauth/authorize');
}

