import React from 'react';
import PropTypes from 'prop-types';
import './style.sass';

const CheckboxListItem = (props) => {
  const {
    checked, label, value, rawData, fetchItemImageUrl, onChange,
  } = props;
  return (
    <div className="CheckboxListItem">
      <div className="CheckboxListItem-box">
        <input
          type="checkbox"
          id={value}
          name={value}
          value={value}
          checked={checked}
          onChange={onChange}
        />
      </div>
      {fetchItemImageUrl &&
        <div
          className="CheckboxListItem-ImageContainer"
          style={{
            backgroundImage: `url(${fetchItemImageUrl(rawData)})`,
            }
          }
        />
      }
      <label
        htmlFor={value}
        className="check-descr"
      >
        {label}
      </label>
    </div>
  );
};

CheckboxListItem.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  label: PropTypes.string,
  rawData: PropTypes.object,
  fetchItemImageUrl: PropTypes.func,
  onChange: PropTypes.func,
};

CheckboxListItem.defaultProps = {
  checked: false,
  value: '',
  label: '',
  rawData: undefined,
  fetchItemImageUrl: undefined,
  onChange: () => {},
};

export default CheckboxListItem;
