import React from 'react';
import PropTypes from 'prop-types';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import DownloadUploaderMobile from './DownloadUploaderMobile/DownloadUploaderMobileContainer.jsx';
import WebAppLink from './WebAppLink/WebAppLinkContainer.jsx';
import './style.sass';


class DownloadUploader extends React.Component {
  componentDidMount() {
    const { isSignIn, onCompletedEnrollment } = this.props;
    window.scrollTo(0, 0);
    if (isSignIn) {
      onCompletedEnrollment();
    }
  }

  getHeader = () => {
    const { isGlookoResearchSite, t } = this.props;
    if (isGlookoResearchSite) {
      return t('glookoResearchSiteHeader');
    }
    return t('syncWithGlooko');
  }

  getDownloadUploader = () => {
    const { isGlookoResearchSite, t } = this.props;
    if (isGlookoResearchSite) {
      return <div className="GlookoResearchSiteDesc"> {t('accountActivatedDesc')}</div>;
    }
    const { hasMobileCompatibleDevice, language } = this.props;
    const sections = {
      mobileDownload: <DownloadUploaderMobile />,
      webLink: <WebAppLink language={language} />,
    };
    const sectionOrder = hasMobileCompatibleDevice ? ['mobileDownload', 'webLink'] : ['webLink', 'mobileDownload'];
    const sectionPanels = sectionOrder.map(sectionKey => (
      <div className="DownloadUploader-panel" key={sectionKey} >
        {sections[sectionKey]}
      </div>
    ));
    return sectionPanels;
  }

  render() {
    return (
      <div className="DownloadUploader">
        <div className="DownloadUploader-header" >
          <div className="DownloadUploader-header-title">{this.getHeader()}</div>
        </div>
        {this.getDownloadUploader()}
      </div>
    );
  }
}

DownloadUploader.propTypes = {
  hasMobileCompatibleDevice: PropTypes.bool,
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
  isGlookoResearchSite: PropTypes.bool,
  onCompletedEnrollment: PropTypes.func,
  isSignIn: PropTypes.bool,
};

DownloadUploader.defaultProps = {
  hasMobileCompatibleDevice: false,
  language: undefined,
  isGlookoResearchSite: false,
  isSignIn: false,
  onCompletedEnrollment: () => {},
};


export default withTranslate(DownloadUploader);
