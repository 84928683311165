import React from 'react';
import PropTypes from 'prop-types';
import images from '~/utils/ImageStore';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { APPLE_STORE_GLOOKO_APP, GOOGLE_PLAY_GLOOKO_APP } from '~/utils/links';
import { getAppleBadgeImage, getGoogleBadgeImage } from '~/utils/appBadges';
import AppLinkSender from '~/EnrollmentWizard/AppLinkSender/AppLinkSender.jsx';
import './style.sass';

export const DownloadUploaderMobile = (props) => {
  const { language, t } = props;

  const getAppDownloadUrl = (os) => {
    if (os === 'ios') {
      return APPLE_STORE_GLOOKO_APP;
    }
    return GOOGLE_PLAY_GLOOKO_APP;
  };

  const onDownloadClicked = () => {
    props.logEvent(
      'en_join_downloaded_mobile_app',
      { linked_app: 'glooko' }, // eslint-disable-line camelcase
    );
  };

  return (
    <div>
      <div className="DownloadUploaderMobile">
        <div className="DownloadUploaderMobile-Image" >
          <img src={images.downloadMobile} alt="" />
        </div>
        <div className="DownloadUploaderMobile-Main" >
          <div className="DownloadUploaderMobile-header" >
            {t('mobileDownloadHeader')}
          </div>
          <p>
            {t('mobileAppDescription')}
          </p>
          <p>
            {t('mobileAppDescriptionExtra')}
          </p>
          <div className="DownloadUploaderMobile-Stores" >
            <div className="DownloadUploaderMobile-AppStoreBadge" >
              <a
                href={getAppDownloadUrl('ios')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onDownloadClicked('ios')}
              >
                <img
                  alt="Download from App Store"
                  src={getAppleBadgeImage(language)}
                />
              </a>
            </div>
            <div className="DownloadUploaderMobile-GooglePlayBadge" >
              <a
                href={getAppDownloadUrl('android')}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => onDownloadClicked('android')}
              >
                <img
                  alt="Get it on Google Play"
                  src={getGoogleBadgeImage(language)}
                />
              </a>
            </div>
          </div>
          <AppLinkSender
            {...props}
          />
        </div>
      </div>
    </div>
  );
};

DownloadUploaderMobile.propTypes = {
  platform: PropTypes.string,
  t: PropTypes.func.isRequired,
  language: PropTypes.string,
  logEvent: PropTypes.func,
  phoneCountry: PropTypes.string,
  countryCode: PropTypes.string,
  mobilePhone: PropTypes.string,
  receiveSms: PropTypes.bool,
  onUpdateAttribute: PropTypes.func,
};

DownloadUploaderMobile.defaultProps = {
  platform: 'ios',
  language: 'en',
  logEvent: () => {},
  mobilePhone: undefined,
  phoneCountry: undefined,
  countryCode: undefined,
  receiveSms: false,
  onUpdateAttribute: () => {},
};

export default withTranslate(DownloadUploaderMobile);
