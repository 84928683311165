import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import images from '~/utils/ImageStore';
import TextField from '../TextField/TextField';
import withTranslate from '../../shared/withTranslate/withTranslate';

import './style.sass';

export class CodeField extends Component {
  constructor() {
    super();
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.valueChange = this.valueChange.bind(this);
    this.state = {
      focused: false,
    };
  }

  valueChange(value) {
    this.props.onChange(value);
  }

  focus() {
    this.setState({ focused: true });
  }

  blur() {
    this.setState({ focused: false });
  }

  classes() {
    return classnames({
      'CodeField-field': true,
      focused: this.state.focused,
    });
  }

  renderField() {
    return (
      <div className={this.classes()}>
        <TextField
          label={this.props.t('code')}
          onBlur={this.blur}
          onFocus={this.focus}
          onChange={this.valueChange}
          value={this.props.value}
        >
          <button onClick={this.props.onMoreInfo} type="button">
            <img className="CodeField-more" alt="more-info" src={images.moreInfo} />
          </button>
        </TextField>
      </div>
    );
  }

  render() {
    return (
      <div className="CodeField">
        { this.renderField() }
      </div>
    );
  }
}

CodeField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onMoreInfo: PropTypes.func,
  value: PropTypes.string,
  t: PropTypes.func.isRequired,
};

CodeField.defaultProps = {
  onMoreInfo: undefined,
  value: undefined,
};

export default withTranslate(CodeField);
