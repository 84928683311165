/* eslint-disable */
import React from 'react';
import { connect } from 'react-redux';
import {
  onProceedToAccountInfo,
  onProceedToEmailActivation,
  onProceedToCodeActivation,
  onProceedToQRCodeActivation,
  onProceedToMinorActivation,
  onProceedToMinorParentalConsentActivation,
  onProceedToWithdrawalConsent
} from '~/store/modules/enrollmentWizard';
import { fetchSessionUser } from '~/store/modules/session';
import EnrollmentWizard from './EnrollmentWizard';

class EnrollmentWizardContainer extends React.Component {
  componentDidMount() {
    let fetchSession = true;

    switch (this.props.activationType) {
      case 'withPassword':
        this.props.onEmailActivation();
        break;
      case 'withCode':
        this.props.onCodeActivation();
        break;
      case 'qrCodeActivation':
        this.props.onQRCodeActivation();
        break;
      case 'minorActivation':
        this.props.onMinorActivation();
        fetchSession = false;
        break;
      case 'minorParentalConsentActivation':
        this.props.onMinorParentalConsentActivation();
        fetchSession = false;
        break;
      case 'withdrawalConsent':
        this.props.onWithdrawalConsent();
        fetchSession = false;
        break;
      default:
        this.props.onAccountInformation();
    }

    if (fetchSession) {
      this.props.fetchSession();
    }
  }

  render() {
    return (
      <EnrollmentWizard {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  currentStep: state.enrollmentWizard.currentStep,
  hide: false,
});

const mapDispatchToProps = dispatch => ({
  fetchSession: () => dispatch(fetchSessionUser()),
  onAccountInformation: () => dispatch(onProceedToAccountInfo()),
  onEmailActivation: () => dispatch(onProceedToEmailActivation()),
  onCodeActivation: () => dispatch(onProceedToCodeActivation()),
  onQRCodeActivation: () => dispatch(onProceedToQRCodeActivation()),
  onMinorActivation: () => dispatch(onProceedToMinorActivation()),
  onMinorParentalConsentActivation: () => dispatch(onProceedToMinorParentalConsentActivation()),
  onWithdrawalConsent: () => dispatch(onProceedToWithdrawalConsent())
});

export default connect(mapStateToProps, mapDispatchToProps)(EnrollmentWizardContainer);
