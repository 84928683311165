import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import TextField from '~/shared/TextField/TextField.jsx';
import { isOfAge } from '~/utils/dateUtils';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { emailValid } from
  '..//validations/accountInformation';
import './style.sass';

export class GuardianInfo extends React.Component {
  constructor(props) {
    super(props);
    this.onCheckedChange = this.onCheckedChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      notifiedOnce: false,
    };
  }

  onCheckedChange(e) {
    this.props.onChange({
      ...this.props.values, [e.target.value]: e.target.checked,
    });
  }

  onChangeValue(value, attribute) {
    const updatedValue = { ...this.props.values };
    updatedValue[attribute] = value;
    this.props.onChange(updatedValue);
  }

  closeModal() { this.setState({ notifiedOnce: true }); }

  touch() {
    const { dateOfBirth, majorityAge } = this.props;
    if (moment.isMoment(dateOfBirth) && !isOfAge(dateOfBirth, majorityAge)) {
      this.guardianEmailRef.touch();
      this.guardianFirstNameRef.touch();
      this.guardianLastNameRef.touch();
    }
  }

  isValid() {
    const { dateOfBirth, majorityAge, values } = this.props;
    return isOfAge(dateOfBirth, majorityAge) || !!(values.guardianEmail && values.termsOfUse);
  }

  render() {
    const {
      validateDob, dateOfBirth, majorityAge, t, values, isNewEmailNotification,
    } = this.props;

    // we will only display if not of certain age
    if (validateDob &&
        (!moment.isMoment(dateOfBirth) || !majorityAge || isOfAge(dateOfBirth, majorityAge))) {
      return null;
    }

    return (
      <div>
        { !isNewEmailNotification &&
        <div className="GuardianInfo">
          <div className="GuardianInfo-header">{t('header')}</div>
          <div className="GuardianInfo-details">{t('whyRequire')}</div>
          <TextField
            label={t('guardianFirstName')}
            onChange={(v) => { this.onChangeValue(v, 'guardianFirstName'); }}
            value={values.guardianFirstName}
            ref={(el) => { this.guardianFirstNameRef = el; }}
            required
          />
          <TextField
            label={t('guardianLastName')}
            onChange={(v) => { this.onChangeValue(v, 'guardianLastName'); }}
            value={values.guardianLastName}
            ref={(el) => { this.guardianLastNameRef = el; }}
            required
          />
          <TextField
            isValid={emailValid}
            label={t('gaurdianEmail')}
            onChange={(v) => { this.onChangeValue(v, 'guardianEmail'); }}
            value={values.guardianEmail}
            ref={(el) => { this.guardianEmailRef = el; }}
            required
          />
          <div className="GuardianInfo-note">
            {t('guardianNote')}
          </div>
          <hr className="GuardianInfo-border" />
        </div>
  }
        { isNewEmailNotification &&
        <div className="GuardianInfo">
          <div className="GuardianInfo-header">{t('header')}</div>
          <TextField
            label={t('firstName')}
            onChange={(v) => { this.onChangeValue(v, 'guardianFirstName'); }}
            value={values.guardianFirstName}
            ref={(el) => { this.guardianFirstNameRef = el; }}
            required
            placeholder={t('guardianFirstName')}
          />
          <TextField
            label={t('lastName')}
            onChange={(v) => { this.onChangeValue(v, 'guardianLastName'); }}
            value={values.guardianLastName}
            ref={(el) => { this.guardianLastNameRef = el; }}
            required
            placeholder={t('guardianLastName')}
          />
          <TextField
            isValid={emailValid}
            label={t('email')}
            onChange={(v) => { this.onChangeValue(v, 'guardianEmail'); }}
            value={values.guardianEmail}
            ref={(el) => { this.guardianEmailRef = el; }}
            required
            placeholder={t('gaurdianEmail')}
          />
          <div className="GuardianInfo-note">
            {t('guardianNote')}
          </div>
          <hr className="GuardianInfo-border" />
        </div>
  }
        <Dialog
          size="small"
          open={validateDob && !isOfAge(dateOfBirth, majorityAge) && !this.state.notifiedOnce}
          onClose={this.closeModal}
          dataAttributes={{ testid: 'guardian-info' }}
        >
          <DialogHeader dataAttributes={{ testid: 'guardian-info' }}><h2>{t('signingUpAsMinorTitle')}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'guardian-info' }}>
            <p className="GuardianInfo-modal-detail">
              { t('signingUpAsMinorDetail') }
            </p>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'guardian-info' }}>
            <Button onClick={this.closeModal} dataAttributes={{ testid: 'guardian-info-ok' }} >{t('ok')}</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

GuardianInfo.propTypes = {
  values: PropTypes.object,
  validateDob: PropTypes.bool,
  dateOfBirth: momentPropTypes.momentObj,
  majorityAge: PropTypes.number,
  onChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  isNewEmailNotification: PropTypes.bool,
};

GuardianInfo.defaultProps = {
  values: {
    guardianEmail: '',
    guardianFirstName: '',
    guardianLastName: '',
    consent: false,
  },
  validateDob: true,
  dateOfBirth: null,
  majorityAge: undefined,
  onChange: () => {},
  isNewEmailNotification: false,
};

export default withTranslate(GuardianInfo);
