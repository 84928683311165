import getHistory from 'react-router-global-history';
import queryString from 'query-string';

export const NAV_PATHS = {
  Uploader: '/uploader',
  Activate: '/activate',
  Password: '/set_password',
  QRCodeActivation: '/qr_code_activation',
  minorActivate: '/minor_activate',
  minorParentalConsent: '/minor_capture_consents',
  withdrawConsent: '/withdraw_parental_consent',
};

export const navigate = path => getHistory().push(path);

export const navigateToUploader = () => navigate(`${NAV_PATHS.Uploader}`);

export const checkResearchUser = () => queryString.parse(window.location.search).isResearch === 'true';

export const disableMobileActivation = () => checkResearchUser();

export const checkProfessional = () => queryString.parse(window.location.search).professional === 'true';

export const getMajorityAge = () => queryString.parse(window.location.search).majorityAge;

export const getLocaleQueryParam = () => queryString.parse(window.location.search).locale;

export const getPartner = () => queryString.parse(window.location.search).partner;

export const getCid = () => queryString.parse(window.location.search).cid;

export const getRedirectUrl = () => queryString.parse(window.location.search).redirectUrl;

export const getRedirectTo = () => queryString.parse(window.location.search).redirect_to;

export const getCountry = () => queryString.parse(window.location.search).country;

export const getLanguage = () => queryString.parse(window.location.search).language;

export const getUserEncryptedDetailsFromURL = () => queryString.parse(window.location.search);

export function getMyGlookoUrl() {
  let webAppUrl = '';
  if (window.countriesWithApiUrls) {
    const { countrySelected, countriesList } = window.countriesWithApiUrls;
    const countryData = countriesList.filter(country => country.countryCode === countrySelected);
    [webAppUrl] = countryData.map(entry => entry.urls.webApp);
  }
  return webAppUrl || MY_GLOOKO_URL; // eslint-disable-line
}
