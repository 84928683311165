import React from 'react';
import i18next from '~/utils/i18n/i18n';

const withTranslate = (Component) => {
  const t = i18next.getFixedT(null, Component.name);

  class WrappedComponent extends React.Component {
    render() {
      const { forwardedRef, ...rest } = this.props; // eslint-disable-line react/prop-types
      return <Component {...rest} t={t} ref={forwardedRef} />;
    }
  }
  return React.forwardRef((props, ref) =>
    <WrappedComponent {...props} forwardedRef={ref} />);
};

export default withTranslate;
