import React from 'react';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import withTranslate from '../../shared/withTranslate/withTranslate';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import { fieldValidators, therapyValid } from
  './validations/therapy';
import MedsSelectContainer from
  './MedsSelect/MedsSelectContainer';
import MedsSummaryContainer from './MedsSummary/MedsSummaryContainer';
import './style.sass';

const formFields = [
  'selectedMedications',
];

export class Therapy extends React.Component {
  constructor() {
    super();
    this.onSubmitTherapy = this.onSubmitTherapy.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onSubmitTherapy(e) {
    if (this.props.isValid || (e.shiftKey && e.altKey)) {
      this.submitTherapy();
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !fieldValidators[field](this.props) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  submitTherapy() {
    this.props.onContinue();
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref && ref.touch) { ref.touch(); }
    });
  }

  render() {
    const {
      isValid, t,
    } = this.props;

    return (
      <div className="Therapy">
        <div className="Therapy-header">
          { t('medicationsSelectionHeader') }
          <div className="Therapy-subHeader">
            { t('medicationsSelectionSubHeader') }
          </div>
        </div>
        <MedsSelectContainer
          isValid={() => therapyValid(this.props)}
          refs={(el) => {
            this.selectedMedicationsRef = el ? el.getWrappedInstance() : el;
          }}
        />
        <MedsSummaryContainer />
        <EnrollmentWizardNavigation
          canContinue={isValid}
          onContinue={this.onSubmitTherapy}
          primaryLabel={t('next')}
          showContinue
        />
      </div>
    );
  }
}

Therapy.propTypes = {
  isValid: PropTypes.bool,
  onContinue: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

Therapy.defaultProps = {
  isValid: false,
};

export default withTranslate(Therapy);
