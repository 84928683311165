import ExecuteApiCall from './apiClient';

export function fetchAllDiabetesDevices(queryString) {
  const url = queryString ?
    `api/v2/reference/devices${queryString}` :
    'api/v2/reference/devices';

  return ExecuteApiCall('get', url);
}

export function saveSelectedDiabetesDevices(selectedDevices) {
  return ExecuteApiCall('put', 'api/v2/generic_enrollment/selected_devices', selectedDevices);
}
