import { fetchAllCountries } from '~/services/countriesService';

// ACTIONS
export const COUNTRIES_REQUESTED =
  'countries/COUNTRIES_REQUESTED';
export const COUNTRIES_RECEIVED =
  'countries/COUNTRIES_RECEIVED';

const initialState = {
  language: undefined,
  countriesFetchStatus: undefined,
  allCountries: [],
};

// REDUCER
export default (state = initialState, action) => {
  switch (action.type) {
    case COUNTRIES_REQUESTED:
      return {
        ...state,
        countriesFetchStatus: 'loading',
        language: undefined,
      };
    case COUNTRIES_RECEIVED:
      return {
        ...state,
        countriesFetchStatus: action.status,
        language: action.language,
        allCountries: [].concat(action.allCountries),
      };
    default:
      return state;
  }
};

// ACTION CREATORS

// thunk action creator
export const fetchCountries = lang =>
  (dispatch, getState) => {
    const { language } = getState().countries;

    // we will return if cached already
    if (lang === language) {
      return;
    }

    dispatch({ type: COUNTRIES_REQUESTED });

    fetchAllCountries(lang)
      .then((res) => {
        dispatch({
          type: COUNTRIES_RECEIVED,
          status: 'success',
          language: lang,
          allCountries: res.data.countries,
        });
      })
      .catch(() => {
        dispatch({
          type: COUNTRIES_RECEIVED,
          status: 'failure',
          language: undefined,
          allCountries: [],
        });
      });
  };
