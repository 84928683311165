import { connect } from 'react-redux';
import DiabetesDeviceSummary from './MedsSummary';

const mapStateToProps = state => ({
  medications: state.therapy.allMedications,
  selected: state.therapy.selectedMedications,
  language: state.language.language,
});

export default connect(mapStateToProps)(DiabetesDeviceSummary);
