import { uniq, compact, find } from 'lodash';

const PLATFORM_TO_DEVICE_ATTR = {
  android: 'shopifyProductAndroid',
  androidUsbC: 'shopifyProductAndroidUsbC',
  ios: 'shopifyProductIos',
  none: 'shopifyProductUploader',
};

export const hasVariant = (product, variantId) =>
  product.variantId === variantId;

export const isHardware = p => p && p.sponsorships.length > 0;

export const getVariantIds = (platform, devices, chargingCord) =>
  uniq(compact(devices.map((d) => {
    let platformWithCable = platform;
    if (platform === 'android' && chargingCord === 'usbC') {
      platformWithCable = 'androidUsbC';
    }
    return d[PLATFORM_TO_DEVICE_ATTR[platformWithCable]];
  })));

export const isHardwareWithVariant = (product, variantId) =>
  hasVariant(product, variantId) && isHardware(product);

export const productsFromVariantIds = (ids, products) =>
  compact(ids.map(id => find(products, p => isHardwareWithVariant(p, id))));

export const deviceForVariant = (devices, platform, variantId, chargingCord) => {
  let platformWithCable = platform;
  if (platform === 'android' && chargingCord === 'usbC') {
    platformWithCable = 'androidUsbC';
  }
  return devices.find(d => d[PLATFORM_TO_DEVICE_ATTR[platformWithCable]] === variantId);
};

export const productByVariant = (variantId, products) =>
  products.find(p => p.variantId === variantId);

export const checkoutUrl = (variantIds, locale) => {
  const checkout = variantIds.map(id => `${id}:1`).join(',');
  const localeString = locale ? `?locale=${locale}` : '';
  return `${GLOOKO_SHOPIFY_URL}/cart/${checkout}${localeString}`; // eslint-disable-line no-undef
};
