import i18next from 'i18next';
import { sortBy } from 'lodash';
import {
  CA_COUNTRY_CODE, DE_COUNTRY_CODE, US_COUNTRY_CODE,
  GR_COUNTRY_CODE, HR_COUNTRY_CODE, TR_COUNTRY_CODE,
  GB_COUNTRY_CODE, FR_COUNTRY_CODE, ES_COUNTRY_CODE,
  SE_COUNTRY_CODE, NL_COUNTRY_CODE, DK_COUNTRY_CODE,
  IT_COUNTRY_CODE, FI_COUNTRY_CODE, PL_COUNTRY_CODE,
  NO_COUNTRY_CODE, IE_COUNTRY_CODE, IL_COUNTRY_CODE,
  MT_COUNTRY_CODE, CZ_COUNTRY_CODE, HU_COUNTRY_CODE,
  CH_COUNTRY_CODE, AU_COUNTRY_CODE, NZ_COUNTRY_CODE,
  CY_COUNTRY_CODE, BG_COUNTRY_CODE, AT_COUNTRY_CODE,
  SK_COUNTRY_CODE, KW_COUNTRY_CODE, QA_COUNTRY_CODE,
  ZA_COUNTRY_CODE, SA_COUNTRY_CODE, BE_COUNTRY_CODE,
  IS_COUNTRY_CODE, LU_COUNTRY_CODE, EE_COUNTRY_CODE,
  PT_COUNTRY_CODE, AE_COUNTRY_CODE,
} from './constants/countryCodes';
import { getCountry } from './navigation';

const EU_COUNTRIES = ['deu', 'grc', 'fra', 'esp', 'fin', 'svk', 'nld', 'swe',
  'dnk', 'lux', 'aut', 'bel', 'prt', 'are'];
const IRELAND = 'irl';
const ICELAND = 'isl';
const CANADA = 'can';
const CROATIA = 'hrv';
const TURKEY = 'tur';
const GB = 'gbr';
const POLAND = 'pol';
const CZECH = 'cze';
const NORWAY = 'nor';
const SOUTH_AFRICA = 'zaf';
const AUSTRALIA = 'aus';
const SWITZERLAND = 'che';

export function getSupportedCountries(countries) {
  const t = i18next.getFixedT(null, 'supportedCountries');
  const filteredCountries = getCountry()
    ? countries.filter(country => (
      country.countryCode === getCountry()
    ))
    : countries;

  const allCountries = filteredCountries.map(c => (
    ({
      value: c.countryCode,
      displayValue: c.country,
    })
  ));
  const allCountriesSorted = sortBy(allCountries, 'displayValue');

  // add placeholder
  allCountriesSorted.unshift({ value: '', displayValue: t('placeholder') });

  return allCountriesSorted;
}

export const codesByCountry = {
  are: AE_COUNTRY_CODE,
  aus: AU_COUNTRY_CODE,
  aut: AT_COUNTRY_CODE,
  bel: BE_COUNTRY_CODE,
  bgr: BG_COUNTRY_CODE,
  can: CA_COUNTRY_CODE,
  che: CH_COUNTRY_CODE,
  cyp: CY_COUNTRY_CODE,
  cze: CZ_COUNTRY_CODE,
  deu: DE_COUNTRY_CODE,
  dnk: DK_COUNTRY_CODE,
  esp: ES_COUNTRY_CODE,
  est: EE_COUNTRY_CODE,
  fin: FI_COUNTRY_CODE,
  fra: FR_COUNTRY_CODE,
  gbr: GB_COUNTRY_CODE,
  grc: GR_COUNTRY_CODE,
  hrv: HR_COUNTRY_CODE,
  hun: HU_COUNTRY_CODE,
  isl: IS_COUNTRY_CODE,
  irl: IE_COUNTRY_CODE,
  isr: IL_COUNTRY_CODE,
  ita: IT_COUNTRY_CODE,
  kwt: KW_COUNTRY_CODE,
  lux: LU_COUNTRY_CODE,
  mlt: MT_COUNTRY_CODE,
  nld: NL_COUNTRY_CODE,
  nor: NO_COUNTRY_CODE,
  nzl: NZ_COUNTRY_CODE,
  pol: PL_COUNTRY_CODE,
  prt: PT_COUNTRY_CODE,
  qat: QA_COUNTRY_CODE,
  sau: SA_COUNTRY_CODE,
  svk: SK_COUNTRY_CODE,
  swe: SE_COUNTRY_CODE,
  tur: TR_COUNTRY_CODE,
  usa: US_COUNTRY_CODE,
  zaf: ZA_COUNTRY_CODE,
};

export const languageToCountry = {
  de: 'deu',
  en: 'usa',
  'fr-CA': 'can',
  el: 'grc',
  hr: 'hrv',
  tr: 'tur',
  'en-GB': 'gbr',
  fr: 'fra',
  es: 'esp',
  'pt-PT': 'prt',
  ar: 'sau',
};

export const countryToIso2 = {
  are: 'ae',
  aus: 'au',
  aut: 'at',
  bel: 'be',
  bgr: 'bg',
  che: 'ch',
  cyp: 'cy',
  cze: 'cz',
  can: 'ca',
  deu: 'de',
  dnk: 'dk',
  esp: 'es',
  est: 'ee',
  fin: 'fi',
  fra: 'fr',
  gbr: 'gb',
  grc: 'gr',
  hrv: 'hr',
  hun: 'hu',
  irl: 'ie',
  isl: 'is',
  isr: 'il',
  ita: 'it',
  kwt: 'kw',
  lux: 'lu',
  mlt: 'mt',
  nld: 'nl',
  nor: 'no',
  nzl: 'nz',
  pol: 'pl',
  prt: 'pt',
  qat: 'qa',
  sau: 'sa',
  svk: 'sk',
  swe: 'se',
  tur: 'tr',
  usa: 'us',
  zaf: 'za',
};

export const priceInCountry = (country, price) => {
  const lowerCountry = (country || '').toLowerCase();
  if (lowerCountry === CROATIA) { return `${price.toFixed(2)}KN`; }
  if (lowerCountry === POLAND) { return `${price.toFixed(2)}zł`; }
  if (lowerCountry === NORWAY) { return `${price.toFixed(2)}kr`; }
  if (lowerCountry === TURKEY) { return `${price.toFixed(2)}TRY`; }
  if (lowerCountry === CZECH) { return `${price.toFixed(2)}CZK`; }
  if (lowerCountry === GB) { return `₤${price.toFixed(2)}`; }
  if (lowerCountry === IRELAND || lowerCountry === ICELAND) { return `€${price.toFixed(2)} EUR`; }
  if (EU_COUNTRIES.includes(lowerCountry)) { return `${price.toFixed(2)}€`; }
  if (lowerCountry === CANADA) { return `${price.toFixed(2)} $ CAD`; }
  if (lowerCountry === SOUTH_AFRICA) { return `R ${price.toFixed(2)}`; }
  if (lowerCountry === AUSTRALIA) { return `AU$${price.toFixed(2)}`; }
  if (lowerCountry === SWITZERLAND) { return `${price.toFixed(2)} CHF`; }

  return `$${price.toFixed(2)}`;
};

export const getMajorityAge = (country, allCountries) => (
  country ? allCountries.find(c => c.countryCode === country).majorityAge : null);

export const getUnitOfMeasure = (country, allCountries) => (
  country ? allCountries.find(c => c.countryCode === country).unitOfMeasure : 'mgdl');

