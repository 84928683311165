import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ReactRouterGlobalHistory } from 'react-router-global-history';
import { NAV_PATHS } from '~/utils/navigation';
import EnrollmentWizardContainer from
  '../EnrollmentWizard/EnrollmentWizardContainer';
import DownloadUploaderContainer from
  '../EnrollmentWizard/DownloadUploader/DownloadUploaderContainer.jsx';

const Main = () => (
  <div className="Main">
    <ReactRouterGlobalHistory />
    <Switch>
      <Route exact path={NAV_PATHS.Uploader} component={DownloadUploaderContainer} />
      <Route
        exact
        path={NAV_PATHS.Password}
        render={() => (
          <EnrollmentWizardContainer activationType="withPassword" />
        )}
      />
      <Route
        exact
        path={NAV_PATHS.Activate}
        render={() => (
          <EnrollmentWizardContainer activationType="withCode" />
        )}
      />
      <Route
        exact
        path={NAV_PATHS.QRCodeActivation}
        render={() => (
          <EnrollmentWizardContainer activationType="qrCodeActivation" />
        )}
      />
      <Route
        exact
        path={NAV_PATHS.minorActivate}
        render={() => (
          <EnrollmentWizardContainer activationType="minorActivation" />
        )}
      />
      <Route
        exact
        path={NAV_PATHS.minorParentalConsent}
        render={() => (
          <EnrollmentWizardContainer activationType="minorParentalConsentActivation" />
        )}
      />
      <Route
        exact
        path={NAV_PATHS.withdrawConsent}
        render={() => (
          <EnrollmentWizardContainer activationType="withdrawalConsent" />
        )}
      />
      <Route component={EnrollmentWizardContainer} />
    </Switch>
  </div>
);

export default Main;
