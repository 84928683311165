import React from 'react';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import { sortBy } from 'lodash';
import Select from '~/shared/Select/Select.jsx';
import TextField from '~/shared/TextField/TextField.jsx';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import images from '~/utils/ImageStore';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import { fieldValidators } from './validations/shippingInfo';
import './style.sass';

const formFields = [
  'country',
  'line1',
  'zipcode',
  'city',
  'state',
];

export class ShippingInfo extends React.Component {
  constructor() {
    super();
    this.onContinue = this.onContinue.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchCountries(this.props.language);
    this.props.fetchEligibilityAddress(this.props.country);
    if (this.addressLine1Ref) {
      this.addressLine1Ref.focus();
    }
  }

  onContinue() {
    if (!this.props.isValid) {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !fieldValidators[field](this.props[field], this.props) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    } else {
      this.props.saveShippingInfo();
    }
  }

  getRegions() {
    const { allCountries, country } = this.props;

    if (allCountries.length === 0 || !country) {
      return [];
    }
    const selectedCountry = allCountries.find(c => c.countryCode === country);
    if (!selectedCountry) {
      return [];
    }
    return selectedCountry.subregions;
  }

  getCountrySelections() {
    const allCountries = this.props.allCountries.map(c => (
      ({
        value: c.countryCode,
        displayValue: c.country,
      })
    ));
    const allCountriesSorted = sortBy(allCountries, 'displayValue');
    if (!allCountries.find(c => c.value === this.props.country)) {
      // add placeholder
      allCountriesSorted.unshift({ value: '', displayValue: this.props.t('countryPlaceholder') });
    }
    return allCountriesSorted;
  }

  getRegionSelections() {
    const allRegions = this.getRegions().map(s => (
      ({
        value: s.code,
        displayValue: s.name,
      })
    ));
    // add placeholder
    allRegions.unshift({ value: '', displayValue: this.props.t('statePlaceholder') });
    return allRegions;
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref.touch) { ref.touch(); }
    });
  }

  render() {
    const {
      isValid, line1, line2, city, state, country,
      onUpdateAttribute, zipcode, allCountries, t, isSaving,
      isFetchingAddress, onBack, savingError,
    } = this.props;

    const disableInput = isFetchingAddress;

    return (
      <div className="ShippingInfo">
        <div className="ShippingInfo-header">
          { t('shippingHeader') }
          <div className="ShippingInfo-subHeader">
            { t('shippingSubheader') }
          </div>
          <div className="ShippingInfo-headerImage">
            <img alt="" src={images.shipping} />
          </div>
        </div>
        <form className="ShippingInfo-form">
          <Select
            label={t('country')}
            onChange={
              (value) => {
                onUpdateAttribute({
                  value, attribute: 'country',
                });
                onUpdateAttribute({
                  value: '', attribute: 'state',
                });
              }
            }
            options={this.getCountrySelections()}
            ref={(el) => {
              this.countryRef = el;
            }}
            value={country}
            disabled={allCountries.length === 0}
          />
          <TextField
            label={t('addressLine1')}
            onChange={
              (value) => {
                onUpdateAttribute({ value, attribute: 'line1' });
              }
            }
            value={line1}
            ref={(el) => { this.line1Ref = el; }}
            disabled={disableInput}
            required
          />
          <TextField
            label={t('addressLine2')}
            onChange={
              value =>
                onUpdateAttribute({ value, attribute: 'line2' })
            }
            value={line2}
            ref={(el) => { this.line2Ref = el; }}
            disabled={disableInput}
          />
          <TextField
            label={t('zipCode')}
            onChange={
              value =>
                onUpdateAttribute({ value, attribute: 'zipcode' })
            }
            value={zipcode}
            ref={(el) => { this.zipcodeRef = el; }}
            disabled={disableInput}
            required
          />
          <TextField
            label={t('city')}
            onChange={
              value =>
                onUpdateAttribute({ value, attribute: 'city' })
            }
            value={city}
            ref={(el) => { this.cityRef = el; }}
            disabled={disableInput}
            required
          />
          <Select
            label={t('state')}
            onChange={
              value => onUpdateAttribute({
                value, attribute: 'state',
              })
            }
            options={this.getRegionSelections()}
            ref={(el) => {
              this.stateRef = el;
            }}
            value={state}
            disabled={!country}
          />
          {savingError &&
            <div className="ShippingInfo-form-error">
              {t('savingError')}
            </div>
          }
          <EnrollmentWizardNavigation
            canContinue={isValid && !isSaving}
            canGoBack
            onGoBack={onBack}
            onContinue={this.onContinue}
            primaryLabel={!isSaving ? t('next') : t('saving')}
            secondaryLabel={t('back')}
            showContinue
            showBack
          />
        </form>
      </div>
    );
  }
}

ShippingInfo.propTypes = {
  allCountries: PropTypes.arrayOf(PropTypes.object),
  country: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  zipcode: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  isValid: PropTypes.bool,
  isFetchingAddress: PropTypes.bool,
  isSaving: PropTypes.bool,
  savingError: PropTypes.bool,
  fetchCountries: PropTypes.func,
  fetchEligibilityAddress: PropTypes.func,
  onUpdateAttribute: PropTypes.func,
  saveShippingInfo: PropTypes.func,
  onBack: PropTypes.func,
  language: PropTypes.string,
  t: PropTypes.func.isRequired,
};

ShippingInfo.defaultProps = {
  allCountries: [],
  country: '',
  line1: '',
  line2: '',
  zipcode: '',
  city: '',
  state: '',
  isValid: false,
  isFetchingAddress: false,
  isSaving: false,
  savingError: false,
  language: 'en',
  fetchCountries: () => {},
  fetchEligibilityAddress: () => {},
  saveShippingInfo: () => {},
  onUpdateAttribute: () => {},
  onBack: () => {},
};

export default withTranslate(ShippingInfo);
