import React from 'react';
import PropTypes from 'prop-types';

import CompatibilityHeader from './CompatibilityHeader/CompatibilityHeader';
import CompatibilityRow from './CompatibilityRow/CompatibilityRow';
import withTranslate from '../../../shared/withTranslate/withTranslate';

import './style.sass';

const SyncCompatibility = (props) => {
  const {
    mobilePlatform, selectedDevices, showMobile, showUploader, t,
  } = props;

  return (
    <div className="SyncCompatibility">
      <div className="SyncCompatibility-header">
        { t('syncCompatibilityHeader') }
      </div>
      <div className="SyncCompatibility-container">
        <CompatibilityHeader
          showMobile={showMobile}
          showUploader={showUploader}
        />
        {selectedDevices.map(device => (
          <CompatibilityRow
            device={device}
            key={device.id}
            mobilePlatform={mobilePlatform}
            showMobile={showMobile}
            showUploader={showUploader}
          />
        ))}
      </div>
    </div>
  );
};

SyncCompatibility.propTypes = {
  mobilePlatform: PropTypes.string,
  selectedDevices: PropTypes.arrayOf(PropTypes.object).isRequired,
  showMobile: PropTypes.bool.isRequired,
  showUploader: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

SyncCompatibility.defaultProps = {
  mobilePlatform: 'none',
};

export default withTranslate(SyncCompatibility);
