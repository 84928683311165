import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.sass';
import { getPartner, getCid } from '../utils/navigation';
import InsuletIdleTimer from './InsuletIdleTimer/InsuletIdleTimer';

const EnrollmentWizard = (props) => {
  const classes = classnames({
    EnrollmentWizard: true, hidden: props.hide,
  });
  if (!props.currentStep) {
    return null;
  }
  const partner = getPartner();
  const cid = getCid();
  const CurrentStep = props.currentStep.container;
  return (
    <div className={classes} >
      <CurrentStep />
      {partner === 'op5' && cid && (<InsuletIdleTimer />)}
    </div>
  );
};

EnrollmentWizard.propTypes = {
  hide: PropTypes.bool,
  currentStep: PropTypes.object,
};

EnrollmentWizard.defaultProps = {
  hide: false,
  currentStep: undefined,
};

export default EnrollmentWizard;
