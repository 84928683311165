import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { therapyValid } from './validations/therapy';
import { onProceedToDeviceSelection } from '../../store/modules/enrollmentWizard';

import Therapy from './Therapy';

const mapStateToProps = state => ({
  noMedications: state.therapy.noMedications,
  selectedMedications: state.therapy.selectedMedications,
  language: state.language.language,
  isValid: therapyValid(state.therapy),
});

const mapDispatchToProps = dispatch => ({
  onContinue: () => dispatch(onProceedToDeviceSelection()),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(Therapy));
