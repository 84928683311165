import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import CheckboxListItem from './CheckboxListItem/CheckboxListItem';
import CheckboxCategoryItem from './CheckboxCategoryItem/CheckboxCategoryItem';
import './style.sass';

function compareCaseInsensitive(a, b) {
  const a1 = a.toLowerCase();
  const b1 = b.toLowerCase();
  if (a1 < b1) {
    return -1;
  } else if (a1 > b1) {
    return 1;
  }

  return 0;
}

class CheckboxList extends React.Component {
  constructor(props) {
    super(props);

    this.onCheckChange = this.onCheckChange.bind(this);
    this.compareItems = this.compareItems.bind(this);
  }

  onCheckChange(e) {
    const newCheckedState = [].concat(this.props.selected);

    if (e.target.checked) {
      newCheckedState.push(e.target.value);
    } else {
      const itemIndex = newCheckedState.indexOf(e.target.value);
      if (itemIndex !== -1) {
        newCheckedState.splice(itemIndex, 1);
      }
    }

    this.props.onSelectionChange(newCheckedState);
  }

  compareItems(a, b) {
    return compareCaseInsensitive(a[this.props.labelField], b[this.props.labelField]);
  }

  sortCategorizedList(list) {
    Object.keys(list).forEach(category => list[category].sort(this.compareItems));
  }

  categorizeList(list, categoryField) {
    const categorizedList = _.groupBy(
      list,
      item => item[categoryField],
    );
    this.sortCategorizedList(categorizedList);
    return categorizedList;
  }

  render() {
    const {
      data, idField, valueField, labelField, categoryField,
      categoryComparator, fetchItemImageUrl, selected,
    } = this.props;
    const categorizedList = this.categorizeList(data, categoryField);
    const categories = Object.keys(categorizedList).sort(categoryComparator);
    const items = [];
    categories.forEach((category) => {
      if (categoryField) {
        items.push((<CheckboxCategoryItem
          key={`category-${category}`}
          label={this.props.getCategoryDisplay(category)}
        />));
      }
      categorizedList[category].forEach(item => (
        items.push(<CheckboxListItem
          checked={selected.includes(item[idField])}
          key={item[idField]}
          value={item[valueField]}
          label={item[labelField]}
          fetchItemImageUrl={fetchItemImageUrl}
          rawData={item}
          onChange={this.onCheckChange}
        />)
      ));
    });

    return (
      <div className="CheckboxList">
        {items}
      </div>
    );
  }
}

CheckboxList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  idField: PropTypes.string.isRequired,
  valueField: PropTypes.string.isRequired,
  labelField: PropTypes.string.isRequired,
  categoryField: PropTypes.string,
  fetchItemImageUrl: PropTypes.func,
  selected: PropTypes.array,
  onSelectionChange: PropTypes.func,
  getCategoryDisplay: PropTypes.func,
  categoryComparator: PropTypes.func,
};

CheckboxList.defaultProps = {
  data: [],
  selected: [],
  categoryField: undefined,
  fetchItemImageUrl: undefined,
  onSelectionChange: () => {},
  getCategoryDisplay: categoryName => categoryName,
  categoryComparator: compareCaseInsensitive,
};

export default CheckboxList;
