import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import images from '../../../utils/ImageStore';
import withTranslate from '../../../shared/withTranslate/withTranslate';

import './style.sass';

export const PlatformButton = ({
  platform, selectedPlatform, onSelectPlatform, name,
}) => {
  const selected = selectedPlatform === platform;
  const buttonClasses = classnames({ PlatformButton: true, selected });
  const inlayClasses = classnames({ 'PlatformButton-inlay': true, selected });
  let imgSrc = platform;
  if (selected) { imgSrc = `${imgSrc}Selected`; }
  return (
    <div className={buttonClasses}>
      <button onClick={() => onSelectPlatform(platform)} type="button">
        <div className={inlayClasses} />
        <img alt={platform} src={images[imgSrc]} />
      </button>
      <p>{ name }</p>
    </div>
  );
};

export class SmartPhonePlatformSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
  }

  showError() {
    return !this.props.isValid(this.props.selectedPlatform) && this.state.touched;
  }

  touch() {
    this.setState({ touched: true });
  }

  classes() {
    return classnames({
      SmartPhonePlatformSelect: true,
      error: this.showError(),
    });
  }

  render() {
    const { selectedPlatform, t } = this.props;
    return (
      <div className={this.classes()}>
        <div className="SmartPhonePlatformSelect-header">
          { t('platformSelectHeader') }
        </div>
        <div className="SmartPhonePlatformSelect-options">
          <PlatformButton platform="ios" name="iPhone" {...this.props} />
          <PlatformButton platform="android" name="Android" {...this.props} />
          <PlatformButton
            platform="none"
            name={t('noneOther')}
            {...this.props}
          />
        </div>
        {selectedPlatform === 'none' &&
          <p className="SmartPhonePlatformSelect-dont-worry">
            { t('dontWorry') }
          </p>
        }
      </div>
    );
  }
}

SmartPhonePlatformSelect.propTypes = {
  isValid: PropTypes.func.isRequired,
  onSelectPlatform: PropTypes.func.isRequired,
  selectedPlatform: PropTypes.string,
  t: PropTypes.func.isRequired,
};

SmartPhonePlatformSelect.defaultProps = {
  selectedPlatform: undefined,
};

PlatformButton.propTypes = {
  name: PropTypes.string.isRequired,
  onSelectPlatform: PropTypes.func.isRequired,
  platform: PropTypes.string.isRequired,
  selectedPlatform: PropTypes.string,
};

PlatformButton.defaultProps = {
  selectedPlatform: undefined,
};

export default withTranslate(SmartPhonePlatformSelect);
