import React from 'react';
import PropTypes from 'prop-types';

import SyncCompatibility from './SyncCompatibility/SyncCompatibility';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import HardwareSelectionContainer from
  './HardwareSelection/HardwareSelectionContainer';
import withTranslate from '../../shared/withTranslate/withTranslate';

import { hasSelectedMobileCompatibleDevice, hasUploaderCompatibleDevice } from
  '../../utils/deviceCompatibility';

import './style.sass';

const showUploader = selectedDevices =>
  hasUploaderCompatibleDevice(selectedDevices);

const showMobile = (platform, devices) =>
  hasSelectedMobileCompatibleDevice(platform, devices);

class WaysToSync extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const {
      isValid, t, selectedDevices, mobilePlatform, onBack, onContinue,
    } = this.props;

    return (
      <div className="WaysToSync">
        <div className="WaysToSync-header">{ t('waysToSyncHeader') }</div>
        <div className="WaysToSync-subheader">{ t('waysToSyncSubheader') }</div>
        <SyncCompatibility
          mobilePlatform={mobilePlatform}
          selectedDevices={selectedDevices}
          showMobile={showMobile(mobilePlatform, selectedDevices)}
          showUploader={showUploader(selectedDevices)}
        />
        <HardwareSelectionContainer />
        <EnrollmentWizardNavigation
          showBack
          canGoBack
          secondaryLabel={t('back')}
          onGoBack={onBack}
          canContinue={isValid}
          onContinue={onContinue}
          primaryLabel={t('next')}
          showContinue
        />
      </div>
    );
  }
}

WaysToSync.propTypes = {
  isValid: PropTypes.bool,
  mobilePlatform: PropTypes.string,
  selectedDevices: PropTypes.arrayOf(PropTypes.object),
  onBack: PropTypes.func,
  onContinue: PropTypes.func,
  t: PropTypes.func.isRequired,
};

WaysToSync.defaultProps = {
  mobilePlatform: 'none',
  selectedDevices: [],
  isValid: true,
  onBack: () => {},
  onContinue: () => {},
};

export default withTranslate(WaysToSync);
