import { connect } from 'react-redux';
import { fetchMedications, onSelectMedications, onFilterMedications, onSelectNoMedications }
  from '~/store/modules/therapy';
import MedsSelect from './MedsSelect';

const isValidMedication = medication => medication.brand;

const getVisibleDiabetesDevices = medications => (
  medications.filter(medication => isValidMedication(medication))
);

const mapStateToProps = state => ({
  noMedications: state.therapy.noMedications,
  medications: getVisibleDiabetesDevices(
    state.therapy.allMedications,
    state.therapy.medicationsFilter,
  ),
  selectedMedications: state.therapy.selectedMedications,
  filter: state.therapy.medicationsFilter,
  isLoading: state.therapy.medsFetchStatus === 'loading',
  language: state.language.language,
});

const mapDispatchToProps = dispatch => ({
  fetchMedications: () => dispatch(fetchMedications()),
  onSelectionChange: selection => dispatch(onSelectMedications(selection)),
  onFilterChange: filter => dispatch(onFilterMedications(filter)),
  onNoMedicationsChange: selection => dispatch(onSelectNoMedications(selection)),
});

export default
connect(
  mapStateToProps, mapDispatchToProps,
  null, { withRef: true },
)(MedsSelect);
