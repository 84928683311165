import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import i18next from 'i18next';
import i18nCountries from 'i18n-iso-countries';
import _ from 'lodash';
import { allCountries } from 'country-telephone-data';
import Images from '../../utils/ImageStore';
import './style.sass';
import { registerLocales } from '../../../src/utils/i18n/i18nIsoCountries';

const getFlagStyle = () => ({ backgroundImage: `url(${Images.flags})` });
const getCaretStyle = (listOpen) => {
  const image = listOpen ? Images.arrowDown : Images.arrowDown;
  return { backgroundImage: `url(${image})` };
};

export const sortedCountries = (selected, preferred = []) => {
  const first = _.union([selected], preferred);
  let codes = allCountries.map(c => c.iso2);
  codes = _.union(first, codes);
  return _.map(codes, c => allCountries.find(x => x.iso2 === c));
};

const languageObjectMapping = {
  'en-gb': 'en',
  'en-us': 'en',
  en: 'en',
  de: 'de',
  'fr-ca': 'fr',
  el: 'el',
  'es-us': 'es',
  tr: 'tr',
  hr: 'hr',
  fr: 'fr',
  es: 'es',
  it: 'it',
  fi: 'fi',
  sk: 'sk',
  pl: 'pl',
  nl: 'nl',
  sv: 'sv',
  da: 'da',
  cs: 'cs',
  'nb-no': 'nb',
  'pt-pt': 'pt',
};

export const getLocalizeName = (countryISO2) => {
  const languageCode = languageObjectMapping[i18next.language.toLowerCase()];

  return i18nCountries.getName(countryISO2, languageCode);
};

class CountryCodeSelect extends React.Component {
  constructor() {
    super();
    this.closeList = this.closeList.bind(this);
    this.toggleList = this.toggleList.bind(this);
    this.onSelectCountry = this.onSelectCountry.bind(this);
    this.state = { listOpen: false };
    registerLocales();
  }

  onSelectCountry(country) {
    this.props.onSelectCountry(country);
    this.closeList();
  }

  closeList() {
    this.setState({ listOpen: false });
  }

  toggleList() {
    this.setState({ listOpen: !this.state.listOpen });
  }

  flagClasses() {
    return classnames({
      'CountryCodeSelect-button-flag': true,
      [this.props.selectedCountry]: true,
    });
  }

  listItemClasses(country) {
    return classnames({
      'CountryCodeSelect-list-item': true,
      selected: country === this.props.selectedCountry,
    });
  }

  buttonClasses() {
    return classnames({
      'CountryCodeSelect-button': true,
      invalid: this.props.showError,
      open: this.state.listOpen,
    });
  }

  render() {
    const { selectedCountry } = this.props;
    const preferred = this.props.preferredCountries;
    return (
      <div className="CountryCodeSelect">
        <button
          className={this.buttonClasses()}
          type="button"
          onClick={this.toggleList}
        >
          <div className={this.flagClasses()} style={getFlagStyle()} />
          <div
            className="CountryCodeSelect-button-caret"
            style={getCaretStyle(this.state.listOpen)}
          />
        </button>
        {this.state.listOpen &&
          <div className="CountryCodeSelect-list">
            {sortedCountries(selectedCountry, preferred).map(country => (
              <button
                className={this.listItemClasses(country.iso2)}
                onClick={() => this.onSelectCountry(country)}
                type="button"
                key={country.iso2}
              >
                <div className="CountryCodeSelect-list-item-name">
                  {getLocalizeName(country.iso2)}
                </div>
                <div className="CountryCodeSelect-list-item-code">
                  {`+${country.dialCode}`}
                </div>
              </button>
            ))}
          </div>
        }
      </div>
    );
  }
}

CountryCodeSelect.propTypes = {
  selectedCountry: PropTypes.string,
  onSelectCountry: PropTypes.func.isRequired,
  preferredCountries: PropTypes.arrayOf(PropTypes.string),
  showError: PropTypes.bool,
};

CountryCodeSelect.defaultProps = {
  selectedCountry: 'us',
  preferredCountries: ['us'],
  showError: false,
};

export default CountryCodeSelect;
