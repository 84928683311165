import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onUpdateAttribute, onRegister, onUpdateCountry } from
  '~/store/modules/accountInformation';
import { fetchCountries } from
  '~/store/modules/countries';

import { getSupportedCountries, getMajorityAge, getUnitOfMeasure } from '~/utils/supportedCountries';
import { getPartner } from '~/utils/navigation';
import { getPartnerInfo } from '~/utils/partners';
import { getLogInURL } from '~/utils/links';

import AccountInformation from './AccountInformation';

import { accountInformationValid } from './validations/accountInformation';

const mapStateToProps = (state) => {
  const majorityAge = getMajorityAge(
    state.accountInformation.countryOfResidence,
    state.countries.allCountries,
  );
  const defaultUnitOfMeasure = getUnitOfMeasure(
    state.accountInformation.countryOfResidence,
    state.countries.allCountries,
  );
  const unitOfMeasure = state.accountInformation.meterUnits || defaultUnitOfMeasure;

  const partner = getPartner();
  const partnerInfo = partner ? getPartnerInfo(partner) : undefined;
  const { language } = state.language;
  const loginUrl = (partnerInfo && partnerInfo.loginUrl) ?
    partnerInfo.loginUrl :
    getLogInURL(language);


  return {
    email: state.accountInformation.email,
    password: state.accountInformation.password,
    firstName: state.accountInformation.firstName,
    lastName: state.accountInformation.lastName,
    countryOfResidence: state.accountInformation.countryOfResidence,
    dateOfBirth: state.accountInformation.dateOfBirth,
    majorityAge,
    guardianInfo: state.accountInformation.guardianInfo,
    code: state.accountInformation.code,
    consents: state.accountInformation.consents,
    phoneCountry: state.accountInformation.phoneCountry,
    mobilePhone: state.accountInformation.mobilePhone,
    countryCode: state.accountInformation.countryCode,
    receiveSms: state.accountInformation.receiveSms,
    language: state.language.language,
    supportedCountries: getSupportedCountries(state.countries.allCountries),
    unitOfMeasure,
    showUnitOfMeasure: defaultUnitOfMeasure === 'mixed',
    isValid: accountInformationValid(state.accountInformation, majorityAge, defaultUnitOfMeasure),
    registerStatus: state.accountInformation.registerStatus,
    registerError: state.accountInformation.registerError,
    registerAs: state.accountInformation.registerAs,
    loginUrl,
  };
};

const mapDispatchToProps = dispatch => ({
  onRegister: () => dispatch(onRegister()),
  onUpdateAttribute: update => dispatch(onUpdateAttribute(update)),
  fetchCountries: language => dispatch(fetchCountries(language)),
  onUpdateCountry: country => dispatch(onUpdateCountry(country)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountInformation));
