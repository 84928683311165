import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { fetchParentalConsents } from '~/store/modules/partnerConsents';
import { onMinorParentalConsent } from '~/store/modules/accountInformation';
import MinorParentalConsentActivation from './MinorParentalConsentActivation';

const mapStateToProps = state => ({
  userDecryptedDetails: state.enrollmentWizard.userDecryptedDetails,
  language: state.language.language,
  registerStatus: state.accountInformation.registerStatus,
  registerError: state.accountInformation.registerError,
  consents: state.partnerConsents.consents,
  isConsentLoading: state.partnerConsents.isConsentLoading,
});

const mapDispatchToProps = dispatch => ({
  fetchParentalConsents: (userEmail, lang, partnerConsent) => {
    dispatch(fetchParentalConsents(userEmail, lang, partnerConsent));
  },
  onMinorParentalConsent: payload => dispatch(onMinorParentalConsent(payload)),
});

export default
withRouter(connect(mapStateToProps, mapDispatchToProps)(MinorParentalConsentActivation));
