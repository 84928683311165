/* eslint-disable */
export const devices = {
  "devices": [
    {
      "id": "ACCU_CHEK_AVIVA_SILVER",
      "brand": "ACCU-CHEK",
      "model": "Aviva Plus Silver",
      "name": "ACCU_CHEK_AVIVA_SILVER",
      "backwardCompatibilityName": "AccuChekAviva",
      "nameAndroid": "ACCU_CHEK_AVIVA",
      "legacyNumberIos": 9,
      "legacyNumberAndroid": 56,
      "mappedFrom": [
        "accuchekavivaplussilver",
        "accu-chek aviva plus silver",
        "accuchekaviva"
      ],
      "uploaderNames": [
        "accu-chek aviva"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.950Z",
      "updatedAt": "2018-02-06T11:19:21.641Z",
      "guid": "e6ac53be-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Plus Silver"
    },
    {
      "id": "ACCU_CHEK_COMPACT_PLUS",
      "brand": "ACCU-CHEK",
      "model": "Compact Plus",
      "name": "ACCU_CHEK_COMPACT_PLUS",
      "backwardCompatibilityName": "AccuChekCompactPlus",
      "nameAndroid": "ACCU_CHEK_COMPACT_PLUS",
      "legacyNumberIos": 10,
      "legacyNumberAndroid": 10,
      "mappedFrom": [
        "accuchekcompactplus",
        "accu-chek compact plus",
        "accu-chek - accuchekcompactplus",
        "accu_chek_compact_plus"
      ],
      "uploaderNames": [
        "*compact",
        "*compact+",
        "*compact+lcm",
        "accu-chek compact",
        "accu-chek compact plus"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.969Z",
      "updatedAt": "2018-02-06T11:19:29.340Z",
      "guid": "e6afbd4c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Compact Plus"
    },
    {
      "id": "ACCU_CHEK_NANO",
      "brand": "ACCU-CHEK",
      "model": "Nano",
      "name": "ACCU_CHEK_NANO",
      "backwardCompatibilityName": "AccuChekNano",
      "nameAndroid": "ACCU_CHEK_NANO",
      "legacyNumberIos": 14,
      "legacyNumberAndroid": 14,
      "mappedFrom": [
        "accucheknano",
        "accu-chek nano",
        "accu-chek - accucheknano",
        "accu_chek_nano"
      ],
      "uploaderNames": [
        "accu-chek nano"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.977Z",
      "updatedAt": "2018-02-06T11:20:06.077Z",
      "guid": "e6b11ae8-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Nano"
    },
    {
      "id": "ACCU_CHEK_AVIVA_NANO",
      "brand": "ACCU-CHEK",
      "model": "Aviva Nano",
      "name": "ACCU_CHEK_AVIVA_NANO",
      "backwardCompatibilityName": "AccuChekAvivaNano",
      "nameAndroid": "ACCU_CHEK_AVIVA_NANO",
      "legacyNumberIos": 13,
      "legacyNumberAndroid": 13,
      "mappedFrom": [
        "accuchekavivanano",
        "accu-chek aviva nano",
        "accu-chek - accuchekavivanano",
        "accu_chek_aviva_nano"
      ],
      "uploaderNames": [
        "accu-chek aviva nano"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.974Z",
      "updatedAt": "2018-02-06T11:18:50.400Z",
      "guid": "e6b08240-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Nano"
    },
    {
      "id": "ACCU_CHEK_GO",
      "brand": "ACCU-CHEK",
      "model": "Go",
      "name": "ACCU_CHEK_GO",
      "backwardCompatibilityName": "AccuChekGo",
      "nameAndroid": "ACCU_CHEK_GO",
      "legacyNumberIos": 39,
      "legacyNumberAndroid": 39,
      "mappedFrom": [
        "accuchekgo",
        "accu-chek go",
        "accu-chek - accuchekgo",
        "accu_chek_go"
      ],
      "uploaderNames": [
        "*go"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.982Z",
      "updatedAt": "2018-11-01T21:07:40.200Z",
      "guid": "e6b1bb1a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Go"
    },
    {
      "id": "ACCU_CHEK_PERFORMA",
      "brand": "ACCU-CHEK",
      "model": "Performa",
      "name": "ACCU_CHEK_PERFORMA",
      "backwardCompatibilityName": "AccuChekPerforma",
      "nameAndroid": "ACCU_CHEK_PERFORMA",
      "legacyNumberIos": 40,
      "legacyNumberAndroid": 40,
      "mappedFrom": [
        "accuchekperforma",
        "accu-chek performa",
        "accu-chek - accuchekperforma",
        "accu_chek_performa"
      ],
      "uploaderNames": [
        "*performa",
        "accu-chek performa"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.987Z",
      "updatedAt": "2018-02-06T11:20:13.048Z",
      "guid": "e6b276b8-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Performa"
    },
    {
      "id": "ACCU_CHEK_PERFORMA_NANO",
      "brand": "ACCU-CHEK",
      "model": "Performa Nano",
      "name": "ACCU_CHEK_PERFORMA_NANO",
      "backwardCompatibilityName": "AccuChekPerformaNano",
      "nameAndroid": "ACCU_CHEK_PERFORMA_NANO",
      "legacyNumberIos": 41,
      "legacyNumberAndroid": 41,
      "mappedFrom": [
        "accuchekperformanano",
        "accu-chek performa nano",
        "accu-chek - accuchekperformanano",
        "accu_chek_performa_nano"
      ],
      "uploaderNames": [
        "accu-chek performa nano"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.991Z",
      "updatedAt": "2018-02-06T11:21:10.724Z",
      "guid": "e6b3227a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Performa Nano"
    },
    {
      "id": "ACCU_CHEK_AVIVA_EXPERT",
      "brand": "ACCU-CHEK",
      "model": "Aviva Expert",
      "name": "ACCU_CHEK_AVIVA_EXPERT",
      "backwardCompatibilityName": "AccuChekAvivaExpert",
      "nameAndroid": "ACCU_CHEK_AVIVA_EXPERT",
      "legacyNumberIos": 42,
      "legacyNumberAndroid": 42,
      "mappedFrom": [
        "accuchekavivaexpert",
        "accu-chek aviva expert",
        "accu-chek - accuchekavivaexpert",
        "accu_chek_aviva_expert"
      ],
      "uploaderNames": [
        "*aviva e",
        "accu-chek aviva expert"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:34.997Z",
      "updatedAt": "2018-02-06T11:18:34.687Z",
      "guid": "e6b426f2-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Expert"
    },
    {
      "id": "ACCU_CHEK_AVIVA_BLACK",
      "brand": "ACCU-CHEK",
      "model": "Aviva Plus Black",
      "name": "ACCU_CHEK_AVIVA_BLACK",
      "backwardCompatibilityName": "AccuChekAvivaPlusBlack",
      "nameAndroid": "ACCU_CHEK_AVIVA_BLACK",
      "legacyNumberIos": 43,
      "legacyNumberAndroid": 43,
      "mappedFrom": [
        "accuchekavivaplusblack",
        "accu-chek aviva plus black",
        "accu-chek - accuchekavivaplusblack",
        "accu_chek_aviva_black"
      ],
      "uploaderNames": [
        "accu-chek aviva"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.002Z",
      "updatedAt": "2018-06-13T17:45:04.972Z",
      "guid": "e6b4c9e0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Plus Black"
    },
    {
      "id": "ACCU_CHEK_AVIVA_CONNECT",
      "brand": "ACCU-CHEK",
      "model": "Aviva Connect",
      "name": "ACCU_CHEK_AVIVA_CONNECT",
      "backwardCompatibilityName": "AccuChekAvivaConnect",
      "nameAndroid": "ACCU_CHEK_AVIVA_CONNECT",
      "legacyNumberIos": 44,
      "legacyNumberAndroid": 9,
      "mappedFrom": [
        "accuchekavivaconnect",
        "accu-chek aviva connect",
        "accu-chek - accuchekavivaconnect",
        "accu_chek_aviva_connect"
      ],
      "uploaderNames": [
        "*aviva c",
        "accu-chek aviva connect"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb",
        "ble"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973338706,
      "shopifyProductAndroid": 37973338706,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.007Z",
      "updatedAt": "2018-09-12T00:15:49.158Z",
      "guid": "e6b580ba-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Connect"
    },
    {
      "id": "ACCU_CHEK_PERFORMA_CONNECT",
      "brand": "ACCU-CHEK",
      "model": "Performa Connect",
      "name": "ACCU_CHEK_PERFORMA_CONNECT",
      "backwardCompatibilityName": "AccuChekPerformaConnect",
      "nameAndroid": "ACCU_CHEK_PERFORMA_CONNECT",
      "legacyNumberIos": 45,
      "legacyNumberAndroid": 60,
      "mappedFrom": [
        "accuchekperformaconnect",
        "accu-chek performa connect",
        "accu-chek - accuchekperformaconnect",
        "accu_chek_performa_connect"
      ],
      "uploaderNames": [
        "*performa c",
        "accu-chek performa connect"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb",
        "ble"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973338706,
      "shopifyProductAndroid": 37973338706,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.011Z",
      "updatedAt": "2018-02-06T11:20:52.378Z",
      "guid": "e6b63974-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Performa Connect"
    },
    {
      "id": "ACCU_CHEK_AVIVA_COMBO",
      "brand": "ACCU-CHEK",
      "model": "Aviva Combo",
      "name": "ACCU_CHEK_AVIVA_COMBO",
      "backwardCompatibilityName": null,
      "nameAndroid": "ACCU_CHEK_AVIVA_COMBO",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "accu-chek aviva combo"
      ],
      "uploaderNames": [
        "accu-chek aviva combo"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.015Z",
      "updatedAt": "2017-09-07T02:30:42.842Z",
      "guid": "e6b6e84c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Aviva Combo"
    },
    {
      "id": "ACCU_CHEK_GUIDE",
      "brand": "ACCU-CHEK",
      "model": "Guide",
      "name": "ACCU_CHEK_GUIDE",
      "backwardCompatibilityName": "AccuChekGuide",
      "nameAndroid": "ACCU_CHEK_GUIDE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "accu-chek guide",
        "accuchekguide",
        "accu_chek_guide"
      ],
      "uploaderNames": [
        "accu-chek guide"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb",
        "ble"
      ],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.3",
      "versionAndroid": "4.3",
      "versionC4cIos": "1.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 41135704905,
      "shopifyProductAndroid": 41135704905,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.021Z",
      "updatedAt": "2018-07-13T19:09:18.723Z",
      "guid": "e6b7bb5a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Guide"
    },
    {
      "id": "ANIMAS_VIBE",
      "brand": "Animas",
      "model": "Vibe",
      "name": "ANIMAS_VIBE",
      "backwardCompatibilityName": null,
      "nameAndroid": "ANIMAS_VIBE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "animas vibe"
      ],
      "uploaderNames": [
        "animas vibe"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.025Z",
      "updatedAt": "2017-09-07T02:30:42.854Z",
      "guid": "e6b85916-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Animas Vibe"
    },
    {
      "id": "ANIMAS_ONETOUCH_PING_METER",
      "brand": "Animas",
      "model": "OneTouch Ping (meter)",
      "name": "ANIMAS_ONETOUCH_PING_METER",
      "backwardCompatibilityName": null,
      "nameAndroid": "ANIMAS_ONETOUCH_PING_METER",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "animas onetouch ping (meter)"
      ],
      "uploaderNames": [
        "onetouch ping meter"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.029Z",
      "updatedAt": "2017-09-07T02:30:42.861Z",
      "guid": "e6b90410-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Animas OneTouch Ping (meter)"
    },
    {
      "id": "ANIMAS_ONETOUCH_PING_PUMP",
      "brand": "Animas",
      "model": "OneTouch Ping (pump)",
      "name": "ANIMAS_ONETOUCH_PING_PUMP",
      "backwardCompatibilityName": null,
      "nameAndroid": "ANIMAS_ONETOUCH_PING_PUMP",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "animas onetouch ping (pump)"
      ],
      "uploaderNames": [
        "onetouch ping"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.033Z",
      "updatedAt": "2017-09-07T02:30:42.866Z",
      "guid": "e6b99fc4-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Animas OneTouch Ping (pump)"
    },
    {
      "id": "ANIMAS_ONETOUCH_VIBE_PLUS",
      "brand": "Animas",
      "model": "OneTouch Vibe Plus",
      "name": "ANIMAS_ONETOUCH_VIBE_PLUS",
      "backwardCompatibilityName": "",
      "nameAndroid": "ANIMAS_ONETOUCH_VIBE_PLUS",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "animas onetouch vibe plus"
      ],
      "uploaderNames": [
        "onetouch vibe plus"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.038Z",
      "updatedAt": "2018-02-26T19:44:45.923Z",
      "guid": "e6ba3f88-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Animas OneTouch Vibe Plus"
    },
    {
      "id": "ASCENSIA_CONTOUR_NEXT_ONE",
      "brand": "Ascensia",
      "model": "CONTOUR NEXT ONE",
      "name": "ASCENSIA_CONTOUR_NEXT_ONE",
      "backwardCompatibilityName": "AscensiaContourNextOne",
      "nameAndroid": "ASCENSIA_CONTOUR_NEXT_ONE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": 61,
      "mappedFrom": [
        "ascensiacontournextone",
        "ascensia contour next one",
        "ascensiacontourone",
        "ascensia_contour_next_one"
      ],
      "uploaderNames": [
        "ascensia contour next one"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb",
        "ble"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.042Z",
      "updatedAt": "2018-10-08T22:26:40.667Z",
      "guid": "e6bae6cc-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Ascensia CONTOUR NEXT ONE"
    },
    {
      "id": "ASCENSIA_CONTOUR_PLUS_ONE",
      "brand": "Ascensia",
      "model": "CONTOUR PLUS ONE",
      "name": "ASCENSIA_CONTOUR_PLUS_ONE",
      "backwardCompatibilityName": null,
      "nameAndroid": "ASCENSIA_CONTOUR_PLUS_ONE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "ascensia contour plus one"
      ],
      "uploaderNames": [
        "ascensia contour plus one"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.045Z",
      "updatedAt": "2017-09-07T02:30:42.885Z",
      "guid": "e6bb7970-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Ascensia CONTOUR PLUS ONE"
    },
    {
      "id": "BAYER_CONTOUR",
      "brand": "Bayer",
      "model": "CONTOUR",
      "name": "BAYER_CONTOUR",
      "backwardCompatibilityName": "BayersContour",
      "nameAndroid": "BAYER_CONTOUR",
      "legacyNumberIos": 3,
      "legacyNumberAndroid": 3,
      "mappedFrom": [
        "bayerscontour",
        "bayer contour",
        "bayer - bayerscontour",
        "bayer_contour"
      ],
      "uploaderNames": [
        "bayer contour"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.050Z",
      "updatedAt": "2018-02-06T11:22:50.926Z",
      "guid": "e6bc20f0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR"
    },
    {
      "id": "BAYER_BREEZE2",
      "brand": "Bayer",
      "model": "BREEZE 2",
      "name": "BAYER_BREEZE2",
      "backwardCompatibilityName": "BayersBreeze2",
      "nameAndroid": "BAYER_BREEZE2",
      "legacyNumberIos": 11,
      "legacyNumberAndroid": 11,
      "mappedFrom": [
        "bayersbreeze2",
        "bayer breeze 2",
        "bayer - bayersbreeze2",
        "bayer_breeze2"
      ],
      "uploaderNames": [
        "ascensia breeze2"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.054Z",
      "updatedAt": "2018-02-06T11:22:43.280Z",
      "guid": "e6bcdc2a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer BREEZE 2"
    },
    {
      "id": "BAYER_CONTOUR_NEXT_EZ",
      "brand": "Bayer",
      "model": "CONTOUR NEXT EZ",
      "name": "BAYER_CONTOUR_NEXT_EZ",
      "backwardCompatibilityName": "BayersContourNextEZ",
      "nameAndroid": "BAYER_CONTOUR_NEXT_EZ",
      "legacyNumberIos": 15,
      "legacyNumberAndroid": 15,
      "mappedFrom": [
        "bayerscontournextez",
        "bayer contour next ez",
        "bayer - bayerscontournextez",
        "bayer_contour_next_ez"
      ],
      "uploaderNames": [
        "bayer contour next ez"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.058Z",
      "updatedAt": "2018-08-28T21:22:16.511Z",
      "guid": "e6bd66f4-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR NEXT EZ"
    },
    {
      "id": "BAYER_CONTOUR_USB",
      "brand": "Bayer",
      "model": "CONTOUR USB",
      "name": "BAYER_CONTOUR_USB",
      "backwardCompatibilityName": "BayersContourUsb",
      "nameAndroid": "BAYER_CONTOUR_USB",
      "legacyNumberIos": 16,
      "legacyNumberAndroid": 16,
      "mappedFrom": [
        "bayerscontourusb",
        "bayer contour usb",
        "bayer - bayerscontourusb",
        "bayer_contour_usb"
      ],
      "uploaderNames": [
        "contour usb"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339538,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.062Z",
      "updatedAt": "2018-10-18T22:38:27.694Z",
      "guid": "e6bdf1f0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR USB"
    },
    {
      "id": "BAYER_CONTOUR_XT",
      "brand": "Bayer",
      "model": "CONTOUR XT",
      "name": "BAYER_CONTOUR_XT",
      "backwardCompatibilityName": "BayersContourXT",
      "nameAndroid": "BAYER_CONTOUR_XT",
      "legacyNumberIos": 17,
      "legacyNumberAndroid": 17,
      "mappedFrom": [
        "bayerscontourxt",
        "bayer contour xt",
        "bayer - bayerscontourxt",
        "bayer_contour_xt"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.065Z",
      "updatedAt": "2018-02-06T11:27:08.217Z",
      "guid": "e6be7de6-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR XT"
    },
    {
      "id": "BAYER_CONTOUR_NEXT",
      "brand": "Bayer",
      "model": "CONTOUR NEXT",
      "name": "BAYER_CONTOUR_NEXT",
      "backwardCompatibilityName": "BayersContourNext",
      "nameAndroid": "BAYER_CONTOUR_NEXT",
      "legacyNumberIos": 36,
      "legacyNumberAndroid": 36,
      "mappedFrom": [
        "bayercontournext",
        "bayerscontournext",
        "bayer contour next",
        "bayer - bayerscontournext",
        "bayer - bayercontournext",
        "bayer_contour_next"
      ],
      "uploaderNames": [
        "contour next"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.069Z",
      "updatedAt": "2018-02-06T11:25:16.907Z",
      "guid": "e6bf0d9c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR NEXT"
    },
    {
      "id": "BAYER_CONTOUR_NEXT_USB",
      "brand": "Bayer",
      "model": "CONTOUR NEXT USB",
      "name": "BAYER_CONTOUR_NEXT_USB",
      "backwardCompatibilityName": "BayersContourNextUsb",
      "nameAndroid": "BAYER_CONTOUR_NEXT_USB",
      "legacyNumberIos": 37,
      "legacyNumberAndroid": 37,
      "mappedFrom": [
        "bayercontournextusb",
        "bayerscontournextusb",
        "bayer contour next usb",
        "bayer - bayerscontournextusb",
        "bayer - bayercontournextusb",
        "bayer_contour_next_usb"
      ],
      "uploaderNames": [
        "contour next usb"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339538,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.072Z",
      "updatedAt": "2018-09-05T20:31:55.270Z",
      "guid": "e6bf98ac-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR NEXT USB"
    },
    {
      "id": "BAYER_CONTOUR_NEXT_LINK",
      "brand": "Bayer",
      "model": "CONTOUR NEXT LINK",
      "name": "BAYER_CONTOUR_NEXT_LINK",
      "backwardCompatibilityName": "BayersContourNextLink",
      "nameAndroid": "BAYER_CONTOUR_NEXT_LINK",
      "legacyNumberIos": 38,
      "legacyNumberAndroid": 38,
      "mappedFrom": [
        "bayercontournextlink",
        "bayerscontournextlink",
        "bayer contour next link",
        "bayer - bayercontournextlink",
        "bayer - bayerscontournextlink",
        "bayer_contour_next_link"
      ],
      "uploaderNames": [
        "contour next link",
        "contour next link us"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339538,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.076Z",
      "updatedAt": "2018-02-06T11:25:27.154Z",
      "guid": "e6c0238a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR NEXT LINK"
    },
    {
      "id": "BAYER_CONTOUR_LINK",
      "brand": "Bayer",
      "model": "CONTOUR LINK",
      "name": "BAYER_CONTOUR_LINK",
      "backwardCompatibilityName": null,
      "nameAndroid": "BAYER_CONTOUR_LINK",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "bayer contour link"
      ],
      "uploaderNames": [
        "bayer contour link"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.079Z",
      "updatedAt": "2017-09-07T02:30:42.937Z",
      "guid": "e6c0acb0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Bayer CONTOUR LINK"
    },
    {
      "id": "CARESENS_N_VOICE",
      "brand": "CareSens",
      "model": "N Voice",
      "name": "CARESENS_N_VOICE",
      "backwardCompatibilityName": null,
      "nameAndroid": "CARESENS_N_VOICE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "caresens n voice"
      ],
      "uploaderNames": [
        "caresens n voice"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.086Z",
      "updatedAt": "2017-09-07T02:30:42.948Z",
      "guid": "e6c1c0aa-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "CareSens N Voice"
    },
    {
      "id": "ISENS_CARESENS_N",
      "brand": "CareSens",
      "model": "N",
      "name": "ISENS_CARESENS_N",
      "backwardCompatibilityName": "",
      "nameAndroid": "ISENS_CARESENS_N",
      "legacyNumberIos": 31,
      "legacyNumberAndroid": 31,
      "mappedFrom": [
        "isenscaresensn",
        "caresens n",
        "i-sens - isenscaresensn"
      ],
      "uploaderNames": [
        "i-sens caresens n",
        "i-sens caresens n 1000",
        "i-sens caresens n 1000 (sn-3)",
        "i-sens caresens n 500"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "",
      "versionIos": "4.3",
      "versionAndroid": "",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.090Z",
      "updatedAt": "2018-02-06T11:33:31.344Z",
      "guid": "e6c24e12-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "CareSens N"
    },
    {
      "id": "ISENS_CARESENS_N_POP",
      "brand": "CareSens",
      "model": "N POP",
      "name": "ISENS_CARESENS_N_POP",
      "backwardCompatibilityName": "",
      "nameAndroid": "ISENS_CARESENS_N_POP",
      "legacyNumberIos": 33,
      "legacyNumberAndroid": 33,
      "mappedFrom": [
        "caresensnpop",
        "caresens n pop",
        "i-sens - caresensnpop"
      ],
      "uploaderNames": [
        "caresens n pop 1000",
        "caresens n pop 500",
        "i-sens caresens n pop 1000 (sn-3)"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "",
      "versionIos": "4.3",
      "versionAndroid": "",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.094Z",
      "updatedAt": "2018-02-06T11:33:35.277Z",
      "guid": "e6c2dc74-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "CareSens N POP"
    },
    {
      "id": "ALPHACHECK_PROFESSIONAL",
      "brand": "CareSens",
      "model": "Alphacheck Professional",
      "name": "ALPHACHECK_PROFESSIONAL",
      "backwardCompatibilityName": "",
      "nameAndroid": "ALPHACHECK_PROFESSIONAL",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "caresens alphacheck professional"
      ],
      "uploaderNames": [
        "i-sens alphacheck professional nfc / acura plus",
        "alphacheck professional"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "mini_usb"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.098Z",
      "updatedAt": "2018-02-26T19:36:44.072Z",
      "guid": "e6c374e0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "CareSens Alphacheck Professional"
    },
    {
      "id": "DEXCOM_G4",
      "brand": "dexcom",
      "model": "G4",
      "name": "DEXCOM_G4",
      "backwardCompatibilityName": "DexcomG4",
      "nameAndroid": "DEXCOM_G4",
      "legacyNumberIos": 1000,
      "legacyNumberAndroid": 52,
      "mappedFrom": [
        "dexcomg4",
        "dexcom - g4",
        "dexcom g4",
        "dexcom - share2",
        "dexcom_g4"
      ],
      "uploaderNames": [
        "dexcom g4"
      ],
      "types": [
        "cgm"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 37973339602,
      "createdAt": "2017-06-16T03:21:35.118Z",
      "updatedAt": "2018-10-11T07:27:01.323Z",
      "guid": "e6c67e2e-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dexcom G4"
    },
    {
      "id": "DEXCOM_G4_SHARE",
      "brand": "dexcom",
      "model": "G4 with Share",
      "name": "DEXCOM_G4_SHARE",
      "backwardCompatibilityName": "",
      "nameAndroid": "DEXCOM_G4_SHARE",
      "legacyNumberIos": 1000,
      "legacyNumberAndroid": 52,
      "mappedFrom": [
        "dexcom g4 with share",
        "dexcom_g4_share",
        "g4 with share",
        "g4 with share",
        "g4 with share"
      ],
      "uploaderNames": [],
      "types": [
        "cgm"
      ],
      "connectionTypes": [
        "micro_usb",
        "healthkit"
      ],
      "versionC4cAndroid": "",
      "versionIos": "4.3",
      "versionAndroid": "",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "not_supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973338834,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.122Z",
      "updatedAt": "2018-09-10T03:43:39.817Z",
      "guid": "e6c71e06-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dexcom G4 With Share"
    },
    {
      "id": "DEXCOM_G5",
      "brand": "dexcom",
      "model": "G5",
      "name": "DEXCOM_G5",
      "backwardCompatibilityName": "",
      "nameAndroid": "DEXCOM_G5",
      "legacyNumberIos": 1001,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "dexcom - g5",
        "dexcom g5",
        "dexcom_g5"
      ],
      "uploaderNames": [
        "dexcom g5"
      ],
      "types": [
        "cgm"
      ],
      "connectionTypes": [
        "micro_usb",
        "healthkit"
      ],
      "versionC4cAndroid": "1.0.1c",
      "versionIos": "4.3",
      "versionAndroid": "4.3",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973338898,
      "shopifyProductAndroid": 37973338898,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.126Z",
      "updatedAt": "2018-02-06T11:30:36.875Z",
      "guid": "e6c7bc12-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dexcom G5"
    },
    {
      "id": "FREESTYLE_FREEDOM_LITE",
      "brand": "FreeStyle",
      "model": "Freedom Lite",
      "name": "FREESTYLE_FREEDOM_LITE",
      "backwardCompatibilityName": "FreestyleFreedomLite",
      "nameAndroid": "FREESTYLE_FREEDOM_LITE",
      "legacyNumberIos": 5,
      "legacyNumberAndroid": 5,
      "mappedFrom": [
        "freestylefreedomlite",
        "freestyle freedom lite",
        "freestyle - freestylefreedomlite",
        "freestyle_freedom_lite"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.130Z",
      "updatedAt": "2018-02-06T11:31:03.670Z",
      "guid": "e6c86ab8-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Freedom Lite"
    },
    {
      "id": "FREESTYLE_LITE",
      "brand": "FreeStyle",
      "model": "Lite",
      "name": "FREESTYLE_LITE",
      "backwardCompatibilityName": "FreestyleLite",
      "nameAndroid": "FREESTYLE_LITE",
      "legacyNumberIos": 6,
      "legacyNumberAndroid": 6,
      "mappedFrom": [
        "freestylelite",
        "freestyle lite",
        "freestyle - freestylelite",
        "freestyle_lite"
      ],
      "uploaderNames": [
        "freestyle"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.134Z",
      "updatedAt": "2018-02-06T11:32:29.764Z",
      "guid": "e6c9133c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Lite"
    },
    {
      "id": "FREESTYLE_PRECISION_NEO",
      "brand": "FreeStyle",
      "model": "Precision Neo",
      "name": "FREESTYLE_PRECISION_NEO",
      "backwardCompatibilityName": "FreestyleNeo",
      "nameAndroid": "FREESTYLE_NEO",
      "legacyNumberIos": 51,
      "legacyNumberAndroid": 6,
      "mappedFrom": [
        "freestyle precision neo",
        "freestyleneo",
        "freestyle_precision_neo"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.1",
      "versionIos": "",
      "versionAndroid": "4.3",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.139Z",
      "updatedAt": "2018-02-06T11:31:51.889Z",
      "guid": "e6c9b72e-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Precision Neo"
    },
    {
      "id": "FREESTYLE_INSULINX",
      "brand": "FreeStyle",
      "model": "Insulinx",
      "name": "FREESTYLE_INSULINX",
      "backwardCompatibilityName": "FreestyleInsuLinx",
      "nameAndroid": "FREESTYLE_INSULINX",
      "legacyNumberIos": 56,
      "legacyNumberAndroid": 6,
      "mappedFrom": [
        "freestyleinsulinx",
        "freestyle insulinx",
        "freestyle - freestyleinsulinx",
        "freestyle_insulinx"
      ],
      "uploaderNames": [
        "abbott freestyle insulinx"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.144Z",
      "updatedAt": "2018-02-06T11:31:41.915Z",
      "guid": "e6ca75ce-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Insulinx"
    },
    {
      "id": "FREESTYLE_OPTIMUM_NEO",
      "brand": "FreeStyle",
      "model": "Optium Neo",
      "name": "FREESTYLE_OPTIMUM_NEO",
      "backwardCompatibilityName": null,
      "nameAndroid": "FREESTYLE_OPTIMUM_NEO",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "freestyle optium neo"
      ],
      "uploaderNames": [
        "abbott freestyle optium neo"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.148Z",
      "updatedAt": "2017-09-07T02:30:43.033Z",
      "guid": "e6cb17d6-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Optium Neo"
    },
    {
      "id": "GLUCOCARD_VITAL",
      "brand": "GLUCOCARD",
      "model": "Vital",
      "name": "GLUCOCARD_VITAL",
      "backwardCompatibilityName": "GlucocardVital",
      "nameAndroid": "GLUCOCARD_VITAL",
      "legacyNumberIos": 12,
      "legacyNumberAndroid": 12,
      "mappedFrom": [
        "glucocardvital",
        "glucocard vital",
        "glucocard - glucocardvital",
        "glucocard_vital"
      ],
      "uploaderNames": [
        "glucocard vital"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.152Z",
      "updatedAt": "2018-02-06T11:32:47.121Z",
      "guid": "e6cbbfba-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GLUCOCARD Vital"
    },
    {
      "id": "GLUCOCARD_01",
      "brand": "GLUCOCARD",
      "model": "01",
      "name": "GLUCOCARD_01",
      "backwardCompatibilityName": "Glucocard01",
      "nameAndroid": "GLUCOCARD_01",
      "legacyNumberIos": 18,
      "legacyNumberAndroid": 18,
      "mappedFrom": [
        "glucocard01",
        "glucocard 01",
        "glucocard - glucocard01",
        "glucocard_01"
      ],
      "uploaderNames": [
        "glucocard 01"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.157Z",
      "updatedAt": "2018-02-06T11:32:36.495Z",
      "guid": "e6cc6d2a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GLUCOCARD 01"
    },
    {
      "id": "GLUCOCARD_SHINE",
      "brand": "GLUCOCARD",
      "model": "Shine",
      "name": "GLUCOCARD_SHINE",
      "backwardCompatibilityName": null,
      "nameAndroid": "GLUCOCARD_SHINE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "glucocard shine"
      ],
      "uploaderNames": [
        "glucocard shine"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.161Z",
      "updatedAt": "2017-09-07T02:30:43.061Z",
      "guid": "e6cd074e-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GLUCOCARD Shine"
    },
    {
      "id": "GLUCOCARD_EXPRESSION",
      "brand": "GLUCOCARD",
      "model": "Expression",
      "name": "GLUCOCARD_EXPRESSION",
      "backwardCompatibilityName": "",
      "nameAndroid": "GLUCOCARD_EXPRESSION",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "glucocard expression"
      ],
      "uploaderNames": [
        "glucocard expression"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.166Z",
      "updatedAt": "2018-10-19T22:44:46.060Z",
      "guid": "e6cdca58-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GLUCOCARD Expression"
    },
    {
      "id": "INSULET_OMNIPOD",
      "brand": "Insulet",
      "model": "Omnipod ® System",
      "name": "INSULET_OMNIPOD",
      "backwardCompatibilityName": "",
      "nameAndroid": "INSULET_OMNIPOD",
      "legacyNumberIos": 1003,
      "legacyNumberAndroid": 50,
      "mappedFrom": [
        "insulet - sample",
        "insulet - omnipod",
        "insulet omnipod system",
        "insulet - insuletomnipod",
        "insulet - omnipod"
      ],
      "uploaderNames": [
        "omnipod"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "mini_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.6",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 32988966162,
      "shopifyProductAndroid": 37973339730,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.170Z",
      "updatedAt": "2018-11-01T21:07:40.102Z",
      "guid": "e6ce71f6-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Insulet Omnipod ® System"
    },
    {
      "id": "CARELINK_USB",
      "brand": "Medtronic",
      "model": "CareLink USB",
      "name": "CARELINK_USB",
      "backwardCompatibilityName": "",
      "nameAndroid": "CARELINK",
      "legacyNumberIos": 1004,
      "legacyNumberAndroid": 53,
      "mappedFrom": [
        "medtronic carelink usb",
        "medtronic - carelinkusb"
      ],
      "uploaderNames": [],
      "types": [
        "cgm",
        "pump"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": 37973339154,
      "shopifyProductAndroid": 37973339154,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.178Z",
      "updatedAt": "2018-02-06T11:27:33.788Z",
      "guid": "e6cfa65c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Medtronic CareLink USB"
    },
    {
      "id": "MINIMED_CONNECT",
      "brand": "Medtronic",
      "model": "CareLink Personal",
      "name": "MINIMED_CONNECT",
      "backwardCompatibilityName": "",
      "nameAndroid": "MINIMED_CONNECT",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "medtronic carelink personal"
      ],
      "uploaderNames": [],
      "types": [
        "cgm",
        "pump"
      ],
      "connectionTypes": [
        "cloud"
      ],
      "versionC4cAndroid": "1.0.1c",
      "versionIos": "4.3",
      "versionAndroid": "4.3",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339154,
      "shopifyProductAndroid": 37973339154,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.182Z",
      "updatedAt": "2018-02-06T11:34:26.204Z",
      "guid": "e6d060ce-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Medtronic CareLink Personal"
    },
    {
      "id": "NIPRO_TRUEBALANCE",
      "brand": "Nipro/Trividia",
      "model": "TRUEbalance",
      "name": "NIPRO_TRUEBALANCE",
      "backwardCompatibilityName": "NiproTrueBalance",
      "nameAndroid": "NIPRO_TRUEBALANCE",
      "legacyNumberIos": 21,
      "legacyNumberAndroid": 21,
      "mappedFrom": [
        "niprotruebalance",
        "nipro/trividia truebalance",
        "nipro - niprotruebalance",
        "nipro_truebalance"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.188Z",
      "updatedAt": "2018-11-01T22:29:23.530Z",
      "guid": "e6d12d56-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUEbalance"
    },
    {
      "id": "NIPRO_TRUEREAD",
      "brand": "Nipro/Trividia",
      "model": "TRUEread",
      "name": "NIPRO_TRUEREAD",
      "backwardCompatibilityName": "NiproTrueRead",
      "nameAndroid": "NIPRO_TRUEREAD",
      "legacyNumberIos": 22,
      "legacyNumberAndroid": 22,
      "mappedFrom": [
        "niprotrueread",
        "nipro/trividia trueread",
        "nipro - niprotrueread",
        "nipro_trueread"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.193Z",
      "updatedAt": "2018-02-06T11:34:54.540Z",
      "guid": "e6d1dbac-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUEread"
    },
    {
      "id": "NIPRO_TRUERESULT",
      "brand": "Nipro/Trividia",
      "model": "TRUEresult",
      "name": "NIPRO_TRUERESULT",
      "backwardCompatibilityName": "NiproTrueResult",
      "nameAndroid": "NIPRO_TRUERESULT",
      "legacyNumberIos": 23,
      "legacyNumberAndroid": 23,
      "mappedFrom": [
        "niprotrueresult",
        "nipro/trividia trueresult",
        "nipro - niprotrueresult",
        "nipro_trueresult"
      ],
      "uploaderNames": [
        "trueresult/trueyou"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.198Z",
      "updatedAt": "2018-02-06T11:35:00.391Z",
      "guid": "e6d2a8fc-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUEresult"
    },
    {
      "id": "NIPRO_TRUETRACK",
      "brand": "Nipro/Trividia",
      "model": "TRUEtrack",
      "name": "NIPRO_TRUETRACK",
      "backwardCompatibilityName": "NiproTrueTrack",
      "nameAndroid": "NIPRO_TRUETRACK",
      "legacyNumberIos": 24,
      "legacyNumberAndroid": 24,
      "mappedFrom": [
        "niprotruetrack",
        "nipro/trividia truetrack",
        "nipro - niprotruetrack",
        "nipro_truetrack"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.203Z",
      "updatedAt": "2018-02-06T11:35:05.308Z",
      "guid": "e6d376b0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUEtrack"
    },
    {
      "id": "NIPRO_TRUE_METRIX_AIR",
      "brand": "Nipro/Trividia",
      "model": "TRUE METRIX AIR",
      "name": "NIPRO_TRUE_METRIX_AIR",
      "backwardCompatibilityName": "NiproTrueMetrixAir",
      "nameAndroid": "NIPRO_TRUE_METRIX_AIR",
      "legacyNumberIos": 46,
      "legacyNumberAndroid": 44,
      "mappedFrom": [
        "niprotruemetrixair",
        "niprotruemetrixairdocking",
        "nipro/trividia true metrix air",
        "nipro - niprotruemetrixair",
        "nipro_true_metrix_air"
      ],
      "uploaderNames": [
        "true metrix air"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm",
        "ble"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339922,
      "shopifyProductAndroid": 37973339922,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.211Z",
      "updatedAt": "2018-02-06T11:36:14.149Z",
      "guid": "e6d4bc64-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUE METRIX AIR"
    },
    {
      "id": "NIPRO_TRUE_METRIX",
      "brand": "Nipro/Trividia",
      "model": "TRUE METRIX",
      "name": "NIPRO_TRUE_METRIX",
      "backwardCompatibilityName": "NiproTrueMetrixDocking",
      "nameAndroid": "NIPRO_TRUE_METRIX",
      "legacyNumberIos": 47,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "niprotruemetrix",
        "niprotruemetrixdocking",
        "nipro/trividia true metrix",
        "nipro_true_metrix"
      ],
      "uploaderNames": [
        "true metrix"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.216Z",
      "updatedAt": "2017-11-02T05:35:13.311Z",
      "guid": "e6d58734-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nipro/Trividia TRUE METRIX"
    },
    {
      "id": "ON_CALL_ADVANCED",
      "brand": "On Call",
      "model": "Advanced",
      "name": "ON_CALL_ADVANCED",
      "backwardCompatibilityName": null,
      "nameAndroid": "ON_CALL_ADVANCED",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "on call advanced"
      ],
      "uploaderNames": [
        "on call advanced"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.225Z",
      "updatedAt": "2017-09-07T02:30:43.214Z",
      "guid": "e6d6d8a0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "On Call Advanced"
    },
    {
      "id": "ON_CALL_PLUS",
      "brand": "On Call",
      "model": "Plus",
      "name": "ON_CALL_PLUS",
      "backwardCompatibilityName": null,
      "nameAndroid": "ON_CALL_PLUS",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "on call plus"
      ],
      "uploaderNames": [
        "on call plus"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.230Z",
      "updatedAt": "2017-09-07T02:30:43.220Z",
      "guid": "e6d7b180-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "On Call Plus"
    },
    {
      "id": "ON_CALL_RESOLVE",
      "brand": "On Call USB cable",
      "model": "Resolve",
      "name": "ON_CALL_RESOLVE",
      "backwardCompatibilityName": null,
      "nameAndroid": "ON_CALL_RESOLVE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "on call usb cable resolve"
      ],
      "uploaderNames": [
        "on call resolve/vivid"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.234Z",
      "updatedAt": "2018-06-13T17:45:05.011Z",
      "guid": "e6d84eec-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "On Call USB Cable Resolve"
    },
    {
      "id": "ON_CALL_VIVID",
      "brand": "On Call",
      "model": "Vivid",
      "name": "ON_CALL_VIVID",
      "backwardCompatibilityName": null,
      "nameAndroid": "ON_CALL_VIVID",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "on call vivid"
      ],
      "uploaderNames": [
        "on call resolve/vivid"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.238Z",
      "updatedAt": "2017-09-07T02:30:43.232Z",
      "guid": "e6d8e7c6-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "On Call Vivid"
    },
    {
      "id": "ONETOUCH_ULTRA2",
      "brand": "OneTouch",
      "model": "Ultra2",
      "name": "ONETOUCH_ULTRA2",
      "backwardCompatibilityName": "Ultra2",
      "nameAndroid": "ONETOUCH_ULTRA2",
      "legacyNumberIos": 1,
      "legacyNumberAndroid": 1,
      "mappedFrom": [
        "my awesome ultra2",
        "ultra2",
        "onetouch ultra2",
        "onetouch - ultra2",
        "onetouch_ultra2"
      ],
      "uploaderNames": [
        "onetouch ultra2"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.242Z",
      "updatedAt": "2018-02-06T11:35:45.881Z",
      "guid": "e6d9795c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Ultra2"
    },
    {
      "id": "ONETOUCH_ULTRAMINI",
      "brand": "OneTouch",
      "model": "UltraMini",
      "name": "ONETOUCH_ULTRAMINI",
      "backwardCompatibilityName": "UltraMini",
      "nameAndroid": "ONETOUCH_ULTRAMINI",
      "legacyNumberIos": 2,
      "legacyNumberAndroid": 2,
      "mappedFrom": [
        "ultramini",
        "onetouch ultramini",
        "onetouch - ultramini",
        "onetouch_ultramini"
      ],
      "uploaderNames": [
        "onetouch ultra mini/easy"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.246Z",
      "updatedAt": "2018-02-06T11:35:33.573Z",
      "guid": "e6da0cf0-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch UltraMini"
    },
    {
      "id": "ONETOUCH_ULTRALINK",
      "brand": "OneTouch",
      "model": "UltraLink",
      "name": "ONETOUCH_ULTRALINK",
      "backwardCompatibilityName": "UltraLink",
      "nameAndroid": "ONETOUCH_ULTRALINK",
      "legacyNumberIos": 7,
      "legacyNumberAndroid": 7,
      "mappedFrom": [
        "ultralink",
        "onetouch ultralink",
        "onetouch - ultralink",
        "onetouch_ultralink"
      ],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_3_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.249Z",
      "updatedAt": "2018-02-06T11:35:39.666Z",
      "guid": "e6da9b7a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch UltraLink"
    },
    {
      "id": "ONETOUCH_VERIO_SYNC",
      "brand": "OneTouch",
      "model": "Verio Sync",
      "name": "ONETOUCH_VERIO_SYNC",
      "backwardCompatibilityName": "",
      "nameAndroid": "ONETOUCH_VERIO_SYNC",
      "legacyNumberIos": 49,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "onetouch f8hx",
        "onetouch g9f6",
        "onetouch gqt6",
        "onetouch lrpn",
        "onetouch lrv2",
        "onetouch m8z9",
        "onetouch n0ct",
        "onetouch nd3b",
        "onetouch nv82",
        "onetouch p21k",
        "onetouch q01n",
        "onetouch q9nx",
        "onetouch qb9p",
        "onetouch r9pq",
        "onetouch s2vw",
        "onetouch sc5r",
        "onetouch t1bs",
        "onetouch tc4v",
        "onetouch x8p0",
        "onetouch xqpt",
        "onetouch zzm1",
        "veriosync",
        "onetouch verio sync",
        "onetouch - veriosync",
        "onetouch_verio_sync"
      ],
      "uploaderNames": [
        "onetouch veriosync"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "healthkit",
        "mini_usb"
      ],
      "versionC4cAndroid": "",
      "versionIos": "4.3",
      "versionAndroid": "",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973340114,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.253Z",
      "updatedAt": "2018-02-06T11:37:30.679Z",
      "guid": "e6db2888-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Verio Sync"
    },
    {
      "id": "ONETOUCH_VERIO_FLEX",
      "brand": "OneTouch",
      "model": "Verio Flex",
      "name": "ONETOUCH_VERIO_FLEX",
      "backwardCompatibilityName": "VerioFlex",
      "nameAndroid": "VERIO_FLEX",
      "legacyNumberIos": 50,
      "legacyNumberAndroid": 58,
      "mappedFrom": [
        "verioflex",
        "onetouch verio flex",
        "onetouch - verioflex",
        "verio_flex"
      ],
      "uploaderNames": [
        "onetouch verio flex"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339986,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.257Z",
      "updatedAt": "2018-02-06T11:37:09.491Z",
      "guid": "e6dbc022-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Verio Flex"
    },
    {
      "id": "ONETOUCH_VERIO",
      "brand": "OneTouch",
      "model": "Verio",
      "name": "ONETOUCH_VERIO",
      "backwardCompatibilityName": "Verio",
      "nameAndroid": "VERIO",
      "legacyNumberIos": 54,
      "legacyNumberAndroid": 57,
      "mappedFrom": [
        "verio",
        "onetouch verio"
      ],
      "uploaderNames": [
        "onetouch verio",
        "onetouch verio (old)"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339602,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.261Z",
      "updatedAt": "2018-02-06T11:36:57.439Z",
      "guid": "e6dc519a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Verio"
    },
    {
      "id": "ONETOUCH_VERIO_IQ",
      "brand": "OneTouch",
      "model": "Verio IQ",
      "name": "ONETOUCH_VERIO_IQ",
      "backwardCompatibilityName": "VerioIQ",
      "nameAndroid": "VERIO_SYNC_OR_IQ",
      "legacyNumberIos": 55,
      "legacyNumberAndroid": 59,
      "mappedFrom": [
        "verioiq",
        "onetouch verio iq",
        "verio_sync_or_iq"
      ],
      "uploaderNames": [
        "onetouch verioiq"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": 37973339730,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.265Z",
      "updatedAt": "2018-02-06T11:37:20.522Z",
      "guid": "e6dce8ee-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Verio IQ"
    },
    {
      "id": "PRODIGY_AUTOCODE",
      "brand": "Prodigy",
      "model": "AutoCode",
      "name": "PRODIGY_AUTOCODE",
      "backwardCompatibilityName": null,
      "nameAndroid": "PRODIGY_AUTOCODE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "prodigy autocode"
      ],
      "uploaderNames": [
        "prodigy autocode"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "mini_usb"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.268Z",
      "updatedAt": "2017-09-07T02:30:43.286Z",
      "guid": "e6dd71e2-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Prodigy AutoCode"
    },
    {
      "id": "RELION_CONFIRM",
      "brand": "ReliOn",
      "model": "Confirm",
      "name": "RELION_CONFIRM",
      "backwardCompatibilityName": "ReliOnConfirm",
      "nameAndroid": "RELION_CONFIRM",
      "legacyNumberIos": 19,
      "legacyNumberAndroid": 19,
      "mappedFrom": [
        "relionconfirm",
        "relion confirm",
        "relion - relionconfirm",
        "relion_confirm"
      ],
      "uploaderNames": [
        "relion confirm"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.272Z",
      "updatedAt": "2018-02-06T11:38:09.142Z",
      "guid": "e6de0486-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ReliOn Confirm"
    },
    {
      "id": "RELION_PRIME",
      "brand": "ReliOn",
      "model": "Prime",
      "name": "RELION_PRIME",
      "backwardCompatibilityName": "ReliOnPrime",
      "nameAndroid": "RELION_PRIME",
      "legacyNumberIos": 20,
      "legacyNumberAndroid": 20,
      "mappedFrom": [
        "relionprime",
        "relion prime",
        "relion - relionprime",
        "relion_prime"
      ],
      "uploaderNames": [
        "relion prime"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "serial_2_5mm"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973339410,
      "shopifyProductAndroid": 37973339410,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.275Z",
      "updatedAt": "2018-02-06T11:38:13.880Z",
      "guid": "e6de94c8-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ReliOn Prime"
    },
    {
      "id": "SANOFI_BGSTAR",
      "brand": "Sanofi",
      "model": "BGStar",
      "name": "SANOFI_BGSTAR",
      "backwardCompatibilityName": null,
      "nameAndroid": "SANOFI_BGSTAR",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "sanofi bgstar"
      ],
      "uploaderNames": [
        "sanofi bgstar"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.286Z",
      "updatedAt": "2017-09-07T02:30:43.322Z",
      "guid": "e6e03b48-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Sanofi BGStar"
    },
    {
      "id": "SANOFI_MYSTAR_EXTRA",
      "brand": "Sanofi",
      "model": "MyStar Extra",
      "name": "SANOFI_MYSTAR_EXTRA",
      "backwardCompatibilityName": null,
      "nameAndroid": "SANOFI_MYSTAR_EXTRA",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "sanofi mystar extra"
      ],
      "uploaderNames": [
        "sanofi mystar extra"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.290Z",
      "updatedAt": "2017-09-07T02:30:43.328Z",
      "guid": "e6e0c5cc-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Sanofi MyStar Extra"
    },
    {
      "id": "TANDEM_T:FLEX",
      "brand": "Tandem",
      "model": "t:flex",
      "name": "TANDEM_T:FLEX",
      "backwardCompatibilityName": "",
      "nameAndroid": "TANDEM_T:FLEX",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "tandem t:flex"
      ],
      "uploaderNames": [
        "tandem t:flex"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.302Z",
      "updatedAt": "2018-03-01T00:57:29.242Z",
      "guid": "e6e2ad2e-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Tandem T:flex"
    },
    {
      "id": "TANDEM_T:SLIM",
      "brand": "Tandem",
      "model": "t:slim",
      "name": "TANDEM_T:SLIM",
      "backwardCompatibilityName": "",
      "nameAndroid": "TANDEM_T:SLIM",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "tandem t:slim"
      ],
      "uploaderNames": [
        "tandem t:slim"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.309Z",
      "updatedAt": "2018-03-01T00:59:25.815Z",
      "guid": "e6e3bf98-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Tandem T:slim"
    },
    {
      "id": "TANDEM_T:SLIM_G4",
      "brand": "Tandem",
      "model": "t:slim G4",
      "name": "TANDEM_T:SLIM_G4",
      "backwardCompatibilityName": null,
      "nameAndroid": "TANDEM_T:SLIM_G4",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "tandem t:slim g4"
      ],
      "uploaderNames": [
        "tandem t:slim g4"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.313Z",
      "updatedAt": "2017-09-07T02:30:43.375Z",
      "guid": "e6e44a6c-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Tandem T:slim G4"
    },
    {
      "id": "WAVESENSE_JAZZ_V2",
      "brand": "WaveSense",
      "model": "Jazz",
      "name": "WAVESENSE_JAZZ_V2",
      "backwardCompatibilityName": null,
      "nameAndroid": "WAVESENSE_JAZZ_V2",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "wavesense jazz"
      ],
      "uploaderNames": [
        "agamatrix wavesense jazz",
        "wavesense jazz"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.327Z",
      "updatedAt": "2017-09-07T02:30:43.401Z",
      "guid": "e6e66c7a-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "WaveSense Jazz"
    },
    {
      "id": "BAYER_CONTOUR_NEXT_LINK_2_4",
      "brand": "Ascensia (Bayer)",
      "model": "CONTOUR NEXT LINK 2.4",
      "name": "BAYER_CONTOUR_NEXT_LINK_2_4",
      "backwardCompatibilityName": "",
      "nameAndroid": "BAYER_CONTOUR_NEXT_LINK_2_4",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "bayer contour next link 2.4"
      ],
      "uploaderNames": [
        "contour next link 2.4"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "usb_a"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-11-15T23:38:23.533Z",
      "updatedAt": "2017-11-15T23:38:23.533Z",
      "guid": "b2e421e0-ca5d-11e7-8925-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Ascensia (Bayer) CONTOUR NEXT LINK 2.4"
    },
    {
      "id": "FREESTYLE_PRECISION_XTRA",
      "brand": "FreeStyle",
      "model": "Precision Xtra",
      "name": "FREESTYLE_PRECISION_XTRA",
      "backwardCompatibilityName": "",
      "nameAndroid": "FREESTYLE_PRECISION_XTRA",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "precision xceed"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "strip_port"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-08T22:51:05.545Z",
      "updatedAt": "2018-03-27T21:02:39.260Z",
      "guid": "0f5cb75c-dc6a-11e7-8f23-12776e7d8ade",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Precision Xtra"
    },
    {
      "id": "DEXCOM_ACCOUNT",
      "brand": "dexcom",
      "model": "Dexcom Account",
      "name": "DEXCOM_ACCOUNT",
      "backwardCompatibilityName": "",
      "nameAndroid": "DEXCOM_ACCOUNT",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "dexcom cloud"
      ],
      "types": [
        "cgm"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.650Z",
      "updatedAt": "2018-10-26T06:27:30.283Z",
      "guid": "ecf8b852-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dexcom Dexcom Account"
    },
    {
      "id": "FITBIT",
      "brand": "Fitbit",
      "model": "Fitbit",
      "name": "FITBIT",
      "backwardCompatibilityName": "",
      "nameAndroid": "FITBIT",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.659Z",
      "updatedAt": "2017-12-16T02:57:13.026Z",
      "guid": "ecfab440-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Fitbit Fitbit"
    },
    {
      "id": "IHEALTH",
      "brand": "iHealth",
      "model": "iHealth",
      "name": "IHEALTH",
      "backwardCompatibilityName": "",
      "nameAndroid": "IHEALTH",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.663Z",
      "updatedAt": "2017-12-16T02:58:35.996Z",
      "guid": "ecfb5be8-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "IHealth IHealth"
    },
    {
      "id": "JAWBONE",
      "brand": "Jawbone UP",
      "model": "Jawbone UP",
      "name": "JAWBONE",
      "backwardCompatibilityName": "",
      "nameAndroid": "JAWBONE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.667Z",
      "updatedAt": "2017-12-16T03:03:04.671Z",
      "guid": "ecfbe982-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Jawbone UP Jawbone UP"
    },
    {
      "id": "MOVES",
      "brand": "Moves",
      "model": "Moves",
      "name": "MOVES",
      "backwardCompatibilityName": "",
      "nameAndroid": "MOVES",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.671Z",
      "updatedAt": "2017-12-16T03:04:45.691Z",
      "guid": "ecfc81c6-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Moves Moves"
    },
    {
      "id": "WITHINGS",
      "brand": "Nokia",
      "model": "Nokia",
      "name": "WITHINGS",
      "backwardCompatibilityName": "",
      "nameAndroid": "WITHINGS",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.675Z",
      "updatedAt": "2017-12-16T03:06:00.194Z",
      "guid": "ecfd169a-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Nokia Nokia"
    },
    {
      "id": "RUNKEEPER",
      "brand": "Runkeeper",
      "model": "Runkeeper",
      "name": "RUNKEEPER",
      "backwardCompatibilityName": "",
      "nameAndroid": "RUNKEEPER",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.679Z",
      "updatedAt": "2017-12-16T03:06:57.631Z",
      "guid": "ecfdb4ec-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Runkeeper Runkeeper"
    },
    {
      "id": "STRAVA",
      "brand": "Strava",
      "model": "Strava",
      "name": "STRAVA",
      "backwardCompatibilityName": "",
      "nameAndroid": "STRAVA",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "1.1.0C",
      "versionIos": "4.6.0",
      "versionAndroid": "4.6.0",
      "versionC4cIos": "1.1.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "not_supported",
      "web": "supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-12-16T02:22:12.684Z",
      "updatedAt": "2017-12-16T03:07:59.283Z",
      "guid": "ecfe6b80-e207-11e7-b3fa-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Strava Strava"
    },
    {
      "id": "TANDEM_T:SLIM_X2",
      "brand": "Tandem",
      "model": "t:slim X2",
      "name": "TANDEM_T:SLIM_X2",
      "backwardCompatibilityName": null,
      "nameAndroid": "TANDEM_T:SLIM_X2",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [
        "tandem t:slim x2"
      ],
      "uploaderNames": [
        "tandem t:slim x2"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2017-06-16T03:21:35.306Z",
      "updatedAt": "2018-04-11T07:48:46.868Z",
      "guid": "e6e337bc-5242-11e7-9193-12d96ab399fc",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Tandem T:slim X2"
    },
    {
      "id": "APPLE_HEALTH",
      "brand": "Apple Health",
      "model": "Apple Health",
      "name": "APPLE_HEALTH",
      "backwardCompatibilityName": "",
      "nameAndroid": "test",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "fitness_and_health"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "",
      "versionIos": "5.0",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-04-20T19:36:43.165Z",
      "updatedAt": "2018-06-19T17:36:32.611Z",
      "guid": "27848500-44d2-11e8-b2ae-0a5cd60a609a",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Apple Health Apple Health"
    },
    {
      "id": "INSULET_DASH",
      "brand": "Insulet",
      "model": "Omnipod DASH™ System",
      "name": "INSULET_DASH",
      "backwardCompatibilityName": null,
      "nameAndroid": "INSULET_DASH",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "insulet omnipod dash"
      ],
      "types": [
        "pump"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.3.0c",
      "versionIos": "5.1.0",
      "versionAndroid": "5.1.0",
      "versionC4cIos": "1.3.0",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-04-20T19:37:05.578Z",
      "updatedAt": "2018-09-06T21:39:51.726Z",
      "guid": "34e0775e-44d2-11e8-b807-0a5cd60a609a",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Insulet Omnipod DASH™ System"
    },
    {
      "id": "ACCU_CHEK_INSIGHT_METER",
      "brand": "ACCU-CHEK",
      "model": "Insight (meter)",
      "name": "ACCU_CHEK_INSIGHT_METER",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "accu-chek aviva insight"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.459Z",
      "updatedAt": "2018-07-31T22:31:43.000Z",
      "guid": "81159864-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "ACCU-CHEK Insight (meter)"
    },
    {
      "id": "B_BRAUN_OMNITEST_5",
      "brand": "B. Braun",
      "model": "Omnitest 5",
      "name": "B_BRAUN_OMNITEST_5",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "b braun omnitest 5"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.589Z",
      "updatedAt": "2018-06-22T18:12:01.239Z",
      "guid": "8129e3aa-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "B. Braun Omnitest 5"
    },
    {
      "id": "BEURER_GL50_ELVO",
      "brand": "Beurer",
      "model": "GL50 evo",
      "name": "BEURER_GL50_ELVO",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "beurer gl50 evo"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.617Z",
      "updatedAt": "2018-07-31T18:27:38.409Z",
      "guid": "812e4134-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Beurer GL50 Evo"
    },
    {
      "id": "FREESTYLE_LIBRE",
      "brand": "FreeStyle",
      "model": "Libre",
      "name": "FREESTYLE_LIBRE",
      "backwardCompatibilityName": "",
      "nameAndroid": "FREESTYLE_LIBRE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "abbott freestyle libre"
      ],
      "types": [
        "cgm"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "not_supported",
      "android": "not_supported",
      "kiosk": "not_supported",
      "web": "not_supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "not_supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.643Z",
      "updatedAt": "2018-07-18T01:31:40.276Z",
      "guid": "813235f0-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "FreeStyle Libre"
    },
    {
      "id": "GLUCOMEN_AREO_2K",
      "brand": "GlucoMen",
      "model": "Areo 2k",
      "name": "GLUCOMEN_AREO_2K",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "glucomen areo 2k"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.704Z",
      "updatedAt": "2018-06-13T17:45:04.704Z",
      "guid": "813b8f1a-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GlucoMen Areo 2k"
    },
    {
      "id": "GLUCOMEN_AREO",
      "brand": "GlucoMen",
      "model": "Areo",
      "name": "GLUCOMEN_AREO",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "glucomen areo"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.723Z",
      "updatedAt": "2018-06-13T17:45:04.723Z",
      "guid": "813e702c-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GlucoMen Areo"
    },
    {
      "id": "GLUCOMEN_GM",
      "brand": "GlucoMen",
      "model": "GM",
      "name": "GLUCOMEN_GM",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "glucomen gm"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.742Z",
      "updatedAt": "2018-06-13T17:45:04.742Z",
      "guid": "8141484c-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GlucoMen GM"
    },
    {
      "id": "GLUCOMEN_LX_PLUS",
      "brand": "GlucoMen",
      "model": "LX Plus",
      "name": "GLUCOMEN_LX_PLUS",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "glucomen lx/lx plus"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.761Z",
      "updatedAt": "2018-06-13T17:45:04.761Z",
      "guid": "81442d1e-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "GlucoMen LX Plus"
    },
    {
      "id": "ONETOUCH_SELECT_PLUS_FLEX",
      "brand": "OneTouch",
      "model": "Select Plus Flex",
      "name": "ONETOUCH_SELECT_PLUS_FLEX",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "lifescan onetouch select plus flex"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.799Z",
      "updatedAt": "2018-06-13T17:45:04.799Z",
      "guid": "8149f6c2-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Select Plus Flex"
    },
    {
      "id": "ONETOUCH_VERIO_PRO",
      "brand": "OneTouch",
      "model": "Verio Pro",
      "name": "ONETOUCH_VERIO_PRO",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "onetouch veriopro"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.818Z",
      "updatedAt": "2018-06-13T17:45:04.818Z",
      "guid": "814cdce8-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "OneTouch Verio Pro"
    },
    {
      "id": "WELLION_CALLA_DIALOG",
      "brand": "Wellion",
      "model": "Calla Dialog",
      "name": "WELLION_CALLA_DIALOG",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "wellion calla dialog"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "not_supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.894Z",
      "updatedAt": "2018-11-01T21:07:40.237Z",
      "guid": "81589074-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Wellion Calla Dialog"
    },
    {
      "id": "YPSOMED__MYLIFE_PURA",
      "brand": "Ypsomed",
      "model": "mylife Pura",
      "name": "YPSOMED__MYLIFE_PURA",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "mylife pura"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.933Z",
      "updatedAt": "2018-06-13T17:45:04.933Z",
      "guid": "815e7fca-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Ypsomed Mylife Pura"
    },
    {
      "id": "YPSOMED__MYLIFE_UNIO",
      "brand": "Ypsomed",
      "model": "mylife Unio",
      "name": "YPSOMED__MYLIFE_UNIO",
      "backwardCompatibilityName": null,
      "nameAndroid": null,
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [
        "mylife unio"
      ],
      "types": [
        "meter"
      ],
      "connectionTypes": [],
      "versionC4cAndroid": null,
      "versionIos": null,
      "versionAndroid": null,
      "versionC4cIos": null,
      "ios": null,
      "android": null,
      "kiosk": null,
      "web": null,
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": null,
      "c4cAndroid": null,
      "c4cIos": null,
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-06-13T17:45:04.953Z",
      "updatedAt": "2018-06-13T17:45:04.953Z",
      "guid": "81616d8e-6f31-11e8-b82a-0afbf1ea1649",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Ypsomed Mylife Unio"
    },
    {
      "id": "DEXCOM_G5_TOUCHSCREEN_RECEIVER",
      "brand": "dexcom",
      "model": "G5 Touchscreen Receiver",
      "name": "DEXCOM_G5_TOUCHSCREEN_RECEIVER",
      "backwardCompatibilityName": null,
      "nameAndroid": "DEXCOM_G5_TOUCHSCREEN_RECEIVER",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "cgm"
      ],
      "connectionTypes": [
        "micro_usb"
      ],
      "versionC4cAndroid": "1.4.0c",
      "versionIos": null,
      "versionAndroid": "5.1.0",
      "versionC4cIos": null,
      "ios": "not_supported",
      "android": "supported",
      "kiosk": "supported",
      "web": null,
      "transmitter": "not_supported",
      "uploader": "not_supported",
      "partnerIntegration": null,
      "c4cAndroid": "supported",
      "c4cIos": "not_supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-07-03T22:09:00.158Z",
      "updatedAt": "2018-07-03T22:09:00.158Z",
      "guid": "b02864ce-7f0d-11e8-b212-0a5cd60a609a",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dexcom G5 Touchscreen Receiver"
    },
    {
      "id": "US_DEVICE",
      "brand": "US-DEVICE",
      "model": "US-MODEL",
      "name": "US_DEVICE",
      "backwardCompatibilityName": "US-DEVICE",
      "nameAndroid": "US_DEVICE",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "test_device"
      ],
      "versionC4cAndroid": "",
      "versionIos": "",
      "versionAndroid": "",
      "versionC4cIos": "",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": null,
      "shopifyProductAndroid": null,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-08-31T07:14:10.367Z",
      "updatedAt": "2018-08-31T07:14:10.367Z",
      "guid": "417da42c-aced-11e8-b3df-0a82c5ffba16",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "US-DEVICE US-MODEL"
    },
    {
      "id": "DummyModel_Xcd9bd2d8",
      "brand": "Dummy",
      "model": "DummyModel_Xcd9bd2d8",
      "name": "DummyModel_Xcd9bd2d8",
      "backwardCompatibilityName": "",
      "nameAndroid": "DummyModel_Xcd9bd2d8",
      "legacyNumberIos": null,
      "legacyNumberAndroid": null,
      "mappedFrom": [],
      "uploaderNames": [],
      "types": [
        "meter"
      ],
      "connectionTypes": [
        "infrared"
      ],
      "versionC4cAndroid": "1.0.1C",
      "versionIos": "4.3",
      "versionAndroid": "4.3.0",
      "versionC4cIos": "1.0.1",
      "ios": "supported",
      "android": "supported",
      "kiosk": "supported",
      "web": "supported",
      "transmitter": "supported",
      "uploader": "supported",
      "partnerIntegration": "not_supported",
      "c4cAndroid": "supported",
      "c4cIos": "supported",
      "shopifyProductIos": 37973338706,
      "shopifyProductAndroid": 37973338898,
      "shopifyProductAndroidUsbC": 123456789,
      "createdAt": "2018-11-01T22:36:14.727Z",
      "updatedAt": "2018-11-01T22:36:14.727Z",
      "guid": "7f4bcb20-de26-11e8-b41b-125cf007b1c2",
      "softDeleted": false,
      "updatedBy": "server",
      "fullName": "Dummy DummyModel_Xcd9bd2d8"
    }
  ]
};

const apiV2RefDevices = () => {
  return [200, devices];
};

export default apiV2RefDevices;
