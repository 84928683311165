import { uniq } from 'lodash';
import { deviceForVariant, getVariantIds, productsFromVariantIds } from
  './shopifyUtil';
import { priceInCountry } from './supportedCountries';

const SPONSORED_ENROLLMENT_TYPES = ['unique', 'shared', 'dropship'];
const INSULET_REFERENCE_IDS = ['INSULET_OMNIPOD', 'INSULET_DASH'];
const INSULET_SPONSOR_KEY = 'insulet';
const PGS_SPONSOR_PRICING = 0;

export const insuletSponsored = selectedDevices =>
  selectedDevices.find(d => INSULET_REFERENCE_IDS.includes(d.id));

export const pgsSponsored = (user = {}) =>
  SPONSORED_ENROLLMENT_TYPES.includes(user.enrollmentType) || user.dropshipPgs;

export const hardwareImageSrc = (hardware) => {
  if (hardware.images && hardware.images.length > 0) {
    return hardware.images[0].src;
  }
  return undefined;
};

export const availableProducts = (
  user = {},
  selectedDevices,
  shopifyProducts,
  androidChargingCord,
  mobilePlatform,
) => {
  const selectableHardwareVariantIds =
    getVariantIds(mobilePlatform, selectedDevices, androidChargingCord);

  const selectableHardware =
    uniq(productsFromVariantIds(selectableHardwareVariantIds, shopifyProducts));

  return selectableHardware.map((h) => {
    let { price, variantId } = h;

    if (pgsSponsored(user)) {
      price = PGS_SPONSOR_PRICING;
    } else if (insuletSponsored(selectedDevices)) {
      const sponsorship =
        h.sponsorships.find(s => s.sponsor === INSULET_SPONSOR_KEY);
      ({ price, variantId } = sponsorship);
    }

    price = priceInCountry(user.countryOfResidence, price);

    return {
      price,
      variantId,
      title: h.title,
      device: deviceForVariant(selectedDevices, mobilePlatform, h.variantId, androidChargingCord),
      imgSrc: hardwareImageSrc(h),
    };
  });
};
