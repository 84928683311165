import moment from 'moment';
import XRegExp from 'xregexp';
import zxcvbn from 'zxcvbn';
import { isOfAge } from '~/utils/dateUtils';
import { validPhone } from '../../../utils/phoneNumberUtil';

export const emailValid = (email) => {
  const emailTest =
    /^[^@\s]+@([^@\s]+\.)+[^@\W]+$/;

  return emailTest.test(email);
};

export const sizeValid = password => password.length > 7;

export const strengthValue = password => zxcvbn(password).score;

export const passwordValid = (password) => {
  if (!password) return false;
  if (!XRegExp('^.{8,64}$').test(password)) return false;

  return strengthValue(password) > 1;
};

export const textFieldValid = name => Boolean(name) && name.trim().length;

export const consentsValid = (consents, dob, majorityAge, immediateConsent = true) =>
  (!immediateConsent && dob && majorityAge && !isOfAge(dob, majorityAge)) ||
  (consents.termsOfUse && consents.privacy);

export const countryValid = country => Boolean(country) && country.trim().length;

export const dateValid = date => moment.isMoment(date);

export const registerAsAndDobValid = (dateOfBirth, registerAs, majorityAge) =>
  (registerAs === 'adult' && isOfAge(dateOfBirth, majorityAge)) ||
  (registerAs === 'minor' && !isOfAge(dateOfBirth, majorityAge));

export const guardianValid = (guardianInfo, dob, majorityAge, immediateConsent = true) =>
  isOfAge(dob, majorityAge) ||
  (guardianInfo && emailValid(guardianInfo.guardianEmail)
    && (!immediateConsent || guardianInfo.consent)
    && textFieldValid(guardianInfo.guardianFirstName)
    && textFieldValid(guardianInfo.guardianLastName));

export const phoneNumberValid = (code, number, country) =>
  validPhone(code, number, country);

export const unitOfMeasureValid = (uom, defaultUnitOfMeasure) => defaultUnitOfMeasure !== 'mixed' || !!uom;

export const accountInformationValid = (form, majorityAge, defaultUnitOfMeasure) => {
  const valid = emailValid(form.email) &&
    passwordValid(form.password) &&
    textFieldValid(form.firstName) &&
    textFieldValid(form.lastName) &&
    dateValid(form.dateOfBirth) &&
    registerAsAndDobValid(form.dateOfBirth, form.registerAs, majorityAge) &&
    consentsValid(form.consents, form.dateOfBirth, majorityAge, false) &&
    countryValid(form.countryOfResidence) &&
    guardianValid(form.guardianInfo, form.dateOfBirth, majorityAge, false) &&
    phoneNumberValid(form.countryCode, form.mobilePhone, form.phoneCountry) &&
    unitOfMeasureValid(form.meterUnits, defaultUnitOfMeasure);
  return valid;
};

export const emailActivationValid = (form, majorityAge, immediateGuardianConsent) => {
  const valid = passwordValid(form.password) &&
    (form.professional ||
      (consentsValid(form.consents, form.dateOfBirth, majorityAge, immediateGuardianConsent) &&
        dateValid(form.dateOfBirth) &&
        guardianValid(form.guardianInfo, form.dateOfBirth, majorityAge, immediateGuardianConsent)
      )
    );
  return valid;
};

export const codeActivationValid = (form) => {
  const valid = emailValid(form.email) &&
    passwordValid(form.password) &&
    textFieldValid(form.code) &&
    dateValid(form.dateOfBirth) &&
    consentsValid(form.consents) &&
    guardianValid(form.guardianInfo, form.dateOfBirth);
  return valid;
};

export const fieldValidators = {
  email: emailValid,
  password: passwordValid,
  firstName: textFieldValid,
  lastName: textFieldValid,
  dateOfBirth: dateValid,
  consents: consentsValid,
  countryOfResidence: countryValid,
  gaurdianInfo: (guardianInfo, form) => guardianValid(guardianInfo, form.dateOfBirth),
  mobilePhone: phoneNumberValid,
};

export const emailActivationfieldValidators = {
  password: passwordValid,
  consents: consentsValid,
  gaurdianInfo: (guardianInfo, form) => guardianValid(guardianInfo, form.dateOfBirth),
};

export const codeActivationfieldValidators = {
  email: emailValid,
  code: textFieldValid,
  password: passwordValid,
  dateOfBirth: dateValid,
  consents: consentsValid,
  gaurdianInfo: (guardianInfo, form) => guardianValid(guardianInfo, form.dateOfBirth),
};

