import React from 'react';
import PropTypes from 'prop-types';
import images from '~/utils/ImageStore';
import detectOs from '~/utils/detectOs';
import { languageOptions } from
  '../utils/defaultLanguageSelection';
import Select from '../shared/Select/Select';
import './style.sass';

const checkMobilePlatform = () =>
  detectOs.isIos() || detectOs.isAndroid() || detectOs.isBlackBerry();

const EmailActivationHeader = props => (
  <div className="EmailActivationHeader">
    <div className={checkMobilePlatform() ? 'EmailActivationHeader-mobile-page' : 'EmailActivationHeader-web-page'}>
      <div className="EmailActivationHeader-logo-container">
        <img
          className={checkMobilePlatform() ? 'EmailActivationHeader-logo-mobileActivation-page' : 'EmailActivationHeader-logo'}
          src={images.glookoLogo}
          alt="Glooko logo"
        />
      </div>
      <div className="EmailActivationHeader-language-container">
        <div className="EmailActivationHeader-language-selector">
          <Select
            options={languageOptions()}
            onChange={props.onChangeLanguage}
            value={props.language}
          />
        </div>
      </div>
    </div>
  </div>
);

EmailActivationHeader.propTypes = {
  language: PropTypes.string.isRequired,
  onChangeLanguage: PropTypes.func.isRequired,
};

export default EmailActivationHeader;
