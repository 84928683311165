import React from 'react';
import PropTypes from 'prop-types';
import scrollToComponent from 'react-scroll-to-component';
import { Button, Dialog, DialogActions, DialogContent, DialogHeader } from '@glooko/common-ui';
import withTranslate from '../../shared/withTranslate/withTranslate';
import EnrollmentWizardNavigation from '../EnrollmentWizardNavigation';
import { fieldValidators, devicesValid, platformValid, androidChargingCordValid } from
  './validations/deviceSelection';

import AndroidChargingCordSelector from
  './AndroidChargingCordSelector/AndroidChargingCordSelector';
import SmartPhonePlatformSelect from
  './SmartPhonePlatformSelect/SmartPhonePlatformSelect';
import DiabetesDeviceSelectContainer from
  './DiabetesDeviceSelect/DiabetesDeviceSelectContainer';
import DiabetesDeviceSummaryContainer from
  './DiabetesDeviceSummary/DiabetesDeviceSummaryContainer';

import './style.sass';

const formFields = [
  'platform',
  'androidChargingCord',
  'selectedDiabetesDevices',
];

export const deviceNotSupported = (device, selectedPlatform) => (
  selectedPlatform &&
  (device[selectedPlatform] !== 'supported' || selectedPlatform === 'none') &&
  device.uploader !== 'supported' &&
  !(device.connectionTypes.some(connect => connect === 'cloud') && selectedPlatform === 'none')
);

class DeviceSelection extends React.Component {
  constructor() {
    super();
    this.onSubmitDevices = this.onSubmitDevices.bind(this);
    this.showModal = this.showModal.bind(this);
    this.onDismissModal = this.onDismissModal.bind(this);
    this.state = { errorModalOpen: false };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchShopifyProducts();
  }

  onSubmitDevices(e) {
    const { platform, selectedDiabetesDevices } = this.props;
    if (this.props.isValid || (e.shiftKey && e.altKey)) {
      if (selectedDiabetesDevices.length !== 0 &&
        platform &&
        selectedDiabetesDevices.every(device => deviceNotSupported(device, platform))) {
        this.setState({ errorModalOpen: true });
      } else {
        this.submitDevices();
      }
    } else {
      this.touchFields();
      formFields.some((field) => {
        const ref = this[field.concat('Ref')];
        return !fieldValidators[field](this.props) &&
          scrollToComponent(ref, { align: 'top', offset: -8, duration: 500 });
      });
    }
  }

  onDismissModal(ok) {
    this.showModal(false);
    if (ok) {
      this.submitDevices();
    }
  }

  showModal(show) {
    this.setState({ errorModalOpen: show });
  }

  submitDevices() {
    this.props.onContinue(this.props.skipDiabetesDevices);
  }

  touchFields() {
    formFields.forEach((field) => {
      const ref = this[field.concat('Ref')];
      if (ref && ref.touch) { ref.touch(); }
    });
  }

  render() {
    const {
      androidChargingCord, isValid, onSelectAndroidChargingCord, onSelectPlatform,
      canGoBack, onBack, platform, t,
    } = this.props;
    return (
      <div className="DeviceSelection">
        <div className="DeviceSelection-header">
          { t('deviceSelectionHeader') }
          <div className="DeviceSelection-subHeader">
            { t('deviceSelectionSubHeader') }
          </div>
        </div>
        <SmartPhonePlatformSelect
          isValid={() => platformValid(this.props)}
          selectedPlatform={platform}
          onSelectPlatform={onSelectPlatform}
          refs={(el) => {
            /* istanbul ignore next */
            this.platformRef = el;
          }}
        />
        {platform === 'android' &&
          <AndroidChargingCordSelector
            isValid={() => androidChargingCordValid(this.props)}
            onSelectCord={onSelectAndroidChargingCord}
            selectedCord={androidChargingCord}
            refs={(el) => {
              /* istanbul ignore next */
              this.androidChargingCordRef = el;
            }}
          />
        }
        <DiabetesDeviceSelectContainer
          isValid={() => devicesValid(this.props)}
          refs={(el) => {
            /* istanbul ignore next */
            this.selectedDiabetesDevicesRef = el ? el.getWrappedInstance() : el;
          }}
        />
        <DiabetesDeviceSummaryContainer />
        <EnrollmentWizardNavigation
          showBack={canGoBack}
          canGoBack
          secondaryLabel={t('back')}
          onGoBack={onBack}
          canContinue={isValid}
          onContinue={this.onSubmitDevices}
          primaryLabel={t('next')}
          showContinue
        />
        <Dialog
          size="medium"
          open={this.state.errorModalOpen}
          onClose={() => this.onDismissModal(false)}
          dataAttributes={{ testid: 'device-selection' }}
        >
          <DialogHeader dataAttributes={{ testid: 'device-selection' }}><h2>{t('deviceNotSupported')}</h2></DialogHeader>
          <DialogContent dataAttributes={{ testid: 'device-selection' }}>
            <p>
              {t('deviceNotSupportedDetail')}
            </p>
            <p>
              {t('deviceNotSupportedContinue')}
            </p>
          </DialogContent>
          <DialogActions dataAttributes={{ testid: 'device-selection' }}>
            <Button
              variation="secondary"
              onClick={() => this.onDismissModal(false)}
              dataAttributes={{ testid: 'device-selection-cancel' }}
            >
              {t('cancel')}
            </Button>
            <Button
              onClick={() => this.onDismissModal(true)}
              dataAttributes={{ testid: 'device-selection-ok' }}
            >
              {t('ok')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DeviceSelection.propTypes = {
  androidChargingCord: PropTypes.string,
  isValid: PropTypes.bool,
  fetchShopifyProducts: PropTypes.func.isRequired,
  canGoBack: PropTypes.bool,
  onBack: PropTypes.func,
  onContinue: PropTypes.func.isRequired,
  onSelectAndroidChargingCord: PropTypes.func.isRequired,
  onSelectPlatform: PropTypes.func.isRequired,
  platform: PropTypes.string,
  selectedDiabetesDevices: PropTypes.arrayOf(PropTypes.object),
  skipDiabetesDevices: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

DeviceSelection.defaultProps = {
  androidChargingCord: undefined,
  isValid: false,
  canGoBack: false,
  onBack: () => {},
  platform: undefined,
  selectedDiabetesDevices: [],
};

export default withTranslate(DeviceSelection);
