import { connect } from 'react-redux';
import DiabetesDeviceSummary from './DiabetesDeviceSummary';

const mapStateToProps = state => ({
  devices: state.deviceSelection.allDiabetesDevices,
  selected: state.deviceSelection.selectedDiabetesDevices,
  language: state.language.language,
});

export default connect(mapStateToProps)(DiabetesDeviceSummary);
