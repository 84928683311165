import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

export const redirectWithErrorCode15 = () => {
  const searchParams = new URLSearchParams(window.location.search);
  if (searchParams.has('redirectUrl')) {
    const redirectUri = new URL(searchParams.get('redirectUrl'));
    const params = new URLSearchParams(redirectUri.search);
    params.set('errorCode', '15');
    window.location.assign(`${redirectUri.origin}${redirectUri.pathname}?${params}`);
  }
};

class InsuletIdleTimer extends Component {
  componentDidMount() {
    this.idleTimer = React.createRef();
  }

  render() {
    return (
      <IdleTimer
        startOnMount
        ref={this.idleTimer}
        onIdle={this.props.onIdle}
        timeout={this.props.timeoutMinutes * 60 * 1000}
      />
    );
  }
}

InsuletIdleTimer.propTypes = {
  timeoutMinutes: PropTypes.number,
  onIdle: PropTypes.func,
};

InsuletIdleTimer.defaultProps = {
  timeoutMinutes: 10,
  onIdle: redirectWithErrorCode15,
};

export default InsuletIdleTimer;
