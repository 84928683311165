import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { getDeviceImageUrl } from '~/utils/DeviceImages';
import CheckboxList from '~/shared/CheckboxList/CheckboxList.jsx';
import Checkbox from '~/shared/Checkbox/Checkbox.jsx';
import withTranslate from '~/shared/withTranslate/withTranslate.jsx';
import { LinkedText } from '@glooko/common-ui';
import TextField from '~/shared/TextField/TextField.jsx';
import images from '~/utils/ImageStore';
import './style.sass';

class DiabetesDeviceSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { touched: false };
  }

  showError() {
    return !this.props.isValid(this.props.devices) && this.state.touched;
  }

  touch() {
    this.setState({ touched: true });
  }

  classes() {
    return classnames({
      DiabetesDeviceSelect: true,
      error: this.showError(),
    });
  }

  render() {
    const {
      devices,
      skipDiabetesDevices,
      filter,
      isLoading,
      onFilterChange,
      onSelectionChange,
      onToggleSkipDiabetesDevices,
      selected,
      t,
    } = this.props;

    return (
      <div className={this.classes()} >
        <div className="DiabetesDeviceSelect-header">
          { t('diabetesDeviceSelectHeader') }
        </div>
        <div className="DiabetesDeviceSelect-search">
          <TextField
            onChange={onFilterChange}
            value={filter}
            placeholder={t('searchPlaceholder')}
          >
            <img alt="search" src={images.search} />
          </TextField>
        </div>
        <div className="DiabetesDeviceSelect-list">
          {isLoading &&
            t('loadingDevices')
          }
          <CheckboxList
            data={devices}
            idField="id"
            valueField="id"
            labelField="model"
            categoryField="brand"
            fetchItemImageUrl={data => (getDeviceImageUrl(data ? data.id : undefined))}
            selected={selected}
            onSelectionChange={onSelectionChange}
          />
        </div>
        <div className="DiabetesDeviceSelect-footer">
          <LinkedText
            template={t('dontSeeDevice')}
            linkContent={[{ title: t('contactUs'), url: 'http://support.glooko.com/' }]}
            openInNewTab
          />
        </div>
        <div className="DiabetesDeviceSelect-noDevices">
          <Checkbox
            value="noDevices"
            checked={skipDiabetesDevices}
            labelContent={t('addDeviceLater')}
            onChange={onToggleSkipDiabetesDevices}
          />
        </div>
      </div>
    );
  }
}

DiabetesDeviceSelect.propTypes = {
  isValid: PropTypes.func.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  skipDiabetesDevices: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  filter: PropTypes.string,
  isLoading: PropTypes.bool,
  onSelectionChange: PropTypes.func.isRequired,
  onToggleSkipDiabetesDevices: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func,
  t: PropTypes.func.isRequired,
};

DiabetesDeviceSelect.defaultProps = {
  skipDiabetesDevices: false,
  filter: '',
  isLoading: false,
  onFilterChange: () => {},
};

export default withTranslate(DiabetesDeviceSelect);
