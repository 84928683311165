import React from 'react';
import PropTypes from 'prop-types';
import { getDeviceImageUrl } from '~/utils/DeviceImages';
import './style.sass';

const DeviceSummaryItem = ({ device }) => (
  <div className="DeviceSummaryItem">
    <div
      className="DeviceSummaryItem-ImageContainer"
      style={{
        backgroundImage: `url(${getDeviceImageUrl(device.id)})`,
        }
      }
    />
    <div className="DeviceSummaryItem-Name">
      {device.model}
    </div>
  </div>
);

DeviceSummaryItem.propTypes = {
  device: PropTypes.object.isRequired,
};


export default DeviceSummaryItem;
